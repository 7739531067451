<mat-form-field class="mat-input" appearance="outline">
    <mat-label class="paragraph paragraph-2 mat-label">Campanha</mat-label>
    <input type="text" placeholder="Buscar Campanha" matInput [formControl]="searchCampaign"
        [value]="searchCampaign.value" [matAutocomplete]="autoComplete">
    <button class="align-icon" mat-icon-button align-icon matSuffix (click)="clearSelectedCampaign()"
        *ngIf="searchCampaign.value !== '' && searchCampaign.value !== null && searchCampaign.value !== undefined && !disabled">
        <mat-icon class="iconSize">clear</mat-icon>
    </button>
    <mat-autocomplete #autoComplete="matAutocomplete" showPanel="true" optionsScroll
        (opened)="openAutoComplete()" (closed)="resetProperties()"
        (optionSelected)="campaignSelected($event.option.value)" (scroll)="onScroll()">
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign" [disabled]="disabled">
            {{campaign.name}}
        </mat-option>
        <mat-option *ngIf="campaigns.length === 0" [disabled]="true" [value]="null">
            Nenhuma campanha encontrada.
        </mat-option>
    </mat-autocomplete>
</mat-form-field>