import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { BeneficiaryComponent } from 'src/app/pages/beneficiary/beneficiary/beneficiary.component';
import { BeneficiarySearchComponent } from 'src/app/pages/beneficiary/main/beneficiary-search.component';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryGuard implements CanActivateChild, CanActivate {

  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService,
   ) {
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    const clientId = childRoute.queryParamMap.get('clientId');

    if (childRoute.component == BeneficiarySearchComponent) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_COLLABORATOR);
    }

    if (childRoute.component == BeneficiaryComponent && state.url.includes('edit-collaborator')) {
      return this.permissionsService.hasPermission(ProfilePermissions.UPDATE_COLLABORATOR);
    }

    if (childRoute.component == BeneficiaryComponent && state.url.includes('view-collaborator')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_COLLABORATOR);
    }

    if (childRoute.component == BeneficiaryComponent && state.url.includes('new-collaborator')) {
      if (clientId == null) {
        return false
      }
      return this.permissionsService.hasPermission(ProfilePermissions.CREATE_COLLABORATOR);
    }

    return false;
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (this.rolesService.getRole('CLIENT')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_COLLABORATOR);
    }

    return false;
  }
}
