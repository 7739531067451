<app-search-table *ngIf="!isClientView"
  tableTitle="Parceiros"
  addButtonLabel="Novo Parceiro"
  [tableSourceData]="dataSource"
  [columnsConfigurations]="columns"
  [deleteDialogInfo]="dialogInfo"
  [deleteDialogInfoConditional]="dialogConditionalInfo"
  [pageLength]="pageLength"
  [pageIndex]="pageIndex"
  (onAddButtonClick)="redirectToNewPartner()"
  (onFilter)="applyFilter($event)"
  (onPageCount)="onPageChange($event)"
  (onEditButtonClick)="redirectToEditPartner($event)"
  (sortDirection)="removeSorting($event)"
  [sortingDataAccessorMethod]="sortingDataAccessorMethod"
  [changeSituationDialogInfo]="changeSituationDialogInfo">
</app-search-table>

<app-search-table *ngIf="isClientView"
  tableTitle="Parceiros"
  addButtonLabel="Novo Parceiro"
  [disableAddButton]="!canCreate"
  [tableSourceData]="dataSource"
  [selectConfiguration]="selectClient"
  [columnsConfigurations]="columns"
  [deleteDialogInfo]="dialogInfo"
  [deleteDialogInfoConditional]="dialogConditionalInfo"
  [pageLength]="pageLength"
  [pageIndex]="pageIndex"
  (onAddButtonClick)="redirectToNewPartner()"
  (onFilter)="applyFilter($event)"
  (onPageCount)="onPageChange($event)"
  (onEditButtonClick)="redirectToEditPartner($event)"
  (onViewButtonClick)="redirectToViewPartner($event)"
  (sortDirection)="removeSorting($event)"
  [sortingDataAccessorMethod]="sortingDataAccessorMethod"
  [changeSituationDialogInfo]="changeSituationDialogInfo"
  (onSelect)="onSelectClientEvent($event)">
</app-search-table>

<div class="container-fluid d-sm-none">
  <div style="margin: 0px 10px 10px 10px">
    <div class="row">
      <div class="col-12">
        <ng-container>
          <div class="mobile-session" *ngFor="let element of dataSource.data; let i = index;">
            <div class="card-row-mobile" *ngIf="paginationCard > i" 
              (click)="canEdit ? redirectToEditPartner(element) : redirectToViewPartner(element)">
              <div class="card-mobile-content">
                <div class="row margin-btm first-row">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{ element.name }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ element.isPj ? 'CNPJ' : 'CPF'}}:&nbsp;</label>
                  <label class="paragraph paragraph-1 truncate-words">{{ element.identification }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ element.email }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{ element.phone | mask: masks.phoneMask }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" class="pagination-card" *ngIf="pageLength > paginationCard">
            <label class="paragraph paragraph-2 semibold primary-color"
              (click)="getPartnerByMobile()">Mostrar mais</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
