export interface AuthPersonaResponse {
    userId: number;
    tempToken: string;
    profiles: AuthProfile[];
}

export interface AuthProfile {
    clientId: number | null;
    clientName: string | null;
    profile: ProfileKeyType
}

export type ProfileKeyType = "ADMINISTRADOR" | "RECURSO_HUMANO" | "COLABORADOR"

export enum ProfileType {
    ADMINISTRADOR = "ADMINISTRADOR",
    RECURSO_HUMANO = "RECURSO_HUMANO",
    COLABORADOR = "COLABORADOR"
}

export const ProfileWithLabels = {
    ADMINISTRADOR: { label: 'Administrador', value: 'ADMINISTRADOR' },
    RECURSO_HUMANO: { label: 'Recursos Humanos', value: 'RECURSO_HUMANO' },
    COLABORADOR: { label: 'Colaborador', value: 'COLABORADOR' }
}