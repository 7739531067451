import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddressInfo } from '../domains/address-info';
import { DataBridgeServiceInterface } from '../interfaces/data-bridge-service.interface';
import { CnpjInfo } from '../domains/cnpj-info';
import { lastValueFrom, throwError } from 'rxjs';
import { ToastComponent } from '../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class DataBridgeService implements DataBridgeServiceInterface {

  urlBase: string;
  
  constructor(private http: HttpClient,
    private toastComponent: ToastComponent) {
    this.urlBase = environment.bff_web;
  }
  async getByCNPJ(cnpj: string): Promise<CnpjInfo> {
    try {
      const infoArray: CnpjInfo = await lastValueFrom(this.http.get<CnpjInfo>(this.urlBase + `/data-bridges/cnpj/${cnpj}`));

      if (infoArray) {
        return infoArray;
      }
    } catch (error: any) {
      console.error(error);
      throw error;
    }
    throw new Error();
  }

  async getByCEP(cep: string): Promise<AddressInfo> {
    try {
      const info: AddressInfo = await lastValueFrom(this.http.get<AddressInfo>(this.urlBase + `/data-bridges/cep/${cep}`));

      if (info) {
        return info;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    throw new Error();
  }
}
