import { Component, Input, OnInit } from '@angular/core';
import { GlobalFunctions } from '../../utils/global-functions';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {

  @Input()
  text: string = '';

  @Input()
  tooltipStyleClass: string = '';

  @Input()
  tooltipPosition: string = '';

  @Input()
  styleTooltip: string = '';

  @Input()
  styleResponsive: string = '';

  divHtmlText: string = '';
  showResponsive: boolean = false;

  constructor(
    public globalFunctions: GlobalFunctions
  ) { }

  ngOnInit(): void {
    this.divHtmlText = `
    <div style='${this.styleTooltip}'>
      ${this.text}
    </div>`
  }
}
