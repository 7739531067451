import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  invokeNotification = new Subject<any>();
  invokeNotificationObservable$ = this.invokeNotification.asObservable();
  notificationCount = new Subject<any>();
  networkSubject = new Subject<any>();
  topicTitle = new Subject<any>();
  carregarRotasSubject = new Subject<any>();

  callNotification() {
    this.invokeNotification.next(undefined);
  }

  setNetworkSubject() {
    return this.networkSubject.asObservable();
  }

  setCommunityForumTopicTitle() {
    return this.topicTitle.asObservable();
  }

  callCarregarRotas() {
    this.carregarRotasSubject.next(undefined);
  }

  setCarregarRotasSubject() {
    return this.carregarRotasSubject.asObservable();
  }

  setNotificationsCountMobile(number: number){
    this.notificationCount.next(number)
  }

  getNotificationsCountMobile(){
    return this.notificationCount.asObservable();
  }
}
