import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, filter, map, pairwise, startWith } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

import { ClientComboboxResponseDTO } from '../../domains/clients/client-combobox-response-dto';
import { ChannelTypesWithLabels } from '../../types/channel.type';
import { MenuOptionsModel } from '../menu-options/models/menu-options.model';
import { SearchColumnConfiguration } from './interfaces/search-table-column-configuration.interface';
import {
  SearchTableDeleteDialogConfiguration,
  SearchTableDeleteDialogConfigurationConditional,
  SituationDialogConfiguration,
} from './interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from './interfaces/search-table-select-configuration.interface';
import { SearchTableToggleInfo } from './interfaces/search-table-toggle-Info.interface';

@Component({
  selector: 'app-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: ['./search-table.component.scss'],
})
export class SearchTableComponent implements OnChanges, AfterContentChecked {
  @ViewChild('confirmModalSearchTable')
  confirmModalSearchTable?: ConfirmModalComponent;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChildren(NgModel) ngModels?: QueryList<NgModel>;
  @ViewChild(MatSort) tableSort!: MatSort;
  @Input() menuOptionModel: MenuOptionsModel[] = [];

  @Input()
  clearSearch: boolean = false;

  @Input()
  selectConfiguration?: SearchTableSelectConfiguration;

  @Input()
  showSearchComponent: boolean = true;

  @Input()
  showAddButton: boolean = true;

  @Input()
  disableAllActions?: boolean;

  @Input()
  addButtonCss =
    'btn btn-primary fit-content subtitle subtitle-1 semibold white';

  @Input()
  addButtonIcon = 'add';

  @Input()
  titleCss = 'titles title-2 semibold extended-secondary-darker';

  @Input()
  disableAddButton? = false;

  @Input()
  disableDeleteButton = false;

  @Input()
  disableEditButton = false;

  @Input()
  disableViewButton = false;

  @Input()
  disableToggleButton = false;

  @Input()
  disablePagination = false;

  @Input()
  tableTitle?: string = '';

  @Input()
  attribute: string = '';

  @Input()
  columnsConfigurations: SearchColumnConfiguration[] = [];

  @Input()
  tableSourceData: MatTableDataSource<any> = new MatTableDataSource();

  @Input()
  deleteDialogInfo!: SearchTableDeleteDialogConfiguration;

  @Input()
  deleteDialogInfoConditional?: SearchTableDeleteDialogConfigurationConditional;

  @Input()
  changeSituationDialogInfo!: SituationDialogConfiguration;

  @Input()
  canDeleteActive = true;

  @Input()
  cleanClientSelect = false;

  @Output()
  onFilter: EventEmitter<string> = new EventEmitter();

  @Output()
  onPageCount: EventEmitter<PageEvent> = new EventEmitter();

  @Input()
  pageLength!: number;

  @Input()
  pageIndex!: number;

  @Input()
  pageSize!: number;

  @Input()
  addButtonLabel: string = '';

  @Input()
  showMobileVision: boolean = false;

  @Input()
  sortingDataAccessorMethod?: (item: any, property: string) => any;

  @Input()
  onlySearchComponent: boolean = false;

  @Output()
  onChangeCheckBoxClick: EventEmitter<void> = new EventEmitter();

  @Output()
  onAddButtonClick: EventEmitter<void> = new EventEmitter();

  @Output()
  onEditButtonClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onViewButtonClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter();

  @Output()
  sortDirection: EventEmitter<any> = new EventEmitter();

  @Output()
  onToggle: EventEmitter<SearchTableToggleInfo> = new EventEmitter();

  searchFormControl = new FormControl();
  selectFormControl = new FormControl();
  searchSelectFormControl = new FormControl();
  checkBoxFormControl = new FormControl(false);
  openModal = false;
  readonly checkboxesNgModelName = 'checkbox';
  private checkboxNgModels?: NgModel[];
  hasselectedItem: boolean = false;

  constructor(
    public globalFunctions: GlobalFunctions,
    private ref: ChangeDetectorRef
  ) {
    this.searchFormControl.valueChanges
      .pipe(
        map((value: string) => {
          const trimmedValue = value.trim().toLocaleLowerCase();

          if (trimmedValue.includes('@')) {
            return trimmedValue.replace(/-/g, '');
          }
          return trimmedValue.replace(/[.-]/g, '');
        }
        )
      )
      .subscribe((searchedValue) => this.onFilter.emit(searchedValue));

    this.selectFormControl.valueChanges
      .pipe(
        startWith(this.selectFormControl.value),
        debounceTime(100),
        pairwise(),
        filter(([oldValue, newValue]) => oldValue?.id !== newValue?.id),
        map(([_, newValue]) => newValue)
      )
      .subscribe((value) => {
        if (value) {
          this.onSelect.emit(value);
        }
      });

    this.onFilter
      .pipe(startWith(''), pairwise(), debounceTime(150))
      .subscribe(([oldValue, newValue]) => {
        if (oldValue && !newValue) {
          this.checkBoxFormControl.setValue(
            this.checkboxNgModels?.every((e) => e.value),
            { emitEvent: false }
          );
        }
      });

    this.checkBoxFormControl.valueChanges.subscribe((v) => {
      this.checkboxNgModels?.forEach((e, index) => {
        if (this.canDeleteActive) {
          e.control.patchValue(v);
        } else if (!this.tableSourceData.data[index]['isActive'])
          e.control.patchValue(v);
      });

      this.onChangeCheckBoxClick.emit();
    });
  }

  onSelectAutoComplete(clientResponseDto: ClientComboboxResponseDTO): void {
    this.selectFormControl.setValue(clientResponseDto);
  }

  ngAfterContentChecked(): void {
    this.checkboxNgModels = this.ngModels?.filter(
      (e) => e.name == this.checkboxesNgModelName
    );
    this.checkBoxFormControl.setValue(
      this.checkboxNgModels && this.checkboxNgModels.length
        ? this.checkboxNgModels?.every((e) => e.value)
        : false,
      { emitEvent: false }
    );
    this.configTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['tableSourceData']?.isFirstChange()) {
      this.configTable();
      if (this.clearSearch) this.searchFormControl.setValue("")
    }

    if (changes['cleanClientSelect'] && this.cleanClientSelect) {
      this.selectFormControl.setValue(null);
    }
  }

  getChannelLabelFromValue(value: any): string | undefined {
    return ChannelTypesWithLabels?.find(
      (p) => p.value.toLocaleLowerCase() === value.toLocaleLowerCase()
    )?.label;
  }

  confirmDelete(value: any) {
    this.showModal(true);

    if (!!this.deleteDialogInfoConditional && value[this.deleteDialogInfoConditional?.field] === true) {
      this.confirmModalSearchTableDelete(value, this.deleteDialogInfoConditional);
    } else {
      this.confirmModalSearchTableDelete(value, this.deleteDialogInfo);
    }
  }

  confirmModalSearchTableDelete(value: any, deleteDialogInfo: SearchTableDeleteDialogConfiguration | SearchTableDeleteDialogConfigurationConditional): void {
    this.confirmModalSearchTable
      ?.showModal(
        deleteDialogInfo?.title,
        deleteDialogInfo?.description
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          await deleteDialogInfo?.onConfirmFn(value);
        }
        this.showModal(false);
      });
  }

  confirmChangeSituation(value: any) {
    this.showModal(true);
    const validChangeDescription2 =
      value[this.changeSituationDialogInfo.situationFieldName] &&
      value[this.attribute];

    this.confirmModalSearchTable
      ?.showModal(
        this.changeSituationDialogInfo?.title +
        (value[this.changeSituationDialogInfo.situationFieldName]
          ? 'inativar'
          : 'ativar') +
        this.changeSituationDialogInfo?.title2,
        validChangeDescription2
          ? this.changeSituationDialogInfo?.description2
          : this.changeSituationDialogInfo?.description
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          this.changeSituationDialogInfo.onConfirmFn(value);
        } else {
          value[this.changeSituationDialogInfo.situationFieldName] =
            !value[this.changeSituationDialogInfo.situationFieldName];
        }
        this.showModal(false);
      });
  }

  get columnsIds() {
    return this.columnsConfigurations.map((column) => column.valueName);
  }

  deleteDisableCondition(isActive: boolean): boolean {
    if (this.disableDeleteButton) return true;
    if (!this.canDeleteActive) return isActive;
    return false;
  }

  private configTable() {
    if (this.pageLength > 0 && this.tableSourceData.paginator) {
      this.tableSourceData.paginator.length = this.pageLength;
      this.tableSourceData.paginator.pageIndex = this.pageIndex;
      if (this.pageSize) this.tableSourceData.paginator!.pageSize = this.pageSize;
    }
    if (this.paginator && this.tableSourceData.paginator !== this.paginator)
      this.tableSourceData.paginator = this.paginator;
    if (this.tableSort && this.tableSourceData.sort !== this.tableSort)
      this.tableSourceData.sort = this.tableSort;

    if (this.sortingDataAccessorMethod) {
      this.tableSourceData.sortingDataAccessor = this.sortingDataAccessorMethod;
    } else {
      this.tableSourceData.sortingDataAccessor = (item: any, property) => {
        const value = item[property];
        if (!isNaN(value) && typeof value !== 'boolean') {
          return value;
        }
        return String(value).toLowerCase();
      };
    }
    this.validHasselectedItem()
  }

  sortData(data: any) {
    this.sortDirection.emit(data);
  }

  private showModal(state: boolean) {
    this.openModal = state;
    this.ref.detectChanges();
  }

  cleanSearchControl() {
    this.searchFormControl.setValue('');
  }

  getStatusClass(status: string): any {
    return {
      pending: status === 'Pendente' || status === 'Aguardando aprovação',
      sent: status === 'Enviado',
      accept: status == 'Aceito' || status === 'Publicado' || status == 'Autorizado',
      refused: status === 'Recusado',
      closed: status === 'Rascunho' || status === 'Arquivado',
      shipped: status === 'Envio sinalizado'
    };
  }

  onPageChange(event: PageEvent) {
    this.onPageCount.emit(event);
  }

  isString(val: any): boolean { return typeof val === 'string'; }

  validHasselectedItem() {
    this.hasselectedItem = this.tableSourceData.data.some(x => x.checkbox);
  }

  checkBoxChange() {
    this.validHasselectedItem();
    this.onChangeCheckBoxClick.emit();
  }

  showEditIcon(useEditButton: boolean, replaceEditWithView?: boolean) {
    return useEditButton && (replaceEditWithView == undefined || !replaceEditWithView);
  }

  showViewIcon(useViewButton: boolean, useEditButton: boolean, replaceEditWithView?: boolean) {
    return useViewButton || !this.showEditIcon(useEditButton, replaceEditWithView);
  }
}
