export enum Origin {
  BENEFIT = 'BENEFIT',
  POST_COMMENT = 'POST_COMMENT',
  CLIENT = 'CLIENT',
  NETWORK = 'NETWORK',
  REPORTEDCOMMENT = 'COMMENT_REPORT',
}
export function stringToEnumSimple(value: string): Origin {
  return Origin[value as keyof typeof Origin];
}
