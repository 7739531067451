import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs';

import { NoticesCentralUsers } from '../../domains/notice';
import { NoticeService } from '../../services/notice.service';
import { GlobalFunctions } from '../../utils/global-functions';

@Component({
  selector: 'app-notice-central-modal',
  templateUrl: './notice-central-modal.component.html',
  styleUrls: ['./notice-central-modal.component.scss'],
})
export class NoticeCentralModalComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() showNoticesCentral: boolean = false;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Input() noticeId: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  noticesCentralDataSource = new MatTableDataSource<NoticesCentralUsers>();
  noticesCentralColumns: string[] = ['viewerUserName', 'viewingDate'];
  searchUserFormControl = new FormControl();
  numberOfUserSawThatNotice: number = 0;
  userNoticePageIndex: number = 0;
  userNoticePageSize: number = 10;
  userNoticePageLength: number = 0;

  constructor(
    private noticeService: NoticeService,
    public globalFunctions: GlobalFunctions
  ) {
    this.searchUserFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((searchText: string) => {
        if (this.searchUserFormControl.dirty) {
          if (!searchText) {
            this.getUsersToNoticeCentral(this.noticeId);
          } else {
            this.getUsersToNoticeCentralWithFilter(
              this.noticeId,
              searchText,
              this.userNoticePageIndex,
              this.userNoticePageSize
            );
          }
        }
      });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['noticeId']) {
      if (this.noticeId != 0) {
        this.initNoticeCentral();
      }
    }
  }

  async initNoticeCentral() {
    this.searchUserFormControl.reset();
    const number = await this.noticeService.getNumberOfUsersViewedNotice(this.noticeId);
    this.numberOfUserSawThatNotice = number;
    this.getUsersToNoticeCentral(this.noticeId);
    this.configTable();
  }

  close() {
    this.clearForm();
    this.onClose.emit();
  }

  clearForm() {
    this.numberOfUserSawThatNotice = 0;
    this.userNoticePageIndex = 0;
    this.userNoticePageLength = 0;
    this.userNoticePageSize = 10;
    this.noticesCentralDataSource = new MatTableDataSource<NoticesCentralUsers>();
    this.configTable();
  }

  configTable() {
    if (this.paginator) {
      this.paginator.length = this.userNoticePageLength;
      this.paginator.pageIndex = this.userNoticePageIndex;
      this.paginator.pageSize = this.userNoticePageSize;
    }
  }

  ngAfterViewInit(): void {
    this.noticesCentralDataSource.paginator = this.paginator;
    this.configTable();
  }

  onPageChanger(event: PageEvent) {
    const page = event.pageIndex;
    this.userNoticePageSize = event.pageSize;
    this.userNoticePageIndex = page;
    this.getUsersToNoticeCentralWithFilter(
      this.noticeId,
      this.searchUserFormControl.value ? this.searchUserFormControl.value : '',
      page,
      this.userNoticePageSize
    );
  }

  async getUsersToNoticeCentral(noticeId: number) {
    const params = { filter: '', page: this.userNoticePageIndex, pageSize: this.userNoticePageSize };
    let usersNoticeList = await this.noticeService.findUsersByNoticeId(noticeId, params);
    this.updateDataSource(usersNoticeList);
  }

  async getUsersToNoticeCentralWithFilter(
    noticeId: number,
    filter: string,
    page: number,
    pageSize: number
  ) {
    const params = { filter, page, pageSize };
    let usersNoticeList = await this.noticeService.findUsersByNoticeId(noticeId, params);
    this.updateDataSource(usersNoticeList);
  }

  private updateDataSource(usersNoticeList: any) {
    this.noticesCentralDataSource = new MatTableDataSource(usersNoticeList.content);
    this.userNoticePageLength = usersNoticeList.totalElements;
    this.userNoticePageIndex = usersNoticeList.number;
    this.userNoticePageSize = usersNoticeList.size;
    this.configTable();
  }

}
