<form #eventForm="ngForm" class="container-fluid" [formGroup]="form">
  <div class="margin-15">
    <div class="titles title-2 semibold extended-secondary-darker" *ngIf="!noticeId">Novo Aviso</div>
    <div class="titles title-2 semibold extended-secondary-darker" *ngIf="noticeId && canEdit">Editar Aviso</div>
    <div class="titles title-2 semibold extended-secondary-darker" *ngIf="noticeId && !canEdit">Visualizar Aviso</div>
    <div class="row" style="margin-top: 20px;">
      <div class="col-12 col-md-6" *ngIf="clientUserId">
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-sm-8 p-0">
            <mat-label>Cliente</mat-label>
            <input matInput type="text" maxlength="255" [disabled]="true" [value]="clientName" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="form-group row">
          <div class="col-12 col-md-6">
            <app-input-data-hour-picker [required]="true" [initialDate]="form.get('startTimestamp')?.value"
              [formInvalid]="startDateFormInvalid" [label]="'Data e Hora Iniciais'"
              (dateSelected)="form.get('startTimestamp')?.setValue($event); handleChangeStartDate()"
              [disabled]="!!noticeId && !canEdit">
            </app-input-data-hour-picker>
          </div>
          <div class="col-12 col-md-6">
            <app-input-data-hour-picker [required]="true" [initialDate]="form.get('endTimestamp')?.value"
              [formInvalid]="endDateFormInvalid" [label]="'Data e Hora Finais'"
              (dateSelected)="form.get('endTimestamp')?.setValue($event); handleChangeEndDate()"
              [disabled]="!!noticeId && !canEdit">
            </app-input-data-hour-picker>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>Nome</mat-label>
            <input matInput type="text" formControlName="name" ngModel required
              (input)="updateCharacterCountName($any($event.target).value)" maxlength="255" />
          </mat-form-field>
          <div class="d-flex justify-content-end character-count text-area-counter">
            {{ characterCountName }}/255
          </div>
        </div>
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-12 col-md-6 pl-md-0 selectStyle mat-input mat-chip-area">
            <mat-label>Público-alvo</mat-label>

            <mat-select formControlName="alertType" ngModel [ngModel]="clientUserId ? ['BENEFICIARY'] : null" required
              (selectionChange)="handleDisabledGrid()" multiple class="multi-select">
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="
                      let notice of form.get('alertType')?.value;
                      let index = index
                    " [disabled]="clientUserId ? true : false" class="custom-label-input"
                    (removed)="removeNoticeUserType(index)">
                    {{ getNoticeLabelFromValue(notice) }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option *ngFor="let type of userTypes" [value]="type.value">
                {{ type.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="col-12 col-md-6 pr-md-0 selectStyle">
            <mat-label>Recorrência</mat-label>
            <mat-select formControlName="recurrence" ngModel required>
              <mat-option value="DAILY">Diária</mat-option>
              <mat-option value="WEEKLY">Semanal</mat-option>
              <mat-option value="MONTHLY">Mensal</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group"></div>
        <div class="form-group">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>URL</mat-label>
            <input matInput type="text" formControlName="url" ngModel />
          </mat-form-field>
        </div>
        <div class="form-group text-area">
          <mat-form-field appearance="outline" class="col-12 col-md-12 p-0">
            <mat-label>Descrição</mat-label>
            <textarea matInput formControlName="description" ngModel rows="3" matInput maxLength="400"
              cdkTextareaAutosize (input)="updateCharacterCountDescription($any($event.target).value)"></textarea>
          </mat-form-field>
          <div class="d-flex justify-content-end character-count text-area-counter">
            {{ characterCountDescription }}/400
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 imageBox" style="padding: 0px !important;">
        <div class="col-12 col-md-10">
          <div class="anexo-area">
            <div class="margin-15">
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <label class="subtitle subtitle-2 semibold extended-secondary-darker">Imagem</label>
                  <div class="ml-2">
                    <app-custom-tooltip [text]="'Formatos: JPEG e PNG.\nTamanho máximo: 15MB.\nDimensões: 1080px x 1350px.'"
                      [tooltipStyleClass]="'info-att-img'" [tooltipPosition]="'top'" [styleTooltip]="'margin-top: -3rem; height: fit-content'"
                      [styleResponsive]="'margin-left: -20vw; width: 85vw; top: -10vh !important;'">
                    </app-custom-tooltip>
                  </div>
                </div>
                <div class="col-2"></div>
                <div class="col-8">
                  <div class="container-img" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                    <div class="anexo-area" style="min-width: 100%; margin-bottom: -20px; padding-bottom: 20px;">
                      <div class="col-12">
                        <div class="row justify-content-end" *ngIf="selectedImage">
                          <mat-icon class="icon-file click" (click)="removeImg(imgInput)">close</mat-icon>
                        </div>
                      </div>
                      <div class="img-anexo" style="margin-bottom: 20px; margin-top: -10px">
                        <img *ngIf="!selectedImage" src="../../../../assets/img/images.png" alt="Imagem do aviso" />
                        <img *ngIf="selectedImage" class="click" (click)="downloadImage(selectedImage, getFileName())"
                          [src]="sanitizeImageUrl(selectedImage)" alt="Imagem selecionada" class="img-responsive" />
                      </div>
                    </div>
                    <div class="col-12 text-center" *ngIf="!selectedImage" style="margin-top: -25px">
                      <label class="pass-13 neutral-ligh">Arraste a imagem aqui ou</label>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
                          (click)="imgInput.click()" [disabled]="!canEdit">Selecione o arquivo</button>&nbsp;
                        <input type="file" multiple #imgInput style="display: none" (change)="onImgAdded($event)"
                          accept="image/jpeg, image/png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-client-list *ngIf="!clientUserId" [disabled]="isDisabledGrid" [clients]="clients"
      (selectedListEmitter)="handleSelectedListChange($event)"></app-client-list>

    <div class="row" style="margin-top: 30px;" *ngIf="this.form && clientUserId">
      <div class="col-12">
        <label class="titles title-2 semibold extended-secondary-darker mr-2">Grupos</label>
        <app-select-group [clientId]="clientUserId" [idGroupsSelected]="form.get('groups')?.value"
          (groupSelectionChanged)="onEemitterGroupSelectionChanged($event)" [disabled]="!canEdit"></app-select-group>
      </div>
    </div>

    <div style="margin-top: 30px;" *ngIf="clientUserId">
      <header class="d-flex justify-content-between align-items-center mb-1">
        <label class="titles title-2 semibold extended-secondary-darker mr-2"><b>Gamificação</b></label>
      </header>
      <div class="row">
        <div class="col-12">
          <app-campaign-select (onSelectCampaign)="onSelectCampaign($event)"
          [selectedCampaignName]="form.get('campaignName')?.value" [disabled]="!canEdit"></app-campaign-select>
        </div>
      </div>
      <div style="width: 4rem; margin-left: auto;">
        <mat-checkbox id="gamification" formControlName="isGeral">
          <label for="gamification" class="paragraph paragraph-2 label-checkbox click">Geral</label>
        </mat-checkbox>
      </div>
      <article class="d-flex justify-content-between" *ngFor="let field of gamificationFields">
        <div class="d-flex align-items-center">
          <input type="checkbox" name="togle" [id]="field.key" class="switchButton"
            [formControlName]="gamificationService.getAllowFieldName(field.key)" />
          <label [for]="field.key" class="label-st">
            <div>
              <p class="mb-1"><b>{{ field.value }}</b></p>
            </div>
          </label>
        </div>
        <mat-form-field appearance="outline" class="mat-input" style="max-width: 17rem;">
          <mat-label class="paragraph paragraph-2 mat-label">Pontos</mat-label>
          <input matInput [formControlName]="gamificationService.getValueFieldName(field.key)" mask="separator.0"
            thousandSeparator="" [allowNegativeNumbers]="true"/>
        </mat-form-field>
      </article>
    </div>

    <div class="row" *ngIf="globalFunctions.isMobile()" style="margin-top: 30px;">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button mat-mini-fab (click)="confirmDeleteNotice()" [disabled]="!this.noticeId || !canDelete" *ngIf="noticeId"
          class="btn btn-tertiary bg-white subtitle subtitle-1 semibold primary-color">
          <mat-icon class="icon-btn ml-0 material-icons-outlined">delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-2">
        <button style="margin-top: 20px;" class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
          (click)="confirmDeleteNotice()" [disabled]="!canDelete" *ngIf="noticeId && !globalFunctions.isMobile()">
          <mat-icon class="icon-btn">delete</mat-icon>Excluir Aviso
        </button>
      </div>
      <div class="col-sm-6"></div>
      <div class="col-12 col-sm-2" *ngIf="globalFunctions.isMobile()">
        <button style="margin-top: 20px;" class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="onSubmit() " [disabled]="!hasPermissions">
          Salvar
        </button>
      </div>
      <div class="col-12 col-sm-2">
        <button style="margin-top: 20px;" mat-button
          (click)="redirectTo(this.clientUserId ? '/notices-client' : '/notices')"
          class="btn btn-secondary subtitle subtitle-1 semibold primary-color">
          Cancelar
        </button>
      </div>
      <div class="col-12 col-sm-2">
        <button style="margin-top: 20px;" class="btn btn-primary subtitle subtitle-1 semibold white"
          (click)="onSubmit() " [disabled]="!hasPermissions" *ngIf="!globalFunctions.isMobile()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</form>

<app-confirm-modal #confirmModal></app-confirm-modal>