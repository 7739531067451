import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastComponent } from "../components/toaster/toast/toast.component";
import { Group } from "../domains/group";
import { Profile } from "../domains/profile/profile";
import { ProfileGeneralData } from "../domains/profile/profile-general-data";
import { ProfileServiceInterface } from "../interfaces/profile-service.interface";
import { GroupDTO, GroupPage } from "../domains/user-client/user-client";

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements ProfileServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
  ) {
    this.urlBase = environment.bff_web;
  }

  async getFilteredGroupsByClient({
    clientId,
    filter,
    pageSize,
    page,
  }: any): Promise<GroupPage> {
    try {
      const groupArray: any = await lastValueFrom(
        this.http.get<Array<GroupDTO>>(this.urlBase + `/profiles/client/${clientId}`, {
          params: {
            filter: filter,
            pageSize: pageSize,
            page: page,
          },
        })
      );

      if (groupArray) {
        return groupArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getGeneralData(clientId: number): Promise<ProfileGeneralData> {
    try {
      const data: ProfileGeneralData = await lastValueFrom(this.http.get<ProfileGeneralData>(this.urlBase + `/profiles/general-data/client/${clientId}`));

      if (data) {
        return data;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }

    throw new Error();
  }

  async getByGroup(groupId: number): Promise<Profile> {
    try {
      const profile: Profile = await lastValueFrom(this.http.get<Profile>(this.urlBase + `/profiles/group/${groupId}`));

      if (profile) {
        return profile;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }

    throw new Error();
  }

  async save(profile: Profile): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/profiles`, profile, { 'headers': { 'Content-Type': 'application/json' } }));
      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async edit(profile: Profile): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.http.put(this.urlBase + `/profiles`, profile, { 'headers': { 'Content-Type': 'application/json' } }));

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async deleteByGroup(groupId: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/profiles/group/${groupId}`, { observe: 'response' }));
      if (success.status == 200 || success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async changeGroupSituation(group: Group): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.put(this.urlBase + `/profiles/group/change-situation`, group, { 'headers': { 'Content-Type': 'application/json' }, observe: 'response' }));
      if(success.status == 200 || success.status == 204) return true;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }
}
