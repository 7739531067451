import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import {
  Observable,
  catchError,
  lastValueFrom,
  map,
  of,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { PartnerGeneralData } from '../domains/partner/general-data';
import { Partner } from '../domains/partner/partner';
import { PartnerServiceInterface } from '../interfaces/partner.service.interface';
import { PcvSearchResponseDTO, PcvSearchResponsePage } from '../domains/partner/pcv-search-response-dto';

@Injectable({
  providedIn: 'root',
})
export class PartnerService implements PartnerServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async getAll(filter?: Optional): Promise<Partner[]> {
    try {
      const params = new HttpParams({ fromObject: { ...(filter as any) } });
      const partners: Array<Partner> = await lastValueFrom(
        this.http.get<Array<Partner>>(this.urlBase + `/partners`, { params })
      );

      if (partners) {
        return partners;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  findClientPcvPage(clientId: number, page: number, pageSize: number, filter: string): Observable<PcvSearchResponsePage | null> {
    return this.http.get<PcvSearchResponsePage>(`${this.urlBase}/partners/list/${clientId}`, {
      params: {
        filter: filter,
        page: page,
        pageSize: pageSize,
      }
    }).pipe(
      catchError((error) => {
        this.toastComponent.showApiError(error);
        return of(null);
      })
    );
  }

  findAdminPcvPage(page: number, pageSize: number, filter: string): Observable<PcvSearchResponsePage | null> {
    return this.http.get<PcvSearchResponsePage>(`${this.urlBase}/partners/list-admin`, {
      params: {
        filter: filter,
        page: page,
        pageSize: pageSize,
      }
    }).pipe(
      catchError((error) => {
        this.toastComponent.showApiError(error);
        return of(null);
      })
    );
  }

  async save(
    partner: Partner,
    image?: File,
    attachments?: File[]
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append(
      'pcv',
      new Blob([JSON.stringify(partner)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    if (attachments && attachments.length > 0) {
      attachments.forEach((file) => {
        formData.append('attachments', file, file.name);
      });
    }
    return await lastValueFrom(
      this.http
        .post<boolean>(this.urlBase + `/partners`, formData, {
          observe: 'response',
        })
        .pipe(
          catchError((error) => {
            this.toastComponent.showApiError(error);
            return of(false);
          }),
          map((value) => !!value)
        )
    );
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/partners/${id}`, {
          observe: 'response',
        })
      );
      if (success.status == 200 || success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  getById(id: number): Observable<Partner> {
    return this.http.get<Partner>(`${this.urlBase}/partners/${id}`).pipe(
      catchError((e) => {
        this.toastComponent.showApiError(e);
        return throwError(() => e);
      })
    );
  }

  async getByUserId(id: number): Promise<Partner> {
    try {
      const partner: Partner = await lastValueFrom(this.http.get<Partner>(this.urlBase + `/partners/user/${id}`));

      if (partner) {
        return partner;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async edit(
    partner: Partner,
    image?: File,
    attachments?: File[]
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append(
      'pcv',
      new Blob([JSON.stringify(partner)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    if (attachments && attachments.length > 0) {
      attachments.forEach((file) => {
        formData.append('attachments', file, file.name);
      });
    }

    return await lastValueFrom(
      this.http
        .put<boolean>(this.urlBase + `/partners`, formData, {
          observe: 'response',
        })
        .pipe(
          catchError((error) => {
            this.toastComponent.showApiError(error);
            return of(false);
          }),
          map((value) => !!value)
        )
    );
  }

  async toggle(id: number, isActive: boolean): Promise<boolean> {
    const url = `${this.urlBase}/partners/toggle-pcv?id=${id}&isActive=${isActive}`;

    return await lastValueFrom(
      this.http.put<boolean>(url, null, { observe: 'response' }).pipe(
        catchError((error) => {
          this.toastComponent.showApiError(error);
          return of(false);
        }),
        map((value) => !!value)
      )
    );
  }

  async getGeneralData(): Promise<PartnerGeneralData> {
    try {
      const partnerGeneralData: PartnerGeneralData = await lastValueFrom(
        this.http.get<PartnerGeneralData>(
          this.urlBase + `/partners/general-data`
        )
      );

      if (partnerGeneralData) {
        return partnerGeneralData;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getActivePartnersByClientId(id: number): Promise<Partner[]> {
    try {

      const partners: Array<Partner> = await lastValueFrom(
        this.http.get<Array<Partner>>(this.urlBase + `/partners/active/by/client/${id}`)
      );

      if (partners) {
        return partners;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getActiveAdminPartners(): Promise<PcvSearchResponseDTO[]> {
    try {
      const partners: Array<PcvSearchResponseDTO> = await lastValueFrom(
        this.http.get<Array<PcvSearchResponseDTO>>(this.urlBase + `/partners/active/admin`)
      );

      if (partners) {
        return partners;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }
}
