import { AddressType } from "../enums/address-type";

export class AddressInfo {
    streetAddress: string;
    number: string;
    complement: string;
    city: string;
    district: string;
    uf: string;
    cep: string;
    typeAddress: AddressType;

    constructor(props?: {streetAddress?:string, number?:string, complement?:string, city?:string, district?:string, uf?:string, cep?:string, addressType?: AddressType}) {
        this.streetAddress = props?.streetAddress || "";
        this.number = props?.number || "";
        this.complement = props?.complement || "";
        this.city = props?.city || "";
        this.district = props?.district || "";
        this.uf = props?.uf || "";
        this.cep = props?.cep || "";
        this.typeAddress = props?.addressType || AddressType.TAX;
    }
}
