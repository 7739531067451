import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxRolesService } from 'ngx-permissions';
import { Observable, Subject, delay, filter, lastValueFrom, map, of, switchMap } from 'rxjs';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { LocalStorageKeys } from 'src/app/shared/enums/storage-keys';
import { environment } from 'src/environments/environment';
import { User } from '../domains/user';
import { AuthServiceInterface } from '../interfaces/auth-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  urlBase: string;
  updatingToken: boolean = false;

  private tokenUpdatedSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
    private rolesService: NgxRolesService
  ) {
    this.urlBase = environment.authorization;
  }

  // TO DO: Corrigir método para não utilizar delay e sim guardar em storage com requisição sincrona.
  refreshToken(): Observable<any> {
    const cachedClientId = localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID);
    const profileSelected = localStorage.getItem(LocalStorageKeys.WRH_CACHE_PROFILE_SELECTED);
    
    const uri = !cachedClientId ? `/refreshToken/${profileSelected}?clientId=` : `/refreshToken/${profileSelected}?clientId=${cachedClientId}`
    return of(null).pipe(
      delay(200),
      switchMap(() => {
        const refreshToken = localStorage.getItem(
          LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN
        );
        return of(refreshToken);
      }),
      filter(refreshToken => refreshToken !== null),
      switchMap(refreshToken =>
        this.http.post<any>(this.urlBase + uri, { refreshToken: refreshToken }).pipe(
          map((response) => {
            localStorage.setItem(
              LocalStorageKeys.WRH_CACHE_TOKEN,
              response.token
            );
            localStorage.setItem(
              LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN,
              response.refreshToken
            );
            return response.token;
          })
        )
      )
    );
  }

  get tokenUpdated(): Observable<void> {
    return this.tokenUpdatedSubject.asObservable();
  }

  async getUser(): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.get<User>(this.urlBase + `/userAuthenticated`)
      );

      if (response.id) {
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_USER,
          JSON.stringify(response)
        );

        const groupNames = response.groups
          .map((group) => {
            if(group.name == 'AFFETIC') group.name = 'ADMIN'
            return group.name;
          })
          .filter((name) => typeof name !== 'undefined');

        groupNames.forEach((group) => {
          this.rolesService.addRoleWithPermissions(group, response.authorities)
        });

        this.saveGroupsInCache(groupNames);
        this.saveAuthoritiesInCache(response.authorities);

        return true;
      }
    } catch (error) {
      console.log('Error -> auth.service.getUser');
      console.log(error);
    }
    return false;
  }

  async login(login: string, password: string): Promise<boolean> {
    const requestBody = {
      login,
      password,
    };

    try {
      const response = await lastValueFrom(
        this.http.post<any>(this.urlBase + `/authenticate`, requestBody, {
          headers: { 'Content-Type': 'application/json' },
        })
      );

      if (response.token) {
        localStorage.setItem(LocalStorageKeys.WRH_CACHE_TOKEN, response.token);
        localStorage.setItem(
          LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN,
          response.refreshToken
        );
        return true;
      }
    } catch (error: any) {
      if (error.error.message)
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
    }

    return false;
  }

  getToken(): string | null {
    const token = localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    if (token) {
      return token;
    }
    return null;
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    return token !== null;
  }

  logout(): void {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_USER);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_KEEP_CONNECTED);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_REFRESH_TOKEN);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_GROUPS);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_AUTHORITIES);
  }

  getUserFromCache(): User | null {
    const cacheUser = localStorage.getItem(LocalStorageKeys.WRH_CACHE_USER);
    if (cacheUser) {
      return JSON.parse(cacheUser);
    }
    return null;
  }

  async checkValidLogin(login: string, password: string): Promise<boolean> {
    const requestBody = {
      login,
      password,
    };

    try {
      const response = await lastValueFrom(
        this.http.post<any>(this.urlBase + `/authenticate`, requestBody, {
          headers: { 'Content-Type': 'application/json' },
          observe: 'response', // Indica para o Angular observar a resposta completa
        })
      );

      // Verifique diretamente o status na resposta HTTP
      if (response instanceof HttpResponse && response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      // Tratar erro de requisição
      if (error.error.message)
        this.toastComponent.showWarningCustomMessage(
          'A senha atual não confere. Tente novamente.'
        );

      return false;
    }
  }

  saveGroupsInCache(groups: Array<string>) {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_GROUPS);

    localStorage.setItem(
      LocalStorageKeys.WRH_CACHE_GROUPS,
      JSON.stringify(groups)
    );
  }

  saveAuthoritiesInCache(authorities: string[]) {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_AUTHORITIES);

    localStorage.setItem(
      LocalStorageKeys.WRH_CACHE_AUTHORITIES,
      JSON.stringify(authorities)
    );

    if (authorities.length == 0) {
      this.toastComponent.showWarningCustomMessage(
        '',
        'Nenhum perfil de acesso encontrado para este usuário.'
      );
    }
  }

  saveUserWithNewUrlAndName(uri: string, name: string) {
    const user = this.getUserFromCache();
    if (user) {
      user.uri = uri;
      user.name = name;
    }
    localStorage.setItem(LocalStorageKeys.WRH_CACHE_USER , JSON.stringify(user));
  }
}
