import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { AdminGuard } from './admin.guard';
import { ProfilePermissions } from '../constants/profile-permissions';
import { DataImportSearchComponent } from 'src/app/pages/data-import/main/data-import-search.component';

@Injectable({
  providedIn: 'root',
})
export class DataImportGuard implements CanActivateChild, CanActivate {
  constructor(
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

   
    if(childRoute.component == DataImportSearchComponent) {      
      return this.permissionsService.hasPermission(ProfilePermissions.IMPORT_IMPORT);
    }

    return false;
  }

  canActivate(): boolean | Promise<boolean> {

    if (this.adminGuard.canActivate()) {
      return true;
    }
  
    if (this.rolesService.getRole('CLIENT')) {    
      return this.permissionsService.hasPermission([ProfilePermissions.READ_CLIENT_USER, ProfilePermissions.IMPORT_IMPORT]);
    }

    return false;
  }
}
