<p-dialog [(visible)]="showNoticesCentral" [modal]="true" class="medium" [style]="{ height: '96vh' }"
  [draggable]="false" [resizable]="false" (onHide)="close()">
  <ng-template pTemplate="header" class="titles title-2 semibold extended-secondary-darker">
    <label class="subtitle subtitle-2 semibold extended-secondary-darker mt-2">
      Central de Avisos
    </label>
  </ng-template>

  <div class="row">
    <div class="col-12">

      <div class="row justify-content-between">
        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
            <input matInput [formControl]="searchUserFormControl" />
          </mat-form-field>
        </div>

        <span class="semibold extended-secondary-darker header-content mr-2 justify-items-center">
          <div class="d-flex" pTooltip="Número de usuários que visualizaram esse aviso" tooltipPosition="top">
            <label class="mt-1 mr-1">{{ numberOfUserSawThatNotice }}</label>
            <mat-icon matPrefix class="icon-search" style="font-size: 28px;">visibility</mat-icon>
          </div>
        </span>
      </div>

      <table mat-table [dataSource]="noticesCentralDataSource" class="mat-elevation-z8" style="width: 100%;" matSort>

        <ng-container matColumnDef="viewerUserName">
          <th mat-header-cell *matHeaderCellDef class="column-50 semibold extended-secondary-darker header-content">
            Nome
          </th>
          <td mat-cell *matCellDef="let user" class="column-50 column-category truncate-words">
            <span [pTooltip]="user.viewerUserName" tooltipPosition="top">
              {{user.viewerUserName}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="viewingDate">
          <th mat-header-cell *matHeaderCellDef class="column-15 semibold extended-secondary-darker header-content">
            Data de Visualização</th>
          <td mat-cell *matCellDef="let user" class="column-15 column-category truncate-words">
            <span [pTooltip]="globalFunctions.convertDate(user.viewingDate)" tooltipPosition="top">
              {{globalFunctions.convertDate(user.viewingDate)}}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="noticesCentralColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: noticesCentralColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true" (page)="onPageChanger($event)">
      </mat-paginator>
    </div>
  </div>

</p-dialog>
