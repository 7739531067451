export type Education =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '99';

const Types = {
  ILLITERATE: { label: 'Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou', value: '01' },
  INCOMPLETEFOURTHGRADE: {
    label: 'Até o 5º ano incompleto do ensino fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular',
    value: '02',
  },
  COMPLETEDFOURTHGRADE: {
    label: '5º ano completo do ensino fundamental',
    value: '03',
  },
  INCOMPLETEFIFTHTOEIGHTHGRADE: {
    label: 'Do 6º ao 9º ano do ensino fundamental incompleto (antiga 5ª a 8ª série)',
    value: '04',
  },
  ELEMENTARYSCHOOLCOMPLETE: {
    label: 'Ensino fundamental completo',
    value: '05',
  },
  HIGHSCHOOLINCOMPLETE: {
    label: 'Ensino médio incompleto',
    value: '06',
  },
  HIGHSCHOOLCOMPLETE: {
    label: 'Ensino médio completo',
    value: '07',
  },
  HIGHEREDUCATIONINCOMPLETE: { label: 'Educação superior incompleta', value: '08' },
  HIGHEREDUCATIONCOMPLETE: { label: 'Educação superior completa', value: '09' },
  POSTGRADUATECOMPLETE: { label: 'Pós-graduação completa', value: '10' },
  MASTERCOMPLETE: { label: 'Mestrado completo', value: '11' },
  DOCTORATECOMPLETE: { label: 'Doutorado completo', value: '12' },
  OTHER: { label: 'Outro', value: '99' },
};

export const EducationWithLabels = Object.values(Types);

