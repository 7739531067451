import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../components/toaster/toast/toast.component';
import { lastValueFrom } from 'rxjs';
import { ProfileServiceInterface } from '../../interfaces/profile-service.interface';
import { Group } from '../../domains/group';
import { Profile } from '../../domains/profile/profile';
import { ProfileGeneralData } from '../../domains/profile/profile-general-data';
import { Role } from '../../domains/profile/role';
import { User } from '../../domains/user';

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceMock implements ProfileServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async getGroupsByClient(clientId: number): Promise<Group[]> {
    try {
      const groups: Group[] = await lastValueFrom(this.http.get<Group[]>(this.urlBase + `/groups?clientId=${clientId}`));
      return groups;
    } catch (error) {
        console.error(error);
    }
    throw new Error();
  }

  async getGeneralData(clientId: number): Promise<ProfileGeneralData> {
    try {
      const roles: Role[] = await lastValueFrom(this.http.get<Role[]>(this.urlBase + `/roles`));
      const users: User[] = await lastValueFrom(this.http.get<User[]>(this.urlBase + `/users`));
      const generalData = new ProfileGeneralData();
      generalData.roles = roles;
      generalData.users = users;

      return generalData;
    } catch (error) {
        console.error(error);
    }
    throw new Error();
  }

  async getByGroup(groupId: number): Promise<Profile> {
    try {
      const profile: Profile[] = await lastValueFrom(this.http.get<Profile[]>(this.urlBase + `/profiles?group.id=${groupId}`));

      return profile[0];
    } catch (error) {
        console.error(error);
    }
    throw new Error();
  }

  async save(profile: Profile): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/profiles`, profile, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async edit(profile: Profile): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.http.put(this.urlBase + `/profiles?group.id=${profile.group.id}`, profile, { 'headers': { 'Content-Type': 'application/json' } }));

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async deleteByGroup(groupId: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/groups/${groupId}`, { observe: 'response' }));
      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async changeGroupSituation(group: Group): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.http.patch(this.urlBase + `/groups`, group, { 'headers': { 'Content-Type': 'application/json' } }));

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }
}
