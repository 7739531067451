// cache.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cacheKey = 'acaoRealizadaNoDia';

  constructor() {}

  shouldPerformAction(): boolean {
    const lastLoginDate = localStorage.getItem(this.cacheKey);

    const isToday = new Date().toLocaleDateString();
    if (lastLoginDate !== isToday) {
      localStorage.setItem(this.cacheKey, isToday);
      return true;
    }

    return false;
  }

  refreshShouldPerformAction(): void {
    localStorage.removeItem(this.cacheKey);
  }
}
