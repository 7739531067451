import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root',
})
export class NoticeGuard implements CanActivate {
  constructor(
    private adminGuard: AdminGuard,
  ) {}

  canActivate(): boolean | Promise<boolean> {
    return this.adminGuard.canActivate();
  }
}
