<div style="cursor: pointer;">

  <button class="btn btn-primary subtitle subtitle-1 semibold white  btn-custom" style="padding: 0;" [matMenuTriggerFor]="menu">
    <mat-icon class="icon-bar menu-button">more_vert</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu" [overlapTrigger]="false" class="menu-opt" xPosition="after" yPosition="below">
  <div *ngFor="let item of menuOptionModel">

    <button style="font-size: 15px;" [disabled]="!showOptions || !item.hasPermission" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
       (click)="item.menuAction()">
      <mat-icon class="icon-secondary-color material-icons-outlined">{{item.menuIcon}}</mat-icon>
      {{item.menuTitle}}
    </button>

  </div>
</mat-menu>
