import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as StompJs from '@stomp/stompjs';
import { DataImport } from '../domains/data-import/data-import';
import { environment } from 'src/environments/environment';
import { AuthService } from "../services/auth.service";
import { ToastComponent } from '../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private messageSubject: Subject<DataImport> = new Subject<DataImport>();

  client: StompJs.Client | undefined;

  constructor(private AuthService: AuthService, private toastComponent: ToastComponent) { }

  getMessageSubject(): Subject<DataImport> {
    return this.messageSubject;
  }

  connect(clientId: string, userId: string) {
    const token = this.AuthService.getToken();
    this.client = new StompJs.Client({
      brokerURL: environment.bff_webSocket,
      connectHeaders: {
        userId: userId,
        clientId: clientId,
        authorization: `Bearer ${token}`
      },
    });
    this.client.onConnect = (frame: any) => {
      this.client?.subscribe(`/user/${userId}/topic/import`, (greeting: any) => {
        const value = JSON.parse(greeting.body);
        this.messageSubject.next(value);
        this.toastComponent.showInfoCustomMessage(
          'Arquivo processado!',
          'Um arquivo acaba de ser processado.'
        );
        if (!value.containsUnprocessedImport) {
          this.disconnect();
        }
      });
    };
    this.client.onWebSocketError = (error: any) => {
      console.error('Error with websocket', error);
    };
    this.client.activate();
  }

  disconnect() {
    this.client?.deactivate();
  }
}
