import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { PartnersSearchComponent } from 'src/app/pages/partners/main/partners-search.component';
import { PartnersComponent } from 'src/app/pages/partners/partners/partners.component';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root'
})
export class PartnerGuard implements CanActivate {

  constructor(
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.adminGuard.canActivate()) {
      return true;
    }

    if(childRoute.component == PartnersSearchComponent) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_PARTNER);
    }

    if(childRoute.component == PartnersComponent && state.url.includes('new-partner')) {
      return this.permissionsService.hasPermission(ProfilePermissions.CREATE_PARTNER);
    }

    if(childRoute.component == PartnersComponent && state.url.includes('edit-partner')) {
      return this.permissionsService.hasPermission(ProfilePermissions.UPDATE_PARTNER);
    }

    if(childRoute.component == PartnersComponent && state.url.includes('view-partner')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_PARTNER);
    }

    return false;
  }

  canActivate(): boolean | Promise<boolean> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (this.rolesService.getRole('CLIENT')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_PARTNER);
    }

    return false;
  }
}
