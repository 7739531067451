import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../domains/user';
import { UserServiceInterface } from '../../interfaces/user-service.interface';
@Injectable({
  providedIn: 'root'
})
export class UserServiceMock implements UserServiceInterface {

  urlBase: string;
  user: User = new User();

  constructor(private http: HttpClient) {
    this.urlBase = environment.authorization;
  }

  async saveUser(user: User): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/users`, user,  {'headers': {'Content-Type': 'application/json'}}));

      if (success) {
        return true;
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async editUser(user: User): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.put(this.urlBase + `/users/${user.id}`, user,  {'headers': {'Content-Type': 'application/json'}}));

      if (success) {
        return true;
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async delete(user: User): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.delete(this.urlBase + `/users/${user.id}`));
      if (success) {
        return true;
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }
    return false;
  }

  getUser(): User {
    return this.user;
  }

  setUser(user: User): void {
    this.user = user;
  }
}
