import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashBoardServiceInterface } from '../interfaces/dashboard-service.interface';
import { AcceptTerm } from '../domains/dashboard/accpet-term';
import { ActivationAndPolicyAcceptanceInterval } from '../domains/dashboard/activation-and-policy-acceptance';
import { lastValueFrom } from 'rxjs';
import { PeriodType } from '../types/period-type';
import { PostLikedComment } from '../domains/dashboard/post-liket-comment';
import { ChartPeriodType } from '../types/chart-period-type';
import { ChartResponse } from '../domains/dashboard/chart-response';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService implements DashBoardServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient
  ) { this.urlBase = environment.bff_web + '/dashboards' }

  async findMostLikedAndCommentedPostsByPeriod(period: PeriodType, clientId: number): Promise<PostLikedComment> {
    try {
      const params = new HttpParams()
      .set('periodType', `${period}`)
      .set('clientId', `${clientId}`)

      const postLikedComment = await lastValueFrom(
        this.http.get<PostLikedComment>(this.urlBase + `/post-most-liked-commented/period`, {params})
      );
      return postLikedComment;
    } catch (error) {
      throw new Error();
    }
  }

  async getTotalPost(clientId: number): Promise<number> {
    const params = new HttpParams()
    .set('clientId', `${clientId}`)

    try {
      const totalPost = await lastValueFrom(
        this.http.get<number>(this.urlBase + `/total-post`, {params})
      );
      return totalPost;
    } catch (error) {
      throw new Error();
    }
  }

  async getIntervalsBetweenActivationAndPolicyAcceptance(clientId: number): Promise<ActivationAndPolicyAcceptanceInterval> {
    try {
      const activationAndPolicyAcceptance = await lastValueFrom(
        this.http.get<ActivationAndPolicyAcceptanceInterval>(this.urlBase + `/activation-and-policy-acceptance-intervals/${clientId}`)
      );
      return activationAndPolicyAcceptance;
    } catch (error) {
      throw new Error();
    }
  }

  async getAcceptTermByPeriod(period: PeriodType, clientId: number): Promise<AcceptTerm> {
    try {
      const params = new HttpParams()
      .set('period', `${period}`)
      .set('clientId', `${clientId}`)

      const acceptTerm = await lastValueFrom(
        this.http.get<AcceptTerm>(this.urlBase + `/accept-term`, {params})
      );
      return acceptTerm;
    } catch (error) {
      throw new Error();
    }
  }

  async getAcceptedTermByPeriodChart(period: ChartPeriodType, clientId: number): Promise<ChartResponse[]> {
    try {
      const params = new HttpParams()
      .set('period', `${period}`)
      .set('clientId', `${clientId}`)

      const chartData = await lastValueFrom(
        this.http.get<ChartResponse[]>(this.urlBase + `/chart/accept-term`, {params})
      );
      return chartData;
    } catch (error) {
      throw new Error();
    }
  }

  async getPostsLikesByPeriodChart(period: ChartPeriodType, clientId: number): Promise<ChartResponse[]> {
    try {
      const params = new HttpParams()
      .set('period', `${period}`)
      .set('clientId', `${clientId}`)

      const chartData = await lastValueFrom(
        this.http.get<ChartResponse[]>(this.urlBase + `/chart/post-likes`, {params})
      );
      return chartData;
    } catch (error) {
      throw new Error();
    }
  }

  async getPostsCommentsByPeriodChart(period: ChartPeriodType, clientId: number): Promise<ChartResponse[]> {
    try {
      const params = new HttpParams()
      .set('period', `${period}`)
      .set('clientId', `${clientId}`)

      const chartData = await lastValueFrom(
        this.http.get<ChartResponse[]>(this.urlBase + `/chart/post-comments`, {params})
      );
      return chartData;
    } catch (error) {
      throw new Error();
    }
  }
}
