import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../domains/user';
import { AuthServiceInterface } from '../../interfaces/auth-service.interface';
import { LocalStorageKeys } from 'src/app/shared/enums/storage-keys';
import { NgxRolesService } from 'ngx-permissions';
import { ProfilePermissions } from '../../constants/profile-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceMock implements AuthServiceInterface {

  urlBase: string;

  constructor(private http: HttpClient,
    private rolesService: NgxRolesService) {
    this.urlBase = environment.authorization;
  }

  refreshToken(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  async getUser(): Promise<boolean> {
    const token = this.getToken();
    try {
      const userArray = await lastValueFrom(this.http.get<Array<User>>(this.urlBase + `/users?token=${token}`));
      const user = userArray[0];

      const groupNames = ['ADMIN'];
      groupNames.forEach(group => this.rolesService.addRoleWithPermissions(group, Object.values(ProfilePermissions)))
      this.saveGroupsInCache(groupNames);
      this.saveAuthoritiesInCache(Object.values(ProfilePermissions));

      if (user) {
        return true;
      }
    } catch (error) {
     console.error(error);
    }
    return false;
  }

  async login(email: string, password: string): Promise<boolean> {
    try {
      const userArray = await lastValueFrom(this.http.get<Array<User>>(this.urlBase + `/users?email=${email}&password=${password}`));
      const user = userArray[0];

      if (user) {
        localStorage.setItem(LocalStorageKeys.WRH_CACHE_TOKEN, user.token);
        localStorage.setItem(LocalStorageKeys.WRH_CACHE_USER , JSON.stringify(user));
        return true;
      }
    } catch (error) {
     console.error(error);
    }
    return false;
  }

  getToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    return token !== null;
  }


  logout(): void {
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_TOKEN);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_USER );
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_KEEP_CONNECTED);
    localStorage.removeItem(LocalStorageKeys.WRH_CACHE_GROUPS);
  }

  getUserFromCache(): User | null {
    const cacheUser = localStorage.getItem(LocalStorageKeys.WRH_CACHE_USER);
    if (cacheUser) {
      return JSON.parse(cacheUser);
    }
    return null;
  }

  saveGroupsInCache(groups: Array<string>) {
    localStorage.setItem(LocalStorageKeys.WRH_CACHE_GROUPS, JSON.stringify(groups));
  }

  saveAuthoritiesInCache(authorities: string[]) {
    localStorage.setItem(LocalStorageKeys.WRH_CACHE_AUTHORITIES, JSON.stringify(authorities));
  }
}
