import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { User } from 'src/app/shared/domains/user';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { map } from 'rxjs';

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss'],
})
export class ClientDialogComponent implements OnInit {
  @ViewChild('myInput')
  myInputVariable: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('dataImportTableSort') dataImportTableSort!: MatSort;
  @Input() client?: ClientDTO[];

  @Output() selectedListEmitter = new EventEmitter<ClientDTO[]>();
  searchFormControl = new FormControl();
  dataSource = new MatTableDataSource<any>();

  displayedColumns: string[] = [
    'checkbox',
    'cnpj',
    'corporateName',
  ];

  currentList: ClientDTO[] | undefined = [];
  currentListView: ClientDTO[] | undefined = [];
  phoneMask: string = '(00) 00000-0000';
  cnpjMask: string = '00.000.000/0000-00';
  selection = new SelectionModel<ClientDTO>(true, []);
  userCache: User | null | undefined;
  lastFilterValue = '';
  hasChange = false;

  constructor(
    private router: Router,
    public globalFunctions: GlobalFunctions,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClientDialogComponent>,
  ) {
    this.searchFormControl.valueChanges
    .pipe(
      map((value: string) => value.trim().toLocaleLowerCase()),
      map((value: string) => value.trim().replace(/[.\-()/ ]/g, '').toLocaleLowerCase())
    )
    .subscribe((searchedValue) => this.applyFilter(searchedValue));
    this.configTable();
  }

  ngOnInit(): void {
    this.getList();
    setTimeout(() => {
      this.configTable();
    }, 1000);
  }

  ngAfterViewInit(): void {
    this.configTable();

    setTimeout(() => {
      this.selection = new SelectionModel<ClientDTO>(true, []);
      const allActiveClients = this.data.allClients.filter(
        (data: ClientDTO) => data.isActive
      );
      this.currentListView = allActiveClients;
      this.currentList = this.currentListView;
      this.dataSource = new MatTableDataSource(this.currentListView);
      if (this.data.list.length > 0) {
        this.currentListView?.forEach((currentItem) => {
          const matchingItem = this.data.list.find(
            (item: ClientDTO) => item.id === currentItem.id
          );

          if (matchingItem) {
            currentItem.checked = true;
            this.selection.select(currentItem);
          } else {
            currentItem.checked = false;
            this.selection.select(currentItem);
          }
        });
        this.dataSource = new MatTableDataSource(this.currentListView);

        this.configTable();
      } else {
        this.clearSelection();
      }
    });
  }

  getList() {
    this.currentList = this.currentListView?.slice();
    this.dataSource = new MatTableDataSource(this.currentList);
    this.configTable();
  }

  ngOnChanges(): void {
    this.getList();
  }

  onMessage(message: any) {
    console.log(message);
  }

  private configTable() {
    if (this.paginator) this.dataSource.paginator = this.paginator;
    if (this.dataImportTableSort) {
      this.dataSource.sort = this.dataImportTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property == 'codeAndSurcode') {
          return (
            item['code']?.toLowerCase() + ' ' + item['lastcode']?.toLowerCase()
          );
        }

        return item[property]?.toLowerCase();
      };
    }
  }

  isAllSelected() {
    const numSelected = this.selection?.selected.filter(item => !!item.checked)?.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.clearSelection()
      : this.dataSource?.data?.forEach((row) => {
          this.selection.select(row);
          row.checked = true;
        });

    this.hasChange = true;
  }

  onSave() {
    const checkedItems = this.currentList?.filter((item) => item.checked);
    this.selectedListEmitter.emit(checkedItems);
    this.onNoClick();
  }

  clearSelection() {
    this.selection.clear();
    this.dataSource.data.forEach((row) => {
      row.checked = false;
    });
  }

  logSelection() {
    this.selection.selected.forEach((s) => console.log(s.code));
  }

  selectItem(participatingBeneficiary: ClientDTO) {
    this.selection.toggle(participatingBeneficiary);
    this.dataSource.data = [...this.dataSource.data];
    this.hasChange = true;
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  applyFilter(searchText: string) {
    const filterValue = searchText.toLocaleLowerCase();
    if (!filterValue && this.lastFilterValue) {
      this.currentListView = this.currentList;
    } else {
      this.currentListView = this.currentList?.filter((item: ClientDTO) => {
        return (
          item.code
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.cnpj?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.cnpj?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.corporateName?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.email?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      this.dataSource = new MatTableDataSource(this.currentListView);
    }
    this.lastFilterValue = filterValue;
    this.configTable();
  }
}
