<p-dialog [(visible)]="visible" class="large" (onHide)="close(); changeCheckbox = false" [modal]="true"
  [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header" class="mt-3 py-0">
    <label class="pl-4 subtitle subtitle-2 semibold extended-secondary-darker mt-2">
      {{showCheckbox ? 'Associar Grupos' : 'Grupos relacionados'}}
    </label>
  </ng-template>

  <app-search-table *ngIf="visible" [columnsConfigurations]="columnsConfiguration" [tableSourceData]="dataSource"
    [showSearchComponent]="showCheckbox" (onFilter)="applyFilter($event)" [showAddButton]="false"
    (onChangeCheckBoxClick)="changeCheckbox = true" [disablePagination]="true" [disableToggleButton]="true"
    [showMobileVision]="true">
  </app-search-table>

  <ng-template pTemplate="footer" *ngIf="showCheckbox">
    <div class="d-flex justify-content-end mt-3">
      <div class="d-flex justify-content-end">
        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
          (click)="close()">Cancelar</button>&nbsp;
        <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="onConfirm()"
          [disabled]="!changeCheckbox">
          Associar
        </button>&nbsp;
      </div>
    </div>
  </ng-template>
</p-dialog>
