<div style="margin-top: 20px">
  <div class="row d-flex">
    <div class="col-4 col-sm-1 align-items-center ">
      <label class="titles title-2 semibold extended-secondary-darker mr-2">Clientes</label>
    </div>
    <div class="col-6 col-sm-2" *ngIf="!globalFunctions.isMobile()">
    <mat-icon class="mtr-icon"
      [style]="globalFunctions.isMobile() ? 'margin-left: -20px;' : ''"
      pTooltip="Aviso exclusivo para o público-alvo selecionado do cliente selecionado. Caso não seja associado nenhum cliente ao aviso, ele será exibido para todos do público-alvo com situação ativo."
      tooltipStyleClass="tooltip-info" tooltipPosition="left">info_outline</mat-icon>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 16px;"
  >
    <div class="col-12 col-sm-3">
      <mat-form-field appearance="outline" class="mat-input">
        <mat-icon matPrefix class="icon-search">search</mat-icon>
        <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
        <input matInput [formControl]="searchFormControl" />
      </mat-form-field>
    </div>
    <div class="col-sm-7"></div>
    <div class="col-sm-2" *ngIf="!globalFunctions.isMobile()">
      <button
        class="btn btn-secondary subtitle subtitle-1 semibold primary-color align-items-center justify-content-center"
        [disabled]="disabled"
        type="button"
        (click)="openClientModal()"
      >
        + Associar Cliente
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="!globalFunctions.isMobile()">
        <table
          mat-table
          #dataImportTableSort="matSort"
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          w-100
          matSort
        >
          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-30">
              CNPJ
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-15">
              {{ dataImport.cnpj | mask : cnpjMask }}
            </td>
          </ng-container>

          <ng-container matColumnDef="corporateName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-30">
              Razão Social
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-15">
              {{ dataImport.corporateName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-20">
              Telefone
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-15">
              {{ dataImport.phone | mask : phoneMask }}
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-20">
              E-mail
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-15">
              {{ dataImport.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="column-8"></th>
            <td mat-cell *matCellDef="let client" class="column-15 p-0 d-flex justify-content-end">
              <button mat-icon-button [pTooltip]="'Excluir'" tooltipPosition="top" (click)="removeItem(client.id)">
                <mat-icon class="icon-secondary-color material-icons-outlined">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true">
        </mat-paginator>
      </ng-container>
    </div>
  </div>

  <div *ngIf="globalFunctions.isMobile()">
    <mat-card class="d-flex flex-column mb-3 cardContainer" *ngFor="let itemS of dataSource.data">
      <span class="cardTitle">{{ itemS.corporateName }}</span>
      <span class="cardText">CNPJ: {{ itemS.cnpj  | mask : cnpjMask  }}</span>
      <span class="cardText">{{ itemS.phone | mask : phoneMask }}</span>
      <span class="cardText">{{ itemS.email }}</span>
    </mat-card>
  </div>
  <div *ngIf="globalFunctions.isMobile()" class="col-12" style="padding: 0px !important;">
    <button
      class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
      type="button"
      [disabled]="disabled"
      (click)="openClientModal()"
    >
      Associar Cliente
    </button>
  </div>
</div>
