<div id="main-content">
  <h3 id="main-title">POLÍTICA DE PRIVACIDADE | TERMOS E POLÍTICAS DE USO</h3>
  <hr>

  <div>
    <h3>INTRODUÇÃO</h3>
    <p id="text-content">A política de privacidade tem como objetivo esclarecer e informar as diretrizes e práticas da WeDo com relação à
      segurança e ao tratamento conferidos aos seus dados pessoais, bem como nossa responsabilidade com as informações
      captadas ou disponibilizadas em nosso domínio, sejam elas referente à coleta, produção, recepção, classificação,
      utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
      avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
    <p id="text-content">Atendendo ao que entende ser o Princípio da Legitima Expectativa do usuário, a WeDo Tecnologia
      esclarece que atua no fornecimento de soluções de prestação de serviços. Em contrapartida,
      entende que o tratamento de dados pessoais realizados se dá para benefício do usuário, que
      poderá usufruir de forma mais ampla de seus produtos e serviços. Dessa forma, o usuário / cliente
      se declara ciente de que o uso de seus dados pessoais servirá, sempre dentro dos limites da
      legislação e observando seus direitos e garantias fundamentais, para assim atingir a melhor
      performance para benefício de si e do usuário / cliente.
    </p>

    <h3>DECLARAÇÃO DE PRIVACIDADE</h3>
    <p id="text-content">Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles usuários que utilizam
      nossa plataforma. Ao fazê-lo de forma ativa, agimos na qualidade de controlador de dados pessoais.</p>
    <p id="text-content">Ademais, existem dados que recebemos por parte de controladores de dados pessoais e neste cenário,
      agimos na qualidade de operadores de dados pessoais.</p>
    <p id="text-content">Em ambos, estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).</p>
    <p id="text-content">Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que
      contém informações importantes sobre:</p>
    <ul>
      <li>Quais dados coletamos e/ou recebemos e o que fazemos com eles;</li>
      <li>Seus direitos em relação aos seus dados pessoais;</li>
      <li>Como entrar em contato conosco.</li>
    </ul>

    <h3>DADOS QUE COLETAMOS E MOTIVOS DA COLETA </h3>
    <p id="text-content">Nossa plataforma coleta e utiliza alguns dados empresariais e pessoais de nossos clientes e usuários, de acordo
      com o disposto nesta seção.</p>
    <p id="text-content">Dados empresariais e de parceiros comerciais fornecidos expressamente pelos usuários clientes:</p>
    <ul>
      <li>CNPJ</li>
      <li>Inscrição Estadual</li>
      <li>CNAE</li>
      <li>Razão Social</li>
      <li>Nome Fantasia</li>
      <li>Site</li>
      <li>Telefone de contato</li>
      <li>E-mail de contato</li>
      <li>Endereço de registro</li>
      <li>Endereço de correspondência</li>
      <li>Contatos (nome, telefone, cargo, e-mail)</li>
      <li>Redes sociais (LinkedIn, Instagram, Facebook) - exclusivo para parceiros </li>
    </ul>
    <p id="text-content">Dados pessoais fornecidos expressamente pelos usuários clientes e/ou beneficiários:</p>
    <ul>
      <li>Dados Pessoais
        <ul>
          <li>Dados pessoais e dados sensíveis </li>
        </ul>
      </li>
      <li>Dados Profissionais<ul>
          <li>Identificação Profissional</li>
          <li>Contrato de Trabalho </li>
          <li>Informações da Empregadora</li>
          <li>Informações Salariais</li>
          <li>Informações Bancárias</li>
        </ul>
      </li>
      <li>Dados de Acesso</li>
      <li>Dependentes</li>
      <li>Endereço de registro</li>
      <li>Endereço de correspondência</li>
      <li>Contatos (nome, telefone, cargo, e-mail)</li>
      <li>Dados acidentalmente inseridos pelos titulares de dados e não solicitados pela WeDo.</li>
    </ul>
    <p id="text-content">A coleta destes dados ocorre nos seguintes momentos:</p>
    <ul>
      <li>Quando um novo Cliente é cadastrado, e as informações são supridas por este em complemento a recuperação dos dados
        por API (Application Programming Interface, ou Interface de Programação de Aplicações) da Receita Federal;</li>
      <li>Quando o usuário deste Cliente atualiza alguns dos dados da própria Empresa (Cliente) em questão;</li>
      <li>Quando o usuário cadastra um Colaborador, seja via tela, importação ou integração;</li>
      <li>Quando do recebimento, por integração ou importação, de informações sobre férias, holerite e informe de rendimentos;</li>
      <li>Quando o Colaborador, via app, informa ou edita algum dos seus dados.</li>
    </ul>
    <p id="text-content">Os dados fornecidos pelos usuários são coletados com as seguintes finalidades:</p>
    <ul>
      <li>Para que o cliente, através de notificações e avisos, possa realizar comunicados com os colaboradores;</li>
      <li>Para que os usuários colaboradores sejam identificados no ambiente e possam ter acesso às suas informações contratuais e de RH (férias, holerite e informe de rendimentos);</li>
      <li>Para que nós possamos entrar em contato, enviar feedback aos nossos clientes;</li>
      <li>Para efetuar estatísticas, ações de marketing, estudos, pesquisas, planejamento de projetos e levantamentos pertinentes às atividades
        e comportamentos do titular de dados ao utilizar os sites, aplicativos e serviços prestados pela WeDo, realizando tais operações, preferencialmente,
        de forma anonimizada com dados anonimizados;</li>
      <li>Para promover a WeDo, seus eventos e serviços, além de informar sobre novidades, funcionalidades, conteúdos, notícias e demais informações relevantes ao colaborador;</li>
      <li>Para realizar a identificação do usuário, autenticação e autorização de acesso; </li>
      <li>Para atender adequadamente a eventuais solicitações e dúvidas; </li>
      <li>Para o cumprimento de obrigações legais, regulatórias, contratuais ou ordens exaradas por Autoridades da Administração Pública;</li>
      <li>Para manter atualizados os cadastros para fins de contato por telefone, correio eletrônico, SMS, mala direta ou por outros meios de comunicação.</li>
    </ul>
    <h3>COMPARTILHAMENTO DE DADOS PESSOAIS COM TERCEIROS</h3>
    <p id="text-content">A nossa prática é de não compartilhar seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir
      alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública ou até mesmo
      para fins de campanhas publicitárias perante autorização prévia.</p>
    <h3>POR QUANTO TEMPO SEUS DADOS SERÃO ARMAZENADOS</h3>
    <p id="text-content">Os dados pessoais coletados pela plataforma são armazenados e utilizados por período que corresponda ao necessário para atingir
      as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador da plataforma
      e as disposições legais ou regulatórias aplicáveis.</p>
    <h3>BASES LEGAIS PARA O TRATAMENTO DE DADOS PESSOAIS</h3>
    <p id="text-content">Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma
      justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.</p>
    <p id="text-content">O tratamento de dados pessoais realizados se dará mediante enquadramento na base jurídica de execução de contrato,
      cumprimento de obrigação legal ou regulatória e no legítimo interesse, levando-se em conta a legítima expectativa do
      usuário de usufruir de forma ampla dos serviços ofertados pela WeDo Tecnologia ou seus parceiros.</p>
    <p id="text-content">Conheça mais detalhes da legislação em:</p>
    <p id="text-content"><a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm">
      http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm
    </a></p>
    <h3>DIREITOS DO USUÁRIO</h3>
    <p id="text-content">O usuário da plataforma possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:</p>
    <ul>
      <li>Confirmação da existência de tratamento;</li>
      <li>Acesso aos dados;</li>
      <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
      <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em lei;</li>
      <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisicão expressa, de acordo com a -
         regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
      <li>Eliminacão dos dados pessoais tratados com consentimento do titular, exceto nos casos previstos em lei;</li>
      <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
      <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
      <li>Revogação do consentimento.</li>
    </ul>
    <p id="text-content">É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento
      em bases legais distintas do consentimento, a menos que os dados sejam desnecessários, excessivos ou tratados em
      desconformidade com o previsto na lei.</p>
    <p id="text-content">Como o titular pode exercer seus direitos:</p>
    <p id="text-content">Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição,
      poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação a fim de resguardar
      nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, momento em que
      o requerente receberá todas as informações relacionadas.</p>
    <h3>MEDIDAS DE SEGURANÇA NO TRATAMENTO DE DADOS PESSOAIS</h3>
    <p id="text-content">Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados
      e de situações de destruição, perda, extravio ou alteração desses dados.</p>
    <p id="text-content">As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento,
      os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente
      empregados no mercado por empresas semelhantes à nossa.</p>
    <p id="text-content">Entre as medidas de segurança adotadas por nós, destacamos as seguintes:</p>
    <ul>
      <li>Armazenamento de senha utilizando hashes criptográficos;</li>
      <li>Limitamos o acesso aos dados de nossos usuários, de modo que terceiros não autorizados não possam acessá-las;
      </li>
      <li>Mantemos registros de todos aqueles que têm, de alguma forma, contato com nossos dados;</li>
      <li>Monitoramento de acesso físicos a servidores.</li>
    </ul>
    <p id="text-content">Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema
      motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário,
      que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais
      que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.</p>
    <p id="text-content">De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários,
      comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.</p>
    <h3>RECLAMAÇÃO A UMA AUTORIDADE DE CONTROLE</h3>
    <p id="text-content">Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem,
      de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.</p>
    <h3>ALTERAÇÕES NESTA POLÍTICA</h3>
    <p id="text-content">A presente versão desta Política de Privacidade foi atualizada pela última vez em: 08/08/2024.</p>
    <p id="text-content">Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações
      feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
    <p id="text-content">Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.</p>
    <p id="text-content">Ao utilizar os nossos serviços, o usuário concorda e aceita os termos da Política vigente na data de utilização,
       cabendo ao mesmo verificá-la previamente cada vez que visitar o app.</p>
    <h3>COMO ENTRAR EM CONTATO CONOSCO</h3>
    <p id="text-content">Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos,
      entre em contato com nosso Encarregado de Proteção de Dados Pessoais, pelo canal mencionado abaixo:</p>
    <p id="text-content"> E-mail: <span id="email">Igpd@wedotec.com.br</span></p>
  </div>
</div>
