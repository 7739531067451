import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { UserProfilesComponent } from 'src/app/pages/user-profile/user-profiles/user-profiles.component';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';
import { UserProfilesSearchComponent } from 'src/app/pages/user-profile/main/user-profiles-search.component';

@Injectable({
  providedIn: 'root'
})
export class UserProfileGuard implements CanActivateChild, CanActivate {

  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> {
    if(this.adminGuard.canActivate()) {
      return true;
    }

    if(childRoute.component == UserProfilesSearchComponent) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER_PROFILE);
    }

    if(childRoute.component == UserProfilesComponent && state.url.includes('edit-profile')) {
      return this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT_USER_PROFILE);
    }

    if(childRoute.component == UserProfilesComponent && state.url.includes('new-profile')) {
      return this.permissionsService.hasPermission(ProfilePermissions.CREATE_CLIENT_USER_PROFILE);
    }

    if(childRoute.component == UserProfilesComponent && state.url.includes('view-profile')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER_PROFILE);
    }

    return false;
  }

  canActivate(): boolean | Promise<boolean> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (this.rolesService.getRole('CLIENT')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER_PROFILE);
    }

    return false;
  }
}
