import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyPolicyMainComponent } from './pages/privacy-policy/main/privacy-policy-main.component';
import { AdminGuard } from './shared/guards/admin.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { BeneficiaryGuard } from './shared/guards/beneficiary.guard';
import { CategoryGuard } from './shared/guards/category.guard';
import { UserClientGroupsGuard } from './shared/guards/client-groups.guard';
import { ClientNoticeGuard } from './shared/guards/client-notice.guard';
import { ClientUsersGuard } from './shared/guards/client-users.guard';
import { ClientGuard } from './shared/guards/client.guard';
import { CollaboratorTransferGuard } from './shared/guards/collaborator-transfer.guard';
import { CommunityForumGuard } from './shared/guards/community-forum.guard';
import { DashboardGuard } from './shared/guards/dashboard.guard';
import { DataImportGuard } from './shared/guards/data-import.guard';
import { EventGuard } from './shared/guards/event.guard';
import { FeedGuard } from './shared/guards/feed.guard';
import { GamificationGuard } from './shared/guards/gamification.guard';
import { UserGroupsGuard } from './shared/guards/group-collaborator.guard';
import { NetworkGuard } from './shared/guards/network.guard';
import { NoticeGuard } from './shared/guards/notice.guard';
import { PartnerGuard } from './shared/guards/partner.guard';
import { ProposalRequestGuard } from './shared/guards/proposal-request.guard';
import { SurveyGuard } from './shared/guards/survey.guard';
import { UserProfileGuard } from './shared/guards/user-profile.guard';
import { UserThirdPartyGuard } from './shared/guards/user-third-party.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyMainComponent,
    children: [
      { path: '', component: PrivacyPolicyMainComponent }
    ],
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'internal-users',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./pages/internal-users/internal-users.module').then(
        (m) => m.InternalUsersModule
      ),
  },
  {
    path: 'internal-users-account',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./pages/internal-users/internal-users.module').then(
        (m) => m.InternalUsersModule
      ),
  },
  {
    path: 'clients',
    canActivate: [ClientGuard],
    canActivateChild: [ClientGuard],
    loadChildren: () =>
      import('./pages/client/client-user.module').then((m) => m.ClientsModule),
  },
  {
    path: 'collaborators',
    canActivate: [BeneficiaryGuard],
    canActivateChild: [BeneficiaryGuard],
    loadChildren: () =>
      import('./pages/beneficiary/beneficiary.module').then(
        (m) => m.BeneficiaryModule
      ),
  },
  {
    path: 'benefits',
    canActivate: [PartnerGuard],
    loadChildren: () =>
      import('./pages/benefits/benefits.module').then((m) => m.BenefitsModule),
  },
  {
    path: 'partners',
    canActivate: [PartnerGuard],
    canActivateChild: [PartnerGuard],
    loadChildren: () =>
      import('./pages/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'partners-account',
    canActivate: [PartnerGuard],
    loadChildren: () =>
      import('./pages/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'users-clients',
    canActivateChild: [ClientUsersGuard],
    canActivate: [ClientUsersGuard],
    loadChildren: () =>
      import('./pages/user-client/user-client.module').then(
        (m) => m.UserClientModule
      ),
  },
  {
    path: 'users-clients-account',
    loadChildren: () =>
      import('./pages/user-client/user-client.module').then(
        (m) => m.UserClientModule
      ),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./pages/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: 'notification-mobile',
    loadChildren: () =>
      import('./pages/notification-mobile/notification-mobile.module').then(
        (m) => m.NotificationMobileModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'user-profiles',
    canActivateChild: [UserProfileGuard],
    canActivate: [UserProfileGuard],
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },
  {
    path: 'data-import',
    canActivate: [DataImportGuard],
    canActivateChild: [DataImportGuard],
    loadChildren: () =>
      import('./pages/data-import/data-import.module').then(
        (m) => m.DataImportModule
      ),
  },
  {
    path: 'notices',
    canActivate: [NoticeGuard],
    loadChildren: () =>
      import('./pages/notices/notices.module').then((m) => m.NoticesModule),
  },
  {
    path: 'notices-client',
    canActivate: [ClientNoticeGuard],
    canActivateChild: [ClientNoticeGuard],
    loadChildren: () =>
      import('./pages/notices/notices.module').then((m) => m.NoticesModule),
  },
  {
    path: 'networks',
    canActivate: [NetworkGuard],
    loadChildren: () =>
      import('./pages/networks/networks.module').then(
        (m) => m.NetworkModule
      ),
  },
  {
    path: 'posts',
    canActivate: [FeedGuard],
    loadChildren: () =>
      import('./pages/feed/feed.module').then((m) => m.FeedModule),
  },
  {
    path: 'surveys',
    canActivate: [SurveyGuard],
    loadChildren: () =>
      import('./pages/survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: 'events',
    canActivate: [EventGuard],
    loadChildren: () =>
      import('./pages/event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'user-groups',
    canActivate: [UserGroupsGuard],
    loadChildren: () =>
      import('./pages/user-group/user-group.module').then((m) => m.UserGroupModule),
  },
  {
    path: 'gamification',
    canActivate: [GamificationGuard],
    loadChildren: () =>
      import('./pages/gamification/gamification.module').then((m) => m.GamificationModule),
  },
  {
    path: 'proposal-request',
    canActivate: [ProposalRequestGuard],
    loadChildren: () =>
      import('./pages/proposal-request/proposal-request.module').then((m) => m.ProposalRequestModule),
  },
  {
    path: 'user-client-group',
    canActivate: [UserClientGroupsGuard],
    loadChildren: () =>
      import('./pages/user-client-group/user-client-group.module').then((m) => m.UserClientGroupModule),
  },
  {
    path: 'category',
    canActivate: [CategoryGuard],
    loadChildren: () =>
      import('./pages/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'dashboard',
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'community-forum',
    canActivate: [CommunityForumGuard],
    loadChildren: () =>
      import('./pages/community-forum/community-forum.module').then((m) => m.CommunityForumModule),
  },
  {
    path: 'collaborator-transfer',
    canActivate: [CollaboratorTransferGuard],
    loadChildren: () =>
      import('./pages/collaborator-transfer/collaborator-transfer.module').then((m) => m.CollaboratorTransferModule),
  },
  {
    path: 'third-party-user',
    canActivate: [UserThirdPartyGuard],
    loadChildren: () =>
      import('./pages/third-party-user/third-party-user.module').then((m) => m.ThirdPartyUserModule),
  },
  // EXAMPLE COMPONENTES
  {
    path: 'examples',
    loadChildren: () =>
      import('./pages/examples/dashboard-example.module').then(
        (m) => m.DashboardExampleModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
