import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree
} from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root',
})
export class SurveyGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService
  ) {}

  canActivate(): boolean | Promise<boolean> {
    if (this.rolesService.getRole('CLIENT') || this.rolesService.getRole('ADMIN')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_SURVEY);
    }

    return false;
  }
}
