<div class="profile-selection" [class]="!tooltipLayout ? 'login-like' : ''" *ngIf="profiles?.length! > 0 ?? false">
    <label *ngIf="!tooltipLayout" for="" class="titles title-2 primary-color">
        Selecione com qual usuário deseja se logar
    </label>
    <div class="button-group">
        <button [disabled]="blockProfiles" *ngFor="let profile of profiles" mat-button
            [style]="!tooltipLayout ? 'width: 300px;' : 'width: 200px;'"
            class="btn btn-tertiary subtitle subtitle-1 semibold primary-color" (click)="selectProfile(profile)">
            {{ profile.clientName ?? 'Administrador' }}
        </button>
    </div>
</div>