import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ConfigIntegration } from 'src/app/shared/domains/config-integration/config-integration';
import { ClientService } from 'src/app/shared/services/client.service';
import { ConfigIntegrationService } from 'src/app/shared/services/config-integration.service';
import { KeyValue, Location } from '@angular/common';
import { User } from 'src/app/shared/domains/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { NgxPermissionsService } from 'ngx-permissions';

export interface DialogDataConfigIntegration {
  clientId: number;
}
@Component({
  selector: 'app-config-integration',
  templateUrl: './config-integration.component.html',
  styleUrls: ['./config-integration.component.scss'],
})
export class ConfigIntegrationComponent implements OnInit {

  clientId: number = 0;
  maxDate = new Date(new Date().getFullYear() + '-12-31');
  isloading: boolean = false;
  clientToken = new FormControl('');
  cnpj = new FormControl('-');
  corporateName = new FormControl('-');
  tradingName = new FormControl('-');
  isActive: boolean = false;
  user: User = new User();
  isUserAdmin: boolean = false;
  canEdit = true;
  hidePassword = true;
  showModalInfoIntegration: boolean = false;
  readonly checkboxList: KeyValue<string, string>[] = [
    {
      key: 'allowIntegrateEmploymentContract',
      value: 'Contrato de Trabalho'
    },
    {
      key: 'allowIntegrateVacation',
      value: 'Férias (Período Aquisitivo, Solicitações e PDF)'
    },
    {
      key: 'allowIntegrateSalaryInformation',
      value: 'Informações Salariais'
    },
    {
      key: 'allowIntegratePayslip',
      value: 'Holerite (resumo e PDFs)'
    },
    {
      key: 'allowIntegratePersonalDocuments',
      value: 'Documentos Pessoais'
    },
    {
      key: 'allowIntegrateBankingInformation',
      value: 'Informações Bancárias'
    },
    {
      key: 'allowIntegrateIncomeReport',
      value: 'Informe de Rendimento (PDF)'
    },
    {
      key: 'allowIntegrateDependents',
      value: 'Dependentes'
    },
  ]

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private configIntegrationService: ConfigIntegrationService,
    private toastComponent: ToastComponent,
    private route: ActivatedRoute,
    private location: Location,
    private clientService: ClientService,
    private router: Router,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) {
    this.route.queryParams.subscribe(async params => {

      this.clientId = +params['id'];
      this.clientService.get(this.clientId).subscribe((client: any) => {
        this.cnpj.setValue(client.cnpj)
        this.corporateName.setValue(client.corporateName)
        this.tradingName.setValue(client.tradingName ? client.tradingName : '-')
        this.cnpj.disable()
        this.corporateName.disable()
        this.tradingName.disable()
        this.isActive = client.isActive;

      })
    });

    this.maxDate.setDate(this.maxDate.getDate() + 1);

  }

  obterInformacaoUsuario() {
    const userCache = this.authService.getUserFromCache();
    if (userCache) {
      this.user = userCache;
    }
    if (this.user.groups.some((x) => x.name === 'ADMIN')) {
      this.isUserAdmin = true;
    }

  }

  addNewConfigIntegrationFormGroup: FormGroup = this.formBuilder.group({
    id: new FormControl({ value: undefined, disabled: true }),
    allowDataReception: new FormControl(false, Validators.required),
    allowSendingData: new FormControl(false, Validators.required),
    requiresApproval: new FormControl(false),
    beginDateIncomeReport: new FormControl(null, Validators.required),
    endDateIncomeReport: new FormControl(null, Validators.required),
    allowSeniorIntegrator: new FormControl(false, Validators.required),
    seniorToken: new FormControl(null, Validators.maxLength(1000)),
    seniorUser: new FormControl(null, Validators.maxLength(100)),
    seniorPassword: new FormControl(null, Validators.maxLength(100)),
    seniorIntegrationCode: new FormControl(null),
    seniorEndpoint: new FormControl(null, Validators.maxLength(200)),
    seniorEndpointReports: new FormControl(null, Validators.maxLength(200)),
    seniorVacationProgrammedModelReport: new FormControl(null, Validators.maxLength(100)),
    seniorVacationPaidOffModelReport: new FormControl(null, Validators.maxLength(100)),
    seniorPayslipModelReport: new FormControl(null, Validators.maxLength(100)),
    seniorIncomeReportModelReport: new FormControl(null, Validators.maxLength(100)),
  });

  private addControlsOfCheckbox(): void {
    this.checkboxList.forEach((item) => {
      this.addNewConfigIntegrationFormGroup.addControl(item.key, new FormControl(false))
    })
  }

  private registerValueChanges() {
    this.addNewConfigIntegrationFormGroup.get('allowSeniorIntegrator')?.valueChanges.subscribe((value) => {
      if (value) {
        this.addNewConfigIntegrationFormGroup.get('seniorUser')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorPassword')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorIntegrationCode')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorEndpoint')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorEndpointReports')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorVacationProgrammedModelReport')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorVacationPaidOffModelReport')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorPayslipModelReport')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('seniorIncomeReportModelReport')?.addValidators(Validators.required);
        return;
      }
      this.addNewConfigIntegrationFormGroup.get('seniorUser')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorPassword')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorIntegrationCode')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorEndpoint')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorEndpointReports')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorVacationProgrammedModelReport')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorVacationPaidOffModelReport')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorPayslipModelReport')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('seniorIncomeReportModelReport')?.removeValidators(Validators.required);

      this.addNewConfigIntegrationFormGroup.get('seniorUser')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorPassword')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorIntegrationCode')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorEndpoint')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorEndpointReports')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorVacationProgrammedModelReport')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorVacationPaidOffModelReport')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorPayslipModelReport')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('seniorIncomeReportModelReport')?.setValue(null);
    })

    this.addNewConfigIntegrationFormGroup.get('allowIntegrateIncomeReport')?.valueChanges.subscribe((value) => {
      if (value) {
        this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.updateValueAndValidity();
        this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.enable();
        this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.addValidators(Validators.required);
        this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.updateValueAndValidity();
        this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.enable();
        return;
      }
      this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.disable();
      this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.removeValidators(Validators.required);
      this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.setValue(null);
      this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.disable();
    })
  }

  async ngOnInit(): Promise<void> {
    this.getConfiByClient();
    this.obterInformacaoUsuario();
    this.canEdit = await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT);
    this.addControlsOfCheckbox();
    this.registerValueChanges();
  }

  allowSendingDataOnChange(event: MatCheckboxChange) {
    if (!event.checked)
      this.addNewConfigIntegrationFormGroup
        .get('requiresApproval')
        ?.setValue(false);
  }

  async confirmConfigIntegration() {
    try {
      if(this.addNewConfigIntegrationFormGroup.get('seniorToken')?.invalid) {
        this.toastComponent.showWarningCustomMessage('O Token de integração é obrigatório');
        return;
      }

      if (this.addNewConfigIntegrationFormGroup.invalid) {
        this.toastComponent.showWarningCustomMessage('Campos obrigatórios não preenchidos');
        return;
      }

      this.isloading = true;
      const form = this.addNewConfigIntegrationFormGroup.getRawValue();
      const request: ConfigIntegration = {
        clientId: this.clientId,
        ...form,
        seniorToken: form.allowSeniorIntegrator ? form.seniorToken : null
      };

      if (!request.id) {
        await this.configIntegrationService.save(request).then((result) => {
          if (result) {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Configuração criada com sucesso.',
              3000
            );
            this.router.navigate(['/clients']);
          }
        });
      } else {
        await this.configIntegrationService.update(request).then((result) => {
          if (result) {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Configuração editada com sucesso.',
              3000
            );
            this.router.navigate(['/clients']);
          }
        });
      }
    } finally {
      this.isloading = false;
    }
  }

  async getConfiByClient() {
    try {
      this.isloading = true;
      const result = await this.configIntegrationService.getByClient(
        this.clientId
      );
      this.addNewConfigIntegrationFormGroup.patchValue(result);
      this.addNewConfigIntegrationFormGroup
        .get('beginDateIncomeReport')
        ?.setValue(new Date(result.beginDateIncomeReport + 'T00:00'));
      this.addNewConfigIntegrationFormGroup
        .get('endDateIncomeReport')
        ?.setValue(new Date(result.endDateIncomeReport + 'T00:00'));
      if (this.addNewConfigIntegrationFormGroup
        .get('beginDateIncomeReport')?.value && result.id) {
        this.maxDate = this.addDays(new Date(this.addNewConfigIntegrationFormGroup
          .get('beginDateIncomeReport')!.value))
      }

      this.clientToken.setValue(result.token);
      this.addNewConfigIntegrationFormGroup.get('seniorToken')?.setValue(result.token);
    } finally {
      this.isloading = false;
    }

  }
  async gerarToken() {
    try {
      this.isloading = true;
      const result = await this.configIntegrationService.generateToken(
        this.clientId
      );
      this.clientToken.setValue(result.token);
      this.addNewConfigIntegrationFormGroup.get('seniorToken')?.setValue(result.token);
      navigator.clipboard.writeText(result.token);
      this.toastComponent.showSuccessCustomMessage(
        `Token copiado para a área de transferência.`,
        '',
        3000
      );
    } finally {
      this.isloading = false;
    }
  }

  copyTpken() {
    navigator.clipboard.writeText(this.clientToken.value);
    this.toastComponent.showSuccessCustomMessage(
      `Token copiado para a área de transferência.`,
      '',
      3000
    );
  }

  changeBeginDateIncomeReport() {
    this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.setValue(null);
    this.maxDate = this.addDays(this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.value);
  }

  addDays(data: Date): Date {

    if (this.addNewConfigIntegrationFormGroup?.get('beginDateIncomeReport')?.value) {
      const lastDayOfYear = new Date((new Date(this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')!.value)).getFullYear() + '-12-31')
      const differenceMilliseconds = (new Date(new Date(this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')!.value))).getTime() - lastDayOfYear.getTime();
      const differenceDays = differenceMilliseconds / (1000 * 60 * 60 * 24);
      const novaData = new Date(data);
      novaData.setDate(data.getDate() + (Math.abs(differenceDays) + 1));
      return novaData;
    } else {
      return new Date();
    }
  }

  oncheckBoxChange(event: any) {
    event.preventDefault();
    const form = this.addNewConfigIntegrationFormGroup.get('allowDataReception');
    this.confirmModal?.showModal('Atenção!', form!.value ? 'Ao desmarcar esse parâmetro, não será mais possível o recebimento de dados por integração, deseja prosseguir?' : 'Deseja mesmo ativar o recebimento de dados por integração?')
      .subscribe(async (isAccepted) => {
        form?.setValue(!form.value);
        if (isAccepted) {
          if (form!.value) {
            this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.setValidators([Validators.required]);
            this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.updateValueAndValidity();
            this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.setValidators([Validators.required]);
            this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.updateValueAndValidity();
          } else {
            this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.clearValidators();
            this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.updateValueAndValidity();
            this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.clearValidators();
            this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.updateValueAndValidity();
            this.addNewConfigIntegrationFormGroup.get('beginDateIncomeReport')?.setValue(null)
            this.addNewConfigIntegrationFormGroup.get('endDateIncomeReport')?.setValue(null)
            this.addNewConfigIntegrationFormGroup.get('allowSeniorIntegrator')?.setValue(false);
            this.checkboxList.forEach((item) => {
              this.addNewConfigIntegrationFormGroup.get(item.key)?.setValue(false);
            });
          }
        } else {
          form?.setValue(!form.value);
        }
      });
  }

  goBack(): void {
    this.location.back();
  }

  confirmTokenGeneration() {
    this.confirmModal
      ?.showModal(
        'Atenção!',
        'Os acessos externos à plataforma e a rotina de importação precisarão ser atualizados para considerar o novo token. Confirma geração?'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          try {
            this.gerarToken();
          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage(
                'Ops!',
                error.error.message
              );
            }
          }
        }
      });

  }
}
