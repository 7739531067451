import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree
} from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';
import { ToastComponent } from '../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class FeedGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService,
    private toastComponent: ToastComponent
  ) {}

  canActivateChild(): boolean | UrlTree | Promise<boolean | UrlTree> {
    if (this.rolesService.getRole('CLIENT') || this.rolesService.getRole('ADMIN')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_POST);
    }

    return false;
  }

  async canActivate(): Promise<boolean> {
    if (this.rolesService.getRole('CLIENT') || this.rolesService.getRole('ADMIN')) {
      const permissions = await this.permissionsService.hasPermission(ProfilePermissions.READ_POST);
      if(!permissions){
        this.toastComponent.showWarningCustomMessage('Acesso Negado', 'Você não possui permissão para acessar esta funcionalidade.');
      }
      return permissions
    }

    return false;
  }
}
