import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, lastValueFrom, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastComponent } from "../components/toaster/toast/toast.component";
import { Benefit } from "../domains/benefits/benefit";
import { BenefitPage } from "../domains/benefits/benefit-page";
import { BenefitsGeneralData } from "../domains/benefits/benefits-general-data";
import { BenefitServiceInterface, paginatedProps } from "../interfaces/benefits-service.interface";

@Injectable({
  providedIn: 'root'
})
export class BenefitsService implements BenefitServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async get(id: number): Promise<Benefit> {
    try {
      const benefit: Benefit = await lastValueFrom(this.http.get<Benefit>(this.urlBase + `/benefits/${id}`));
      if (benefit) {
        return benefit;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getAll(): Promise<Benefit[]> {
    try {
      const benefits: Array<Benefit> = await lastValueFrom(this.http.get<Array<Benefit>>(this.urlBase + `/benefits`));
      if (benefits) {
        return benefits;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async update(benefit: Benefit, image?: File): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append('benefit', new Blob([JSON.stringify(benefit)], { type: "application/json" }));
      if (image) formData.append('image', image, image.name);
      const benefits = await lastValueFrom(this.http.put(this.urlBase + `/benefits`, formData));
      if (benefits) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/benefits/${id}`, { observe: 'response' }));
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw error;
    }
    return false;
  }

  async save(benefit: Benefit, image?: File): Promise<boolean> {
    const formData = new FormData();
    formData.append('benefit', new Blob([JSON.stringify(benefit)], { type: "application/json" }));
    if (image) formData.append('image', image, image.name);

    return await lastValueFrom(this.http.post(this.urlBase + `/benefits`, formData, { observe: 'response' }).pipe(
      map((res) => res.status >= 200 && res.status < 300),
      catchError((error) => {
        this.toastComponent.showApiError(error);
        console.log(error);
        return of(false);
      })
    ));
  }

  async getGeneralData(): Promise<BenefitsGeneralData> {
    try {
      const BenefitsGeneralData: BenefitsGeneralData = await lastValueFrom(this.http.get<BenefitsGeneralData>(this.urlBase + `/benefits/general-data`));

      if (BenefitsGeneralData) {
        return BenefitsGeneralData;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async toggle(id: number | undefined, isActive: boolean): Promise<boolean> {
    const url = `${this.urlBase}/benefits/toggle-benefit?id=${id}&isActive=${isActive}`;

    return await lastValueFrom(this.http.put<boolean>(url, {}, { observe: 'response' }).pipe(
      catchError((error) => {
        this.toastComponent.showApiError(error);
        return of(false)
      }),
      map((value) => !!value)
    ));
  }

  async getBenefitsByClientId(clientId: number): Promise<Benefit[]> {
    try {

      const benefits: Array<Benefit> = await lastValueFrom(
        this.http.get<Array<Benefit>>(this.urlBase + `/benefits/by/${clientId}`)
      );

      if (benefits) {
        return benefits;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getBenefitPage({ page, pageSize, filter, clientId,
  }: paginatedProps): Promise<BenefitPage> {
    try {
      const benefitPage: any = await lastValueFrom(
        this.http.get<BenefitPage>(this.urlBase + `/benefits/page`, {
          params: {
            page: page,
            pageSize: pageSize,
            filter: filter,
            clientId: clientId ?? '',
          },
        })
      );

      if (benefitPage) {
        return benefitPage;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
