import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject, takeUntil, tap } from 'rxjs';

export interface AutoCompleteScrollEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}

@Directive({
  selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {

  @Input() thresholdPercent = .8;
  @Output() scroll = new EventEmitter<AutoCompleteScrollEvent>();
  _onDestroy = new Subject<void>();

  constructor(public autoComplete: MatAutocomplete) {
    this.configOpenedAutoComplete();
    this.configClosedAutoComplete();
  }

  configOpenedAutoComplete(): void {
    this.autoComplete.opened.pipe(
      tap(() => {
        setTimeout(() => {
          this.removeScrollEventListener();
          this.autoComplete.panel.nativeElement
            .addEventListener('scroll', this.onScroll.bind(this))
        });
      }),
      takeUntil(this._onDestroy)).subscribe();
  }

  configClosedAutoComplete(): void {
    this.autoComplete.closed.pipe(
      tap(() =>  this.removeScrollEventListener()),
      takeUntil(this._onDestroy)).subscribe();
  }

  private removeScrollEventListener() {
    if(this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement) {
      this.autoComplete.panel.nativeElement
      .removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll(event: any) {
    const threshold = this.thresholdPercent * 100 * event.target.scrollHeight / 100;
    const current = event.target.scrollTop + event.target.clientHeight;
    if (current > threshold) {
      this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.removeScrollEventListener();
  }
}
