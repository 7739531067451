import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { EMPTY, filter, from, map, of, pairwise, startWith, zip } from 'rxjs';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { Masks } from 'src/app/shared/constants/masks';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { AddressInfo } from 'src/app/shared/domains/address-info';
import { AttachmentDTO, FileDTO } from 'src/app/shared/domains/attachment';
import { Benefit } from 'src/app/shared/domains/benefits/benefit';
import { CnpjInfo } from 'src/app/shared/domains/cnpj-info';
import { Contact } from 'src/app/shared/domains/contact';
import { PartnerAddress } from 'src/app/shared/domains/partner/address';
import { PartnerGeneralData } from 'src/app/shared/domains/partner/general-data';
import { Partner } from 'src/app/shared/domains/partner/partner';
import { ClientService } from 'src/app/shared/services/client.service';
import { DataBridgeService } from 'src/app/shared/services/data-bridge.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { PartnerService } from 'src/app/shared/services/partner.service';
import { ContactAppsWithLabels } from 'src/app/shared/types/contact-app.type';
import { OriginRegistrationWithLabels } from 'src/app/shared/types/origin-registration.type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { brazilianStates, ISENTA } from 'src/app/shared/utils/global-variables';
import { ValidatorGlobal } from 'src/app/shared/utils/validator-global';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('benefitTableSort') benefitTableSort!: MatSort;
  states: Array<string> = brazilianStates;
  contactDataSource = new MatTableDataSource<any>();
  contactDisplayedColumns: string[] = [
    'name',
    'phone',
    'role',
    'email',
    'actions',
  ];
  generalData: PartnerGeneralData = new PartnerGeneralData();
  isEditionMode = false;
  isEditSpecialty = false;
  showSpecialtyModal = false;
  specialtyDataSource = new MatTableDataSource<any>();
  specialtyDisplayedColumns: string[] = [
    'specialty',
    'subSpecialties',
    'actions',
  ];
  contacts: Contact[] = [];
  lockContactTypeEdition = false;
  showModalContact: boolean = false;
  isEditContact = false;
  contactApps = ContactAppsWithLabels;
  lastContactCodeUsed = 0;
  greaterContactCode = 0;
  paginationCard: number = 10;
  allBenefits: Array<any> = [];
  presentationLength = 0;

  exempt: string[] = [ISENTA];
  searchBenefitFormControl = new FormControl();
  benefitDataSource = new MatTableDataSource<BenefitTableItem>();
  benefits: BenefitTableItem[] = [];
  displayedBenefitColumns: string[] = [
    'benefit',
    'validity',
    'isActive',
  ];
  lastFilterValue: string = '';
  fiterTable: string = '';

  selectedImage: any;
  image!: File | undefined;
  attachments: FileDTO[] = [];
  attachmentsToSave: File[] = [];
  attachmentsOld: AttachmentDTO[] = [];

  readonly masks = Masks;

  formPartner: FormGroup;

  searchSubspecialtyFormControl = new FormControl();
  searchSpecialtesFormControl = new FormControl();
  searchStateFormControl = new FormControl();  
  filteredStates: Array<string> = brazilianStates;
  noPresentOnFilterSubspecialty: any[] = [];

  addNewContactFormGroup: FormGroup = this.formBuilder.group({
    id: new FormControl(undefined),
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      ValidatorGlobal.validateEmail(),
    ]),
    type: new FormControl('', Validators.required),
  });

  subspecialties = new FormControl([]);
  specialtyForm: FormGroup = this.formBuilder.group({
    id: new FormControl(undefined),
    specialty: new FormControl('', Validators.required),
    description: new FormControl({ value: '', disabled: true }),
    index: new FormControl(),
  });

  showCnpjSearchErrorModal: boolean = false;
  count: number = 0;
  countInterval?: any;
  origins = OriginRegistrationWithLabels;

  isClientView = false;
  canEdit = true;
  canDelete = true;

  constructor(
    private formBuilder: FormBuilder,
    private dataBridgeService: DataBridgeService,
    private toastComponent: ToastComponent,
    private partnerService: PartnerService,
    public globalFunctions: GlobalFunctions,
    private route: ActivatedRoute,
    private router: Router,
    private dataImportService: DataImportService,    
    private sanitizer: DomSanitizer,
    private clientService: ClientService,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) {
    const situation = new FormControl(false);

    this.formPartner = this.formBuilder.group({
      id: new FormControl(undefined),
      userId: new FormControl(undefined),
      clientId: new FormControl(undefined),
      clientName: new FormControl(""),
      code: new FormControl({ value: '', disabled: true }),
      origin: new FormControl({ value: '', disabled: true }),
      type: new FormControl('PF'),
      imgUri: new FormControl(''),
      hasBeenImageDeleted: new FormControl(false),
      isSameRegistrationAddress: new FormControl(true),
      presentation: new FormControl('', ValidatorGlobal.stringZeroOrMin(60)),
      specialties: new FormControl([]),
      benefits: new FormControl([]),
      contacts: new FormControl([], ValidatorGlobal.validateContacts),
      attachments: new FormControl([]),
      cnpjData: this.formBuilder.group({
        cnpj: new FormControl('', Validators.required),
        registerState: new FormControl('', Validators.required),
        openingDate: new FormControl(
          { value: undefined, disabled: true },
          Validators.required
        ),
        corporateName: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        fantasyName: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        situation,
      }),
      cpfData: this.formBuilder.group({
        cpf: new FormControl('', [
          Validators.required,
          ValidatorGlobal.validateCpf(),
        ]),
        birthDate: new FormControl('', Validators.required),
        tradingName: new FormControl(''),
        fullName: new FormControl('', Validators.required),
        situation,
      }),
      businessData: this.formBuilder.group({
        site: new FormControl(''),
        phone: new FormControl('', Validators.required),
        email: new FormControl('', [
          Validators.required,
          ValidatorGlobal.validateEmail(),
        ]),
        id: new FormControl(undefined),
        cep: new FormControl('', Validators.required),
        streetAddress: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required),
        complement: new FormControl(''),
        district: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
      }),
      correspondenceAddress: this.formBuilder.group({
        id: new FormControl(undefined),
        cep: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        streetAddress: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        number: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        complement: new FormControl({ value: '', disabled: true }),
        district: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        city: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
        state: new FormControl(
          { value: '', disabled: true },
          Validators.required
        ),
      }),
      socialNetworks: this.formBuilder.group({
        instagram: new FormControl('', ValidatorGlobal.validateInstagram()),
        facebook: new FormControl('', ValidatorGlobal.validateFacebook()),
        linkedin: new FormControl('', ValidatorGlobal.validateLinkedin()),
      }),
    });

    zip([
      route.queryParams.pipe(
        map((params) => Number(params['id'])),
        mergeMap((id) => 
          id ? this.partnerService.getById(id) : of(undefined)
        )
      ),
      route.queryParams.pipe(
        map((params) => Number(params['clientId'])),
        mergeMap((clientId) =>
          clientId ? this.clientService.get(clientId) : of(undefined)
        )
      )
    ]).subscribe(async ([partner, client]) => {
      if(client) {
        this.isClientView = true;

        const clientName = client.tradingName
          ? client.tradingName
          : client.corporateName;

        this.formPartner?.get('clientName')?.setValue(clientName);
        this.formPartner?.get('clientId')?.setValue(client.id);
        this.formPartner?.get('clientName')?.disable();
        this.formPartner?.get('origin')?.setValue('CADASTRO');
      } else {
        this.isClientView = false;
        this.formPartner?.get('origin')?.setValue('ADMIN');
      }

      if (partner) {
        this.setEditMode(partner);
      }
    });

    const cnpjFormControl = this.formPartner.get('cnpjData')?.get('cnpj');
    cnpjFormControl?.valueChanges
      .pipe(
        debounceTime(400),
        filter((cnpj) => !!cnpj),
        map((cnpj) => cnpj.trim()),
        filter((cnpj) => cnpj.length == 14 && !this.isEditionMode),
        mergeMap((cnpj) => this.getCnpjInfo(cnpj)),
        mergeMap((cnpj) => (cnpj.status === 'ERROR' ? EMPTY : of(cnpj)))
      )
      .subscribe((cnpj) => this.fillCnpjData(cnpj));

    const typeControl = this.formPartner.get('type');
    typeControl?.valueChanges
      .pipe(
        startWith(typeControl.value),
        pairwise(),
        filter(([oldValue, newValue]) => oldValue !== newValue),
        map(([_, newValue]) => newValue)
      )
      .subscribe((type) => {
        this.setValidatorsByType(type);

        this.formPartner.get('businessData')?.reset('');
        this.formPartner.get('correspondenceAddress')?.reset('');
      });

    //#region CEP
    const businessAddressFormGroup = this.formPartner.get('businessData');
    const cepFormControl = businessAddressFormGroup?.get('cep');
    const correspondenceAddressFormGroup = this.formPartner.get(
      'correspondenceAddress'
    );
    const cepDeliverFormControl = correspondenceAddressFormGroup?.get('cep');

    cepFormControl?.valueChanges
      .pipe(
        filter((cep) => cep && cep.length === 8),
        debounceTime(400),
        mergeMap((cep) => this.dataBridgeService.getByCEP(cep)),
        catchError(() => {
          cepFormControl?.setErrors({ invalid: true });
          return EMPTY;
        }),
        mergeMap((address) => {
          if (!address) {
            return EMPTY;
          } else if (!address?.city && !address?.uf) {
            this.toastComponent.showWarningCustomMessage('CEP Inválido!', '');
            cepFormControl?.setErrors({ invalid: true });
            return EMPTY;
          }
          return of(address);
        })
      )
      .subscribe((address) => {
        this.setAddressData(
          businessAddressFormGroup as AbstractControl,
          address
        );

        if (this.formPartner.get('isSameRegistrationAddress')?.value) {
          cepDeliverFormControl?.setValue(cepFormControl);
          this.setAddressData(
            correspondenceAddressFormGroup as AbstractControl,
            address
          );
        }
      });

    cepDeliverFormControl?.valueChanges
      .pipe(
        filter(
          (cep) =>
            cep &&
            cep.length === 8 &&
            !this.formPartner.get('isSameRegistrationAddress')?.value
        ),
        debounceTime(400),
        mergeMap((cep) => this.dataBridgeService.getByCEP(cep)),
        catchError(() => {
          cepFormControl?.setErrors({ invalid: true });
          return EMPTY;
        }),
        mergeMap((address) => {
          if (!address) {
            return EMPTY;
          } else if (!address?.city && !address?.uf) {
            this.toastComponent.showWarningCustomMessage('CEP Inválido!', '');
            cepFormControl?.setErrors({ invalid: true });
            return EMPTY;
          }
          return of(address);
        })
      )
      .subscribe((address) =>
        this.setAddressData(
          correspondenceAddressFormGroup as AbstractControl,
          address
        )
      );

    businessAddressFormGroup?.valueChanges.subscribe(() => {
      if (this.formPartner.get('isSameRegistrationAddress')?.value) {
        this.setcorrespondence();
      }
    });
    //#endregion CEP

    this.searchBenefitFormControl.valueChanges
      .pipe(
        map((value: string) => value.trim().toLocaleLowerCase()),
        map((value: string) =>
          value
            .trim()
            .replace(/[.\-()/ ]/g, '')
            .toLocaleLowerCase()
        )
      )
      .subscribe((searchedValue) => this.applyFilter(searchedValue));

    this.searchStateFormControl.valueChanges.subscribe((filter) => {
      this.filteredStates = this.states.filter((state) =>
        state.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }

  async ngOnInit(): Promise<void> {
    this.verifyPermissions();
    
    if (!this.isEditionMode) {
      this.setcorrespondence();
      this.setValidatorsByType('PF');
    }

    this.orderContacts();
  }

  async verifyPermissions(): Promise<void> {
    this.canEdit = await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_PARTNER);
    this.canDelete = await this.permissionsService.hasPermission(ProfilePermissions.DELETE_PARTNER);
  }
  
  //#region Editar
  async setEditMode(partner: Partner) {
    this.isEditionMode = true;

    const cpfData = this.formPartner.get('cpfData');
    const cnpjData = this.formPartner.get('cnpjData');
    const businessData = this.formPartner.get('businessData');
    const correspondenceAddress = this.formPartner.get('correspondenceAddress');
    const socialNetworks = this.formPartner.get('socialNetworks');
    const deliveryAddress = partner.addresses.find(
      (address) => address.typeAddress == 'DELIVERY'
    );
    const correspondence = partner.addresses.find(
      (address) => address.typeAddress == 'CORRESPONDENCE'
    );
    const openingDate = new Date(partner.openingDate);

    this.formPartner.get('id')?.setValue(partner.id);
    this.formPartner.get('userId')?.setValue(partner.userId);
    this.formPartner.get('clientId')?.setValue(partner.clientId);
    this.formPartner.get('code')?.setValue(partner.code);
    this.formPartner.get('origin')?.setValue(partner.origin);
    this.formPartner.get('type')?.setValue(partner.civilType);
    this.formPartner.get('type')?.disable();
    this.formPartner.get('isPcvClient')?.setValue(partner.isPcvClient);
    this.formPartner.get('isPcvClient')?.disable();
    this.formPartner
      .get('isSameRegistrationAddress')
      ?.setValue(partner.isSameRegistrationAddress);
    this.formPartner.get('presentation')?.setValue(partner.presentation);
    this.formPartner.get('benefits')?.setValue(partner.benefits);
    this.formPartner.get('imgUri')?.setValue(partner.imgUri);

    cpfData?.get('cpf')?.setValue(partner.cpf);
    cpfData?.get('cpf')?.disable();
    cpfData?.get('birthDate')?.setValue(new Date(partner.birthDate + 'T00:00'));
    cpfData?.get('tradingName')?.setValue(partner.tradingName);
    cpfData?.get('fullName')?.setValue(partner.fullName);

    cnpjData?.get('cnpj')?.setValue(partner.cnpj, { emitEvent: false });
    cnpjData?.get('cnpj')?.disable();
    cnpjData?.get('corporateName')?.setValue(partner.corporateName);
    cnpjData?.get('corporateName')?.disable();
    cnpjData?.get('fantasyName')?.setValue(partner.fantasyName);
    cnpjData?.get('fantasyName')?.disable();

    if (!isNaN(openingDate.getTime())) {
      const newDate = new Date(openingDate);
      newDate.setDate(openingDate.getDate() + 1);

      cnpjData?.get('openingDate')?.setValue(newDate);
    }
    cnpjData?.get('openingDate')?.disable();
    cnpjData?.get('registerState')?.setValue(partner.registerState);
    cnpjData?.get('registerState')?.disable();

    socialNetworks?.get('instagram')?.setValue(partner.instagram);
    socialNetworks?.get('facebook')?.setValue(partner.facebook);
    socialNetworks?.get('linkedin')?.setValue(partner.linkedin);

    businessData?.get('site')?.setValue(partner.site);
    businessData?.get('phone')?.setValue(partner.phone);
    businessData?.get('email')?.setValue(partner.email);

    businessData?.get('id')?.setValue(deliveryAddress?.id);
    businessData
      ?.get('cep')
      ?.setValue(deliveryAddress?.cep, { emitEvent: false });
    businessData
      ?.get('streetAddress')
      ?.setValue(deliveryAddress?.streetAddress);
    businessData?.get('number')?.setValue(deliveryAddress?.number);
    businessData?.get('complement')?.setValue(deliveryAddress?.complement);
    businessData?.get('district')?.setValue(deliveryAddress?.district);
    businessData?.get('city')?.setValue(deliveryAddress?.city);
    businessData?.get('state')?.setValue(deliveryAddress?.uf);

    if (!this.formPartner.get('isSameRegistrationAddress')?.value) {
      correspondenceAddress?.get('id')?.setValue(correspondence?.id);
      correspondenceAddress
        ?.get('cep')
        ?.setValue(correspondence?.cep, { emitEvent: false });
      correspondenceAddress
        ?.get('streetAddress')
        ?.setValue(correspondence?.streetAddress);
      correspondenceAddress?.get('number')?.setValue(correspondence?.number);
      correspondenceAddress
        ?.get('complement')
        ?.setValue(correspondence?.complement);
      correspondenceAddress
        ?.get('district')
        ?.setValue(correspondence?.district);
      correspondenceAddress?.get('city')?.setValue(correspondence?.city);
      correspondenceAddress?.get('state')?.setValue(correspondence?.uf);
    }

    if (this.formPartner.get('type')?.value == 'PJ') {
      cnpjData
        ?.get('situation')
        ?.setValue(partner.isActive, { emitEvent: false });
    } else {
      cpfData
        ?.get('situation')
        ?.setValue(partner.isActive, { emitEvent: false });
    }

    this.formPartner.get('contacts')?.setValue(partner.contacts);
    this.contactDataSource = new MatTableDataSource(
      this.formPartner.get('contacts')?.value
    );
    this.setContacts(partner);

    if (partner.benefits) {
      this.benefits = this.mapDate(partner.benefits);
      this.benefitDataSource = new MatTableDataSource(this.benefits);
      this.configTableBenefit();
    }

    await this.getImage(partner.imgUri);

    this.attachments = partner.attachments as FileDTO[];
    this.attachmentsOld = [...(this.attachments as AttachmentDTO[])];

    this.setValidatorsByType(this.formPartner.get('type')?.value);

    if(!this.canEdit) this.formPartner.disable();
  }

  setContacts(partner: Partner) {
    partner.contacts.forEach((contact) => {
      this.contacts.push({
        id: contact.id,
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
        role: contact.role,
        type: contact.type,
      });
    });
  }

  //#endregion Editar

  //#region checkboxes
  changeIsSameRegistrationAddress(value: boolean) {
    this.formPartner.get('isSameRegistrationAddress')?.setValue(value);
    this.setcorrespondence();
  }
  //#endregion checkboxes

  setValidatorsByType(type: string) {
    const cnpjFormGroup = this.formPartner.get('cnpjData') as FormGroup;
    const cpfFormGroup = this.formPartner.get('cpfData') as FormGroup;
    const businessDataFormGroup = this.formPartner.get('businessData');
    const situation = cnpjFormGroup.get('situation')?.value;

    if (type == 'PF') {
      cpfFormGroup
        ?.get('cpf')
        ?.addValidators([Validators.required, ValidatorGlobal.validateCpf()]);
      cpfFormGroup?.get('birthDate')?.addValidators(Validators.required);
      cpfFormGroup?.get('fullName')?.addValidators(Validators.required);

      Object.keys((cnpjFormGroup as FormGroup)?.controls).forEach((e) =>
        cnpjFormGroup.controls[e].clearValidators()
      );
      cnpjFormGroup.updateValueAndValidity();
      cnpjFormGroup?.reset({ situation }, { emitEvent: false });

      businessDataFormGroup?.enable({ emitEvent: false });
      cpfFormGroup?.updateValueAndValidity();
    } else {
      cnpjFormGroup?.get('cnpj')?.setValidators(Validators.required);
      cnpjFormGroup?.get('openingDate')?.setValidators(Validators.required);
      cnpjFormGroup?.get('corporateName')?.setValidators(Validators.required);
      cnpjFormGroup?.get('fantasyName')?.setValidators(Validators.required);
      cnpjFormGroup
        ?.get('registerState')
        ?.setValidators([Validators.required, Validators.maxLength(17)]);

      Object.keys((cpfFormGroup as FormGroup)?.controls).forEach((e) =>
        cpfFormGroup.controls[e].clearValidators()
      );
      cpfFormGroup?.updateValueAndValidity();
      cpfFormGroup?.reset({ situation }, { emitEvent: false });
      cnpjFormGroup?.updateValueAndValidity();
    }
  }

  redirectTo(path: string) {
    this.router.navigate([path]);
  }

  //#region Endereço
  setAddressData(addressFormGroup: AbstractControl, address: AddressInfo) {
    addressFormGroup?.get('city')?.patchValue(address.city);
    addressFormGroup?.get('complement')?.patchValue(address.complement);
    addressFormGroup?.get('district')?.patchValue(address.district);
    addressFormGroup?.get('number')?.patchValue(address.number);
    addressFormGroup?.get('state')?.patchValue(address.uf);
    addressFormGroup?.get('streetAddress')?.patchValue(address.streetAddress);
  }

  setcorrespondence() {
    const mailingAddressFormGroup = this.formPartner.get(
      'correspondenceAddress'
    );
    const businessDataFormGroup = this.formPartner.get('businessData');

    const cepFormControl = mailingAddressFormGroup?.get('cep');
    const addressFormControl = mailingAddressFormGroup?.get('streetAddress');
    const numberFormControl = mailingAddressFormGroup?.get('number');
    const complementFormControl = mailingAddressFormGroup?.get('complement');
    const districtFormControl = mailingAddressFormGroup?.get('district');
    const cityFormControl = mailingAddressFormGroup?.get('city');
    const stateFormControl = mailingAddressFormGroup?.get('state');

    if (this.formPartner.get('isSameRegistrationAddress')?.value) {
      cepFormControl?.setValue(businessDataFormGroup?.get('cep')?.value);
      cepFormControl?.disable();

      addressFormControl?.setValue(
        businessDataFormGroup?.get('streetAddress')?.value
      );
      addressFormControl?.disable();

      numberFormControl?.setValue(businessDataFormGroup?.get('number')?.value);
      numberFormControl?.disable();

      complementFormControl?.setValue(
        businessDataFormGroup?.get('complement')?.value
      );
      complementFormControl?.disable();

      districtFormControl?.setValue(
        businessDataFormGroup?.get('district')?.value
      );
      districtFormControl?.disable();

      cityFormControl?.setValue(businessDataFormGroup?.get('city')?.value);
      cityFormControl?.disable();

      stateFormControl?.setValue(businessDataFormGroup?.get('state')?.value);
      stateFormControl?.disable();
    } else {
      cepFormControl?.setValue('');
      cepFormControl?.enable();

      addressFormControl?.setValue('');
      addressFormControl?.enable();

      numberFormControl?.setValue('');
      numberFormControl?.enable();

      complementFormControl?.setValue('');
      complementFormControl?.enable();

      districtFormControl?.setValue('');
      districtFormControl?.enable();

      cityFormControl?.setValue('');
      cityFormControl?.enable();

      stateFormControl?.setValue('');
      stateFormControl?.enable();
    }
  }
  //#endregion Endereço

  //#region Especialidades

  resetForm() {
    this.specialtyForm.reset({
      id: undefined,
      specialty: '',
      description: '',
      index: undefined,
    });

    this.subspecialties.reset([]);
  }
  //#endregion Especialidades

  //#region Contatos
  initContacts() {
    this.formPartner.get('contacts')?.value.forEach((contact: any) => {
      if (contact.id > this.greaterContactCode) {
        this.greaterContactCode = contact.id;
        this.lastContactCodeUsed = contact.id;
      }
    });

    this.contactDataSource = new MatTableDataSource(
      this.formPartner.get('contacts')?.value
    );
    this.orderContacts();
  }

  showContactModal(contact?: Contact) {
    this.lockContactTypeEdition = false;
    const typeFormControl = this.addNewContactFormGroup.get('type');

    if (!contact) {
      this.isEditContact = false;
      this.addNewContactFormGroup.reset();
      typeFormControl?.setValue('DEFAULT');
      if (this.formPartner.get('contacts')?.value.length == 0) {
        this.lockContactTypeEdition = true;
        typeFormControl?.setValue('PRIMARY');
      }
      this.addNewContactFormGroup
        .get('id')
        ?.setValue(this.lastContactCodeUsed + 1);
      this.lastContactCodeUsed++;
    } else {
      this.isEditContact = true;
      if (contact.type == 'PRIMARY') {
        this.lockContactTypeEdition = true;
      }

      const nameFormControl = this.addNewContactFormGroup.get('name');
      const phoneFormControl = this.addNewContactFormGroup.get('phone');
      const contactAppFormControl =
        this.addNewContactFormGroup.get('contactApp');
      const roleFormControl = this.addNewContactFormGroup.get('role');
      const idFormControl = this.addNewContactFormGroup.get('id');
      const emailFormControl = this.addNewContactFormGroup.get('email');

      nameFormControl?.setValue(contact.name);
      phoneFormControl?.setValue(contact.phone);
      roleFormControl?.setValue(contact.role);
      emailFormControl?.setValue(contact.email);
      typeFormControl?.setValue(contact.type);
      idFormControl?.setValue(contact.id);
    }

    this.showModalContact = true;
  }

  closeContactModal() {
    this.resetForm();
    this.showModalContact = false;
  }

  confirmContactModal() {
    if (this.isEditContact) {
      this.editContact();
    } else {
      this.createContact();
    }

    this.setPrimaryOrSecondaryContact();
    this.orderContacts();
    this.closeContactModal();
    this.verifyIfHaveContacts();
  }

  editContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formPartner.get('contacts')?.value;
    let contact = contacts.find(
      (contact: { id: any }) => contact.id == addNewContactForm.id
    );

    contact.name = addNewContactForm.name;
    contact.phone = addNewContactForm.phone;
    contact.email = addNewContactForm.email;
    contact.role = addNewContactForm.role;
    contact.type = addNewContactForm.type;
  }

  createContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formPartner.get('contacts')?.value;

    let contact = new Contact(
      addNewContactForm.name,
      addNewContactForm.phone,
      undefined,
      addNewContactForm.role,
      addNewContactForm.email,
      addNewContactForm.type
    );
    contact.id = addNewContactForm.id;
    contacts.push(contact);

    this.formPartner.get('contacts')?.patchValue(contacts);
  }

  deleteContactModal(object: Contact) {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este contato?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.deleteContact(object);
          this.contactDataSource = new MatTableDataSource(
            this.formPartner.get('contacts')?.value
          );
        }
      });
    this.closeContactModal();
  }

  deleteContact(client: Contact) {
    const contacts: Contact[] = this.formPartner.get('contacts')?.value;

    if (client.type !== 'PRIMARY') {
      contacts.forEach((c: Contact, i: number) => {
        if (c.id === client.id) {
          this.formPartner.get('contacts')?.value.splice(i, 1);
        }
      });
    } else {
      this.toastComponent.showErrorCustomMessage(
        'O Contato Principal não pode ser excluído.',
        'Gentileza selecionar um novo Contato Principal para liberar a exclusão deste contato.'
      );
    }
  }

  setPrimaryOrSecondaryContact() {
    const addNewContactForm = this.addNewContactFormGroup?.value;
    const contacts = this.formPartner.get('contacts')?.value;

    if (
      addNewContactForm &&
      addNewContactForm.type == 'PRIMARY' &&
      contacts.length > 0 &&
      addNewContactForm.id != contacts[0].id
    ) {
      contacts[0].type = 'DEFAULT';
    } else if (
      addNewContactForm.type == 'SECONDARY' &&
      contacts.length > 1 &&
      addNewContactForm.id != contacts[1].id
    ) {
      contacts[1].type = 'DEFAULT';
    }
  }

  orderContacts() {
    const primaryContact: Contact[] = [];
    const secondaryContact: Contact[] = [];
    const defaultContacts: Contact[] = [];

    this.formPartner.get('contacts')?.value.forEach((contact: Contact) => {
      if (contact.type === 'PRIMARY') {
        primaryContact.push(contact);
      } else if (contact.type === 'SECONDARY') {
        secondaryContact.push(contact);
      } else {
        defaultContacts.push(contact);
      }
    });

    defaultContacts.sort((a, b) => {
      if (!a.id) return 1;
      if (!b.id) return -1;
      return a.id - b.id;
    });

    const sortedContacts = [
      ...primaryContact,
      ...secondaryContact,
      ...defaultContacts,
    ];
    this.formPartner.get('contacts')?.patchValue(sortedContacts);
    this.contactDataSource = new MatTableDataSource(
      this.formPartner.get('contacts')?.value
    );
  }
  //#endregion Contatos

  //#region CNPJ
  retryGetCnpjInfo() {
    if (this.count <= 0)
      this.formPartner.get('cnpjData')?.get('cnpj')?.updateValueAndValidity();
  }

  private fillCnpjData(cnpjData: CnpjInfo) {
    this.showCnpjSearchErrorModal = false;

    if (cnpjData) {
      const businessDataFormGroup = this.formPartner.get('businessData');
      const cnpjDataFormGroup = this.formPartner.get('cnpjData');

      cnpjDataFormGroup
        ?.get('corporateName')
        ?.patchValue(cnpjData.corporateName);
      cnpjDataFormGroup?.get('fantasyName')?.patchValue(cnpjData.fantasyName);
      cnpjDataFormGroup?.get('openingDate')?.patchValue(cnpjData.openingDate);
      businessDataFormGroup?.get('email')?.patchValue(cnpjData.email);
      businessDataFormGroup?.get('phone')?.patchValue(cnpjData.phone);
      businessDataFormGroup?.get('site')?.patchValue(cnpjData?.site);
      businessDataFormGroup
        ?.get('cep')
        ?.patchValue(cnpjData.address.cep, { emitEvent: false });
      this.setAddressData(
        this.formPartner.get('businessData') as AbstractControl,
        cnpjData.address
      );
      this.count = -1;

      if (!cnpjData.email) {
        businessDataFormGroup?.get('email')?.enable();
      }
    }
  }

  private openModal() {
    this.showCnpjSearchErrorModal = true;
    this.inicializeTimer();
  }

  private inicializeTimer() {
    this.count = 60;
    this.countInterval = setInterval(() => {
      this.count--;
      if (this.count <= 0) {
        this.showCnpjSearchErrorModal = false;
        clearInterval(this.countInterval);
      }
    }, 1000);
  }

  private getCnpjInfo(cnpj: string) {
    if (this.count > 0) {
      this.showCnpjSearchErrorModal = true;
      return EMPTY;
    }

    return from(this.dataBridgeService.getByCNPJ(cnpj)).pipe(
      catchError((error) => {

        if (error.status === 429) {
          this.openModal();
        }
        this.toastComponent.showApiError(error);

        return EMPTY;
      })
    );
  }
  //#endregion CNPJ

  getBenefit(benefit: Benefit) {
    return this.allBenefits.find((p) => p.id === benefit.id);
  }

  confirmSituationChange(event: any, benefit: Benefit) {
    event.preventDefault();
    this.confirmModal
      ?.showModal(
        `Deseja mesmo ${benefit?.isActive ? 'inativar' : 'ativar'
        } este benefício?`
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          benefit.isActive = !benefit.isActive;
          this.formPartner.markAsDirty();
          this.formPartner.get('benefits')?.value.forEach((b: Benefit) => {
            if (b.id === benefit.id) {
              b.isActive = benefit.isActive;
            }
          });
        }
      });
  }

  confirmDelete() {
    const text =
      this.formPartner.get('benefits')?.value.length > 0
        ? 'Esse Parceiro possui benefícios ativos associados. Tem certeza que deseja excluir mesmo assim?'
        : 'Deseja mesmo excluir este parceiro?';

    this.confirmModal
      ?.showModal(text, 'Caso confirme, essa ação não poderá ser desfeita.')
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          const success = await this.partnerService.delete(
            this.formPartner.get('id')?.value
          );
          if (success) {
            this.toastComponent.showSuccessCustomMessage(
              'Exclusão realizada com sucesso',
              '',
              3000
            );
            this.redirectTo('/partners');
          }
        }
      });
  }

  //#region Cancelar
  confirmCancel() {
    if (
      this.formPartner.pristine &&
      !this.contactsHasChanged()
    ) {
      this.redirectTo('/partners');
    } else {
      this.confirmModal
        ?.showCancelModal('Deseja mesmo cancelar?', '', true)
        .subscribe((isAccepted) => {
          if (isAccepted) {
            this.redirectTo('/partners');
          }
        });
    }
  }

  contactsHasChanged() {
    if (
      this.contacts.length !== this.formPartner.get('contacts')?.value.length
    ) {
      return true;
    }

    this.orderContactList();
    const sortedList1 = this.contacts;
    const sortedList2 = this.formPartner.get('contacts')?.value;

    for (let i = 0; i < sortedList1.length; i++) {
      const obj1 = sortedList1[i];
      const obj2 = sortedList2[i];

      if (!this.contactsAreEquals(obj1, obj2)) return true;
    }

    return false;
  }

  orderContactList() {
    const primaryContact: Contact[] = [];
    const secondaryContact: Contact[] = [];
    const defaultContacts: Contact[] = [];

    this.contacts.forEach((contact: Contact) => {
      if (contact.type === 'PRIMARY') {
        primaryContact.push(contact);
      } else if (contact.type === 'SECONDARY') {
        secondaryContact.push(contact);
      } else {
        defaultContacts.push(contact);
      }
    });

    defaultContacts.sort((a, b) => {
      if (!a.id) return 1;
      if (!b.id) return -1;
      return a.id - b.id;
    });

    const sortedContacts = [
      ...primaryContact,
      ...secondaryContact,
      ...defaultContacts,
    ];
    this.contacts = sortedContacts;
  }

  contactsAreEquals(item1: Contact, item2: Contact) {
    return (
      item1.id == item2.id &&
      item1.name == item2.name &&
      item1.email == item2.email &&
      item1.phone == item2.phone &&
      item1.role == item2.role &&
      item1.type == item2.type
    );
  }
  //#endregion Cancelar

  validatorPcvRequired: boolean = false;
  validatorContactsRequired: boolean = false;

  verifyIfHaveContacts() {
    this.formPartner.get('contacts')?.value.length === 0
      ? (this.validatorContactsRequired = true)
      : (this.validatorContactsRequired = false);
  }

  markFormFieldsAsTouched() {
    Object.values(this.formPartner.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((innerControl) => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
    this.verifyIfHaveContacts();
  }

  //#region Salvar ou atualizar
  async saveOrUpdate() {
    this.markFormFieldsAsTouched();
    if (this.formPartner.valid) {
      const partner = await this.mapFormToPartnerDTO();

      if (partner.id) {
        if (
          await this.partnerService.edit(
            partner,
            this.image,
            this.attachmentsToSave
          )
        ) {
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Parceiro editado com sucesso.',
            3000
          );

          this.redirectTo('/partners');
        }
      } else {
        if (
          await this.partnerService.save(
            partner,
            this.image,
            this.attachmentsToSave
          )
        ) {
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Parceiro criado com sucesso.',
            3000
          );
          this.redirectTo('/partners');
        }
      }
    } else {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );
    }
  }

  mapFormToPartnerDTO(): Partner {
    const rawValue = this.formPartner.getRawValue();

    const cnpjData = rawValue.cnpjData;
    const cpfData = rawValue.cpfData;
    const businessData = rawValue.businessData;
    const correspondenceAddress = rawValue.correspondenceAddress;
    const socialNetworks = rawValue.socialNetworks;

    const isActive =
      rawValue.type == 'PF' ? cpfData.situation : cnpjData.situation;

    const deliveryAddressInfo: PartnerAddress = {
      id: businessData.id,
      cep: this.globalFunctions.removeMaskCep(businessData.cep),
      streetAddress: businessData.streetAddress,
      number: businessData.number,
      complement: businessData.complement,
      district: businessData.district,
      city: businessData.city,
      uf: businessData.state,
      typeAddress: 'DELIVERY',
    };

    const addresses: PartnerAddress[] = [deliveryAddressInfo];

    if (!rawValue.isSameRegistrationAddress) {
      const correspondenceAddressInfo: PartnerAddress = {
        id: correspondenceAddress.id,
        cep: this.globalFunctions.removeMaskCep(correspondenceAddress.cep),
        streetAddress: correspondenceAddress.streetAddress,
        number: correspondenceAddress.number,
        complement: correspondenceAddress.complement,
        district: correspondenceAddress.district,
        city: correspondenceAddress.city,
        uf: correspondenceAddress.state,
        typeAddress: 'CORRESPONDENCE',
      };

      addresses.push(correspondenceAddressInfo);
    }

    rawValue.contacts.forEach((contact: Contact) => {
      if (contact.id && contact.id > this.greaterContactCode) {
        contact.id = undefined;
      }
    });

    this.attachmentsToSave = [];

    this.attachments.forEach((a) => {
      if (!a.id && a.file) {
        this.attachmentsToSave.push(a.file);
      }
    });

    const partner: Partner = {
      id: rawValue.id,
      isActive: isActive,
      userId: rawValue.userId,
      clientId: rawValue.clientId,
      code: rawValue.code,
      origin: rawValue.origin.toUpperCase(),
      civilType: rawValue.type,
      isPcvBusiness: rawValue.isPcvBusiness,
      isPcvProduct: rawValue.isPcvProduct,
      isPcvClient: rawValue.isPcvClient,
      cnpj: cnpjData.cnpj,
      registerState: cnpjData.registerState,
      openingDate: cnpjData.openingDate,
      corporateName: cnpjData.corporateName,
      fantasyName: cnpjData.fantasyName,
      cpf: cpfData.cpf,
      birthDate: cpfData.birthDate,
      fullName: cpfData.fullName,
      tradingName: cpfData.tradingName,
      site: this.removeInvisibleChars(businessData.site),
      phone: businessData.phone,
      email: businessData.email,
      dateActivation: cpfData.birthDate,
      contacts: rawValue.contacts,      
      benefits: rawValue.benefits,
      addresses: addresses,
      attachments: this.attachmentsOld,
      isSameRegistrationAddress: rawValue.isSameRegistrationAddress,
      imgUri: rawValue.imgUri,
      hasBeenImageDeleted: rawValue.hasBeenImageDeleted,
      presentation: rawValue.presentation,
      instagram: this.removeInvisibleChars(socialNetworks.instagram),
      facebook: this.removeInvisibleChars(socialNetworks.facebook),
      linkedin: this.removeInvisibleChars(socialNetworks.linkedin),
    };
    return partner;
  }
  //#endregion Salvar ou atualizar

  //#region Anexos
  onFileAdded(event: any): void {
    const newFiles = event.target.files;

    if (!newFiles || newFiles.length === 0) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }

    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];

    const maxSize = 15 * 1024 * 1024;

    for (const file of newFiles) {
      if (!validTypes.includes(file.type)) {
        this.toastComponent.showWarningCustomMessage(
          'Formato Inválido!',
          'Por favor, selecione um arquivo PDF, DOC, DOCx, XLSx ou CSV.'
        );
        return;
      }

      if (file.size > maxSize) {
        this.toastComponent.showWarningCustomMessage(
          'Arquivo Grande!',
          'Por favor, selecione um arquivo menor que 15MB.'
        );
        return;
      }

      const fileUri = URL.createObjectURL(file);

      this.attachments.push({
        name: file.name,
        hasBeenDeleted: false,
        uri: fileUri,
        file: file,
      });
    }

    event.target.value = null;
  }

  removeFile(index: number): void {
    const attachment = this.attachments[index];
    this.attachmentsOld.forEach((a) => {
      if (a.id === attachment.id) a.hasBeenDeleted = true;
    });

    this.attachments.splice(index, 1);
  }

  onImgAdded(event: any): void {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file: File): void {
    if (!file) {
      this.toastComponent.showWarningCustomMessage(
        'Erro!',
        'Nenhum arquivo fornecido.'
      );
      return;
    }
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 15 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo PNG ou JPG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido',
        'Por favor, selecione um arquivo com tamanho máximo de 15mb.'
      );
      return;
    }

    this.removeImg(file);
    this.image = file;
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
    };
    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };
    reader.readAsDataURL(file);
  }

  removeImg(fileInput: any) {
    if (this.isEditionMode && this.formPartner.get('imgUri')?.value) {
      this.formPartner.get('hasBeenImageDeleted')?.setValue(true);
    }

    this.selectedImage = null;
    this.image = undefined;
    fileInput.value = '';
  }

  updatePresentationLength(value: string) {
    this.presentationLength = value.length;
  }

  async getImage(imgUrl: string) {
    this.formPartner.get('imgUri')?.setValue(imgUrl);

    if (imgUrl) {
      const blob = await this.dataImportService.getBlob(imgUrl);
      const file = new File([blob], 'img', { type: blob.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  removeInvisibleChars(value: string): string {
    return this.globalFunctions.removeInvisibleChars(value);
  }

  async downloadImportedFile(file: FileDTO) {
    if (file.uri?.length > 0) {
      if (file.uri.charAt(0) == '/') {
        file.uri = file.uri.substring(1);
      }
    }
    let a = document.createElement('a');
    let data: Blob;
    if (file.id) {
      data = await this.dataImportService.getBlob(file.uri);
      a.href = URL.createObjectURL(data);
      a.download = `${file.name}`;
      a.click();
    } else {
      if (file.file) {
        data = new Blob([file.file], { type: file.file.type });
        a.href = URL.createObjectURL(data);
        a.download = `${file.name}`;
        a.click();
      }
    }
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  getFileName() {
    const isPf =
      this.formPartner.get('cpfData')?.get('tradingName')?.value !== null;
    return `IMG_${isPf
      ? this.formPartner.get('cpfData')?.get('tradingName')?.value
      : this.formPartner.get('cnpjData')?.get('fantasyName')?.value
      }`.replace(' ', '_');
  }

  //#endregion Anexos

  //#region Benefit
  applyFilter(filter: any) {
    if (!filter) {
      this.benefitDataSource.data = this.benefits;
    } else {
      this.benefitDataSource.data = this.benefits.filter(
        (element: BenefitTableItem) =>
          element.benefit.toLowerCase().includes(filter) ||
          element.startValidityFormatted
            ?.trim()
            .replace(/[.\-()/ ]/g, '')
            ?.toLocaleLowerCase()
            .includes(filter) ||
          element.endValidityFormatted
            ?.trim()
            .replace(/[.\-()/ ]/g, '')
            ?.toLocaleLowerCase()
            .includes(filter) ||
          (!element.endValidity &&
            '00/00/0000'
              .trim()
              .replace(/[.\-()/ ]/g, '')
              ?.toLocaleLowerCase()
              .includes(filter)) ||
          (element.isActive && filter.toLowerCase().startsWith('ati')) ||
          (!element.isActive && filter.toLowerCase().startsWith('ina'))
      );
    }
  }

  private configTableBenefit() {
    if (this.paginator) this.benefitDataSource.paginator = this.paginator;
    if (this.benefitTableSort) {
      this.benefitDataSource.sort = this.benefitTableSort;
      this.benefitDataSource.sortingDataAccessor = (item: any, property) => {
        if (property == 'isActive') {
          return item[property];
        }
        if (property == 'validity') {
          return item['startValidity'];
        }
        return item[property]?.toLowerCase();
      };
    }
    this.benefitDataSource.filter = this.fiterTable;
  }

  private mapDate(data: Benefit[]): BenefitTableItem[] {
    return data.map((benefit) => {
      const startValidityFormatted = new Date(`${benefit.startValidity}T00:00:00`);
      const startformattedDate = this.globalFunctions.dateFormat(
        startValidityFormatted
      );
      const endValidityFormatted = new Date(`${benefit.endValidity}T00:00:00`);
      const endformattedDate =
        this.globalFunctions.dateFormat(endValidityFormatted);
      return {
        ...benefit,
        startValidityFormatted: startformattedDate,
        endValidityFormatted: endformattedDate,
      } as any;
    });
  }
  //#endregion Benefit
}

interface BenefitTableItem extends Benefit {
  startValidityFormatted?: string;
  endValidityFormatted?: string;
}
