import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, lastValueFrom, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { NetworkInsert } from "../../domains/network/network-insert";
import { NetworkServiceInterface } from '../../interfaces/network-service.interface';
import { NetworkDetailsDTO } from "../../domains/network/network-details";

@Injectable({
  providedIn: 'root'
})
export class NetworkServiceMock implements NetworkServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient
  ) { this.urlBase = environment.bff_web }

  async getById(id: number): Promise<NetworkInsert> {
    try {
      const network = await lastValueFrom(
        this.http.get<NetworkInsert>(this.urlBase + `/networks'/${id}`)
      );
      return network;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getComments(id: number): Promise<NetworkDetailsDTO> {
    try {
      const comments = await lastValueFrom(
        this.http.get<NetworkDetailsDTO>(this.urlBase + `/comments/${id}`)
      );
      return comments;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async save(network: FormData, imgs?: File): Promise<boolean> {
    return await lastValueFrom(this.http.post<boolean>(this.urlBase, network.get('network')).pipe(
      map(() => true),
      catchError(e => {

        return of(false);
      })
    ));
  }

  async saveComment(comment: any): Promise<boolean> {
    return await lastValueFrom(this.http.post<boolean>(this.urlBase, comment, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        return of(false);
      })
    ));
  }

  async update(network: FormData, imgs?: File): Promise<boolean> {
    return await lastValueFrom(this.http.post<boolean>(this.urlBase, network.get('network')).pipe(
      map(() => true),
      catchError(e => {

        return of(false);
      })
    ));
  }

  async delete(id: number): Promise<boolean> {
    return await lastValueFrom(this.http.delete<boolean>(this.urlBase + '/' + id).pipe(
      map(() => true),
      catchError(e => {
        return of(false);
      })
    ));
  }
}
