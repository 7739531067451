export type EmailType = 'PERSONAL' | 'CORPORATE';

const Types = {
  PERSONAL: {
    label: 'E-mail Pessoal',
    value: 'PERSONAL'
  },
  CORPORATE: {
    label: 'E-mail Corporativo',
    value: 'CORPORATE'
  }
}

export const EmailTypesWithLabels = Object.values(Types);
