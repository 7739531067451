import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';
import { Observable } from 'rxjs';
import { RegisterNoticeComponent } from 'src/app/pages/notices/register-notice/register-notice.component';
import { NoticesComponent } from 'src/app/pages/notices/main/notices.component';

@Injectable({
  providedIn: 'root',
})
export class ClientNoticeGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    private adminGuard: AdminGuard
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.adminGuard.canActivate()) {
      return true;
    }

    if(childRoute.component == NoticesComponent) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_NOTICES);
    }

    if(childRoute.component == RegisterNoticeComponent && (state.url.includes('edit-notice'))) {
      return this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT_NOTICES);
    }

    if(childRoute.component == RegisterNoticeComponent && (state.url.includes('view-notice'))) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_NOTICES);
    }

    if(childRoute.component == RegisterNoticeComponent && state.url.includes('new-notice')) {
      return this.permissionsService.hasPermission(ProfilePermissions.CREATE_CLIENT_NOTICES);
    }

    return false;
  }

  canActivate(): boolean | Promise<boolean> {
    return this.rolesService.getRole('CLIENT')
      && this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_NOTICES);
  }
}
