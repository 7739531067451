<app-search-table #searchTable
[tableTitle]="isUserClient ? 'Perfis de Usuários' : 'Perfis de Usuários Cliente'"
addButtonLabel="Novo Perfil"
(onFilter)="applyFilter($event)"
[tableSourceData]="dataSource"
[columnsConfigurations]="columns"
[deleteDialogInfo]="dialogInfo"
[disableDeleteButton]="!canDelete"
(onAddButtonClick)="redirectToNewUserProfile()"
(onEditButtonClick)="editUserProfile($event)"
(onViewButtonClick)="redirectToViewUserProfile($event)"
[disableAddButton]="!canCreate"
[disableToggleButton]="!canChangeSituation"
[selectConfiguration]="selectClient"
(onSelect)="getProfilesByClient($event)"
(onPageCount)="onPageChange($event)"
[pageLength]="pageLength"
[pageIndex]="pageIndex"
[pageSize]="pageSize"
[changeSituationDialogInfo]="changeSituationDialogInfo"
>
</app-search-table>

<div class="container-fluid d-sm-none">
  <div style="margin: 0px 10px 10px 10px">
    <div class="row">
      <div class="col-12">
        <ng-container>
          <div class="mobile-session" *ngFor="let profile of dataSource.data; let i = index;">
            <div class="card-row-mobile" *ngIf="paginationCard > i" 
              (click)="canUpdate ? editUserProfile(profile) : redirectToViewUserProfile(profile)">
              <div class="card-mobile-content">
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 semibold truncate-words">{{ profile.name }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row pagination-card" *ngIf="dataSource.data.length > paginationCard">
            <label class="paragraph paragraph-2 semibold primary-color"
              (click)="paginationCard = paginationCard + 10;">Mostrar mais</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>