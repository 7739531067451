import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy-main',
  templateUrl: './privacy-policy-main.component.html',
  styleUrls: ['./privacy-policy-main.component.scss'],
})
export class PrivacyPolicyMainComponent {
  constructor(private title: Title) {
    this.title.setTitle("Política de Privacidade")
  }
}
