import { KeyValue } from "@angular/common";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class GamificationService {
    private _onChangeAllowGamification(field: string, form: FormGroup) {
        let valueField = this.getValueFieldName(field);
        if ((form?.get(this.getAllowFieldName(field))?.value && !form?.get('isGeral')?.value) ?? false) {
            form?.get(valueField)?.setValidators(Validators.required);
            form?.get(valueField)?.enable({ emitEvent: false });
        } else {
            form?.get(valueField)?.clearValidators();
            form?.get(valueField)?.disable({ emitEvent: false });
            form?.get(valueField)?.setValue(null, { emitEvent: false });
        }
        form?.get(valueField)?.updateValueAndValidity();
    }

    public getAllowFieldName(name: string): string {
        return 'allow' + name;
    }

    public getValueFieldName(name: string): string {
        return 'value' + name;
    }

    public registerGamificationFields(gamificationFields: KeyValue<string, string>[], form: FormGroup) {
        form?.addControl('isGeral', new FormControl(true, Validators.required));

        gamificationFields.forEach(field => {
            form?.addControl(this.getAllowFieldName(field.key), new FormControl(true, Validators.required));
            form?.addControl(this.getValueFieldName(field.key), new FormControl({ value: null, disabled: true }, Validators.required));
            form?.get(this.getAllowFieldName(field.key))?.valueChanges.subscribe(() => {
                this._onChangeAllowGamification(field.key, form);

                if (gamificationFields.some(field => !(form.get(this.getAllowFieldName(field.key))?.value))) {
                    form.get('isGeral')?.setValue(false);
                    form.get('isGeral')?.disable();
                    return;
                } else {
                    form.get('isGeral')?.enable()
                }
            })
        })

        form?.get('isGeral')?.valueChanges.subscribe(() => {
            gamificationFields.forEach(field => {
                this._onChangeAllowGamification(field.key, form);
            })
        })
    }

    public clearGamification(gamificationFields: KeyValue<string, string>[], form: FormGroup) {
        form?.get('isGeral')?.setValue(true);

        gamificationFields.forEach(field => {
            form?.get(this.getAllowFieldName(field.key))?.setValue(true);
            form?.get(this.getValueFieldName(field.key))?.setValue(null);
        })
    }

    public getGamificationObject(gamificationFields: KeyValue<string, string>[], form: FormGroup): any {
        let gamificationObject: any = {}

        gamificationFields.forEach(field => {
            gamificationObject[this.getAllowFieldName(field.key)] = form?.get(this.getAllowFieldName(field.key))?.value;
            gamificationObject[this.getValueFieldName(field.key)] = (form?.get(this.getValueFieldName(field.key))?.value?.length === null ? null : form?.get(this.getValueFieldName(field.key))?.value) ?? null;
        })

        return gamificationObject;
    }

    public setGamificationValues(gamificationFields: KeyValue<string, string>[], form: FormGroup, dto: any) {
        gamificationFields.forEach(field => {
            form?.get(this.getAllowFieldName(field.key))?.setValue(dto[this.getAllowFieldName(field.key)] ?? true, { emitEvent: false });
            form?.get(this.getValueFieldName(field.key))?.setValue(dto[this.getValueFieldName(field.key)] ?? null, { emitEvent: false });
        })
        form.get('isGeral')?.setValue(!gamificationFields
            .some(field => (!!form?.get(this.getValueFieldName(field.key))?.value) ?? false));
        if (gamificationFields.some(field => !form.get(this.getAllowFieldName(field.key))?.value)) form.get('isGeral')?.disable()

    }
}