import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataBridgeServiceInterface } from '../../interfaces/data-bridge-service.interface';
import { AddressInfo } from '../../domains/address-info';
import { CnpjInfo } from '../../domains/cnpj-info';

@Injectable({
  providedIn: 'root'
})
export class DataBridgeServiceMock implements DataBridgeServiceInterface {

  urlBase: string;

  constructor(private http: HttpClient) {
    this.urlBase = environment.bff_web;
  }
  async getByCNPJ(cnpj: string): Promise<CnpjInfo> {
    try {
      const info = await lastValueFrom(this.http.get<Array<CnpjInfo>>(this.urlBase + `/cnpj?cnpj=${cnpj}`));
      const infoCnpj = info[0];

      if (infoCnpj) {
        return infoCnpj;
      }

    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getByCEP(cep: string): Promise<AddressInfo> {
    try {
      const info = await lastValueFrom(this.http.get<Array<AddressInfo>>(this.urlBase + `/cep?cep=${cep}`));
      const address = info[0];

      if (address) {
        return address;
      }

    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

}
