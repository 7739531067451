export type OriginRegistration = 'SELF_REGISTER' | 'CADASTRO' | 'IMPORT' | 'INTEGRATION' | 'AFFETIC';

export const OriginTypes = {
  SELF_REGISTER: { label: 'Autocadastro', value: 'SELF_REGISTER' },
  AFFETIC: { label: 'AFFETIC', value: 'AFFETIC' },
  IMPORT: { label: 'IMPORTAÇÃO', value: 'IMPORT' },
  INTEGRATION: { label: 'INTEGRAÇÃO', value: 'INTEGRATION' },
  CADASTRO: { label: 'CADASTRO', value: 'CADASTRO' }
}

export const OriginRegistrationWithLabels = Object.values(OriginTypes);
