import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Notification } from '../../domains/notification/notification';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { SharedService } from '../../services/shared.service';
import { GlobalFunctions } from '../../utils/global-functions';
import { Subscription, forkJoin } from 'rxjs';
import { Origin, stringToEnumSimple } from '../../enums/origin';
import { Router } from '@angular/router';
import { WebSocketNotificationService } from '../../services/webSocket-notification.service';
import { User } from '../../domains/user';
import { NotificationOriginDto } from '../../domains/notification/notification-origin-dto';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit , OnDestroy{

  allNotifications: Array<Notification> = [];
  notificationMap: Map<string, Notification> = new Map();
  checkedNotification: boolean = false;

  @Output() notificationsCount = new EventEmitter<number>();
  firstGet: boolean = true;
  webSocketServiceSubscription: Subscription | undefined;

  isMobileRouter: boolean = false;

  constructor(private notificationService: NotificationService,
    public globalFunctions: GlobalFunctions,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private webSocketService: WebSocketNotificationService) { }

  ngOnInit(): void {
    this.isMobileRouter =this.router.url.includes("/notification-mobile")

    this.sharedService.invokeNotificationObservable$.subscribe(() => {
      this.inicializeGetNotification();
    });

    if(this.isMobileRouter){
      this.inicializeGetNotification();
    }
  }

  ngOnDestroy(): void {
    if(!this.isMobileRouter){
      this.clearNotificationSession();
      this.webSocketServiceSubscription?.unsubscribe();
    }
  }

  clearNotificationSession() {
    this.allNotifications = []
    this.notificationsCount.emit(0)
    this.notificationMap = new Map();
  }

  getNotifications() {
    this.notificationService.getAll()
      .subscribe(
        notifications => {
          notifications.forEach(notification => {
            if (!this.notificationMap.has(notification.id))
              this.notificationMap.set(notification.id, { ...notification, expanded: false, timeNotification: this.notificationFormatDate(notification.createdDate) })
            else {
              const notificationFound = this.notificationMap.get(notification.id)!;
              notificationFound.content = notification.content;
              notificationFound.isRead = notification.isRead;
              notificationFound.subject = notification.subject;
              notificationFound.origin = notification.origin;
              notificationFound.originId = notification.originId;
              notificationFound.timeNotification = this.notificationFormatDate(notification.createdDate);
            }

          });
          this.allNotifications = Array.from(this.notificationMap.values())
            .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
          this.emitCountNotificationNotRead();
        });
  }

  readNotification(notification: Notification) {
    if (!notification.isRead) {
      notification.isRead = true;

      if(notification.id){
        this.notificationService.readNotification(notification);
      }
    }
    notification.expanded = !notification.expanded;
    this.emitCountNotificationNotRead();
    this.redirectToPage(notification);
  }

  redirectToPage(notification: Notification) {
    const origin = notification.origin;
    switch (origin) {
      case Origin.CLIENT:
        this.router.navigate(['clients/edit-client'], { queryParams: { id: notification.originId } });
        break;
      case Origin.BENEFIT:
        this.router.navigate(['benefits']);
        break;
      case Origin.POST_COMMENT:
        this.router.navigate(['posts/by'], { queryParams: { id: notification.originId } });
        break;
      case Origin.NETWORK:
        this.router.navigate(['networks/comments'], { queryParams: { id: notification.originId } });
        break;
      case Origin.REPORTEDCOMMENT:
        this.router.navigate(['/posts/show-reported-comments'], { queryParams: { id: notification.originId } });
        break;

    }
  }

  notificationFormatDate(dateInput: Date): string {
    let inputDate: Date;

    if (typeof dateInput === 'string') {
      inputDate = new Date(dateInput);
    } else {
      inputDate = dateInput;
    }

    const currentDate: Date = new Date();
    const diffInMilliseconds: number = currentDate.getTime() - inputDate.getTime();
    const diffInHours: number = diffInMilliseconds / (1000 * 60 * 60);
    const diffInMinutes: number = diffInMilliseconds / (1000 * 60);

    if (diffInMinutes < 60) {
      return `${Math.floor(diffInMinutes)}m`;
    }
    else if (diffInHours < 24) {
      return `${Math.floor(diffInHours)}h`;
    }
    else if (diffInHours >= 24 && diffInHours < 48) {
      return '1d';
    }
    else {
      const day = inputDate.getDate() > 9 ? inputDate.getDate() : '0' + inputDate.getDate();
      const month = (inputDate.getMonth() + 1) > 9 ? (inputDate.getMonth() + 1) : '0' + (inputDate.getMonth() + 1);
      const year = inputDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }

  deleteNotificationById(notification: Notification, index: number) {
    this.notificationService.deleteByIds([notification.id])
      .subscribe(
        hasDeleted => {
          if (hasDeleted) {
           this.removeNotificationFromUI(notification.id, index)
          }
        })
  }

  removeNotificationFromUI(id: string, index: number) {
    this.notificationMap.delete(id);
    this.allNotifications.splice(index, 1);
    this.notificationsCount.emit(this.allNotifications.filter(n => !n.isRead).length);
  }

  deleteAllNotifications() {
    const idsToBeDeleted: string[] = [];
    const notificationsWithoutId: NotificationOriginDto[] = [];

    this.allNotifications.forEach(notification => {
      if (notification.id) {
        idsToBeDeleted.push(notification.id);
      } else {
        notificationsWithoutId.push({
          origin: stringToEnumSimple(notification.origin),
          originId: notification.originId
        });
      }
    });

    this.notificationService.deleteByIds(idsToBeDeleted)
    .subscribe(
      hasDeleted => {
        if (hasDeleted) {
         this.clearNotificationSession()
        }
      })
  }

  inicializeGetNotification() {
    if (this.authService.isLoggedIn()) {
      this.getNotifications();
      this.subscribeWebsocket();
    } else {
      this.allNotifications = [];
      this.emitCountNotificationNotRead();
    }
  }

  updateList(notification: Notification) {
    if (notification) {
      this.allNotifications = [notification ,...this.allNotifications]
      this.emitCountNotificationNotRead();
    }
  }

  emitCountNotificationNotRead(){
    this.notificationsCount.emit(this.allNotifications.filter(n => !n.isRead).length);
  }

  async conectWebSocket() {
    const user: User| null = this.authService.getUserFromCache();
    if (user?.id){
      this.webSocketService.connect(`${user.associatedClientId}`,`${user.id}`);
    }
  }

  subscribeWebsocket() {
    this.webSocketServiceSubscription = this.webSocketService
    .getNotificationMessageSubject()
    .subscribe(() => {
      this.getNotifications()
    });
  }
}
