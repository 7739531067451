import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { User } from 'src/app/shared/domains/user';
import { ClientService } from 'src/app/shared/services/client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ClientDialogComponent } from '../client-dialog/client-dialog.component';
import { map } from 'rxjs';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit, OnChanges {
  @ViewChild('myInput')
  myInputVariable: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('dataImportTableSort') dataImportTableSort!: MatSort;
  phoneMask: string = '(00) 00000-0000';
  cnpjMask: string = '00.000.000/0000-00';
  @Input() clients?: ClientDTO[];
  @Input() disabled?: boolean;

  @Input() productIds?: number[];

  @Output() selectedListEmitter: EventEmitter<ClientDTO[]> = new EventEmitter<
    ClientDTO[]
  >();
  searchFormControl = new FormControl();
  dataSource = new MatTableDataSource<any>();

  displayedColumns: string[] = [
    'cnpj',
    'corporateName',
    'phone',
    'email',
    'actions',
  ];

  currentList: ClientDTO[] | undefined = [];
  allClientsList: ClientDTO[] | undefined = [];
  currentListView: ClientDTO[] | undefined = [];
  userCache: User | null | undefined;
  allClients: ClientDTO[] = []

  constructor(
    private router: Router,
    public globalFunctions: GlobalFunctions,
    private clientService: ClientService,
    private dialog: MatDialog
  ) {
    this.searchFormControl.valueChanges.pipe(
      map((value: string) => value.trim().toLocaleLowerCase()),
      map((value: string) => value.trim().replace(/[.\-()/ ]/g, '').toLocaleLowerCase())
    ).subscribe((searchText: string) => {
      if (!searchText) {
        this.getList();
      } else {
        this.currentList = this.currentList?.filter((item: ClientDTO) => {
          return (
            item.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.cnpj?.toLowerCase().includes(searchText) ||
            item.corporateName?.toLowerCase().includes(searchText.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchText.toLowerCase())
          );
        });
        this.dataSource = new MatTableDataSource(this.currentList);
        this.configTable();
      }
    });
    this.configTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && changes['disabled'].currentValue) {
      this.removeAllItems();
    } else {
      this.getList();
    }
  }

  removeAllItems() {
    this.currentListView = [];
    this.dataSource = new MatTableDataSource(this.currentListView);
    this.selectedListEmitter.emit(this.currentListView);
  }

  ngOnInit(): void {
    this.getList();
  }

  ngAfterViewInit(): void {
    this.configTable();
  }

  getList() {
    if (this.clients?.length) {
      this.currentList = this.currentListView?.slice();
      this.currentListView = this.clients
      this.dataSource = new MatTableDataSource(this.currentListView);
    } else {
      this.currentList = this.currentListView?.slice();
      this.dataSource = new MatTableDataSource(this.currentList);
    }
    this.configTable();
  }

  onMessage(message: any) {
    console.log(message);
  }

  async getAllClients() {
    try {
      const allClients = await this.clientService.getAll();
      this.allClientsList = allClients as ClientDTO[];
      this.allClients = [...this.allClientsList]
    } finally {
      if (this.clients?.length) {
        this.currentList = this.currentListView?.slice();
        const clientIds = new Set(this.clients?.map(client => client.id));
        const objetosFiltrados = this.allClientsList?.filter(objeto => clientIds.has(objeto.id));
        this.currentListView = objetosFiltrados;
        this.dataSource = new MatTableDataSource(this.currentListView);
        this.selectedListEmitter.emit(this.currentListView);
      }
    }
    this.configTable();
  }

  async openClientModal() {
    if(!this.allClients.length){
      await this.getAllClients();
    }
    this.allClientsList = this.allClients;
    const dialogRef = this.dialog.open(ClientDialogComponent, {
      width: !this.globalFunctions.isMobile() ? '60%' : '100vw',
      maxWidth: '100vw',
      data: {
        list: this.currentListView,
        allClients: this.allClientsList,
      },
      height: !this.globalFunctions.isMobile() ? '70%' : '80%',
    });
    dialogRef.componentInstance?.selectedListEmitter.subscribe((valor) => {
      if (this.currentListView) {
        this.dataSource = new MatTableDataSource(valor);
        this.currentListView = valor;
        this.selectedListEmitter.emit(this.currentListView);
      }
    });
  }

  removeItem(idToRemove: number): void {
    if (this.currentListView) {
      const index = this.currentListView.findIndex(
        (item) => item.id === idToRemove
      );
      if (index !== -1) {
        this.currentListView.splice(index, 1); // Remove o item do array
        this.dataSource = new MatTableDataSource(this.currentListView);
        this.selectedListEmitter.emit(this.currentListView);
      } else {
        console.log(`Item com ID ${idToRemove} não encontrado.`);
      }
    }
    this.configTable();
  }

  private configTable() {
    if (this.paginator) this.dataSource.paginator = this.paginator;
    if (this.dataImportTableSort) {
      this.dataSource.sort = this.dataImportTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property == 'codeAndSurcode') {
          return (
            item['code']?.toLowerCase() + ' ' + item['lastcode']?.toLowerCase()
          );
        }

        return item[property]?.toLowerCase();
      };
    }
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }
}
