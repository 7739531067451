<!-- Visão ADMINISTRADOR -->
<app-search-table
  *ngIf="!clientId"
  tableTitle="Avisos"
  addButtonLabel="Novo Aviso"
  [tableSourceData]="dataSource"
  [columnsConfigurations]="columns"
  [deleteDialogInfo]="dialogInfo"
  [sortingDataAccessorMethod]="sortingDataAccessorMethod"
  [pageLength]="pageLength"
  [pageIndex]="pageIndex"
  (onAddButtonClick)="redirectToNew()"
  (onFilter)="applyFilter($event)"
  (onPageCount)="onPageChangeView($event)"
  (onEditButtonClick)="redirectToEdit($event)"
  (onViewButtonClick)="redirectToView($event)"
  (sortDirection)="removeSorting($event)"
>
</app-search-table>

<!-- Visão CLIENTE -->
<app-search-table
  *ngIf="clientId"
  tableTitle="Avisos"
  addButtonLabel="Novo Aviso"
  (onAddButtonClick)="redirectToNew()"
  (onEditButtonClick)="redirectToEdit($event)"
  (onViewButtonClick)="redirectToView($event)"
  [deleteDialogInfo]="dialogInfo"
  [tableSourceData]="dataSource"
  [columnsConfigurations]="columns"
  [selectConfiguration]="selectClient"
  (onSelect)="getNoticesByClient($event?.id)"
  (onFilter)="applyFilter($event)"
  (onPageCount)="onPageChangeView($event)"
  [sortingDataAccessorMethod]="sortingDataAccessorMethod"
  [pageLength]="pageLength"
  [pageIndex]="pageIndex"
  (sortDirection)="removeSorting($event)"
  [disableAddButton]="!canCreate"
  [disableDeleteButton]="!canDelete"
  [disableEditButton]="!canEdit"
>
</app-search-table>

<div class="container-fluid d-sm-none">
  <div style="margin: 0px 10px 10px 10px">
    <div class="row">
      <div class="col-12">
        <ng-container>
          <div
            class="mobile-session"
            *ngFor="let notice of dataSource.data; let i = index"
          >
            <div
              class="card-row-mobile"
              *ngIf="paginationCard > i"
              (click)="redirectToEdit(notice)"
            >
              <div class="card-mobile-content">
                <div class="row margin-btm">
                  <label
                    class="paragraph paragraph-1 semibold truncate-words">{{ notice.name }}</label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">Início: {{notice.startTimestamp}} </label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">Fim: {{notice.startTimestamp}} </label>
                </div>
                <div class="row margin-btm">
                  <label class="paragraph paragraph-1 truncate-words">{{notice.recurrence}} </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            class="pagination-card"
            *ngIf="dataSource.data.length >= paginationCard"
          >
            <label
              class="paragraph paragraph-2 semibold primary-color"
              (click)="getNoticesByMobile()">Mostrar mais</label
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-notice-central-modal
  [showNoticesCentral]= showNoticesCentral
  (onClose)= "showNoticesCentral = false; noticeId = 0"
  [noticeId]="noticeId"
>
</app-notice-central-modal>
