import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as StompJs from '@stomp/stompjs';
import { environment } from 'src/environments/environment';
import { AuthService } from "./auth.service";
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { Notification } from '../domains/notification/notification';
import { User } from '../domains/user';

@Injectable({
  providedIn: 'root',
})
export class WebSocketNotificationService {
  private notificationSubject: Subject<Notification> = new Subject<Notification>();
  client: StompJs.Client | undefined;

  constructor(private authService: AuthService, private toastComponent: ToastComponent) { }

  getNotificationMessageSubject(): Subject<Notification> {
    return this.notificationSubject;
  }

  connect(clientId: string, userId: string): void {
    const token = this.authService.getToken();
    this.client = new StompJs.Client({
      brokerURL: environment.bff_webSocket,
      connectHeaders: {
        userId: userId,
        authorization: `Bearer ${token}`
      },
      reconnectDelay: 1000,
    });

    this.client.onConnect = () => this.subscribeToTopics(clientId, userId);
    this.client.onWebSocketError  = (error: any) => this.handleError(error);
    this.client.activate();
  }

  private subscribeToTopics(clientId: string, userId: string): void {
    this.subscribe(`/user/${userId}/notification`, this.notificationSubject);

    if (Number(clientId) > 0) {
      this.subscribe(`/user/CLIENT/${clientId}/notification-group`, this.notificationSubject);
    }
  }

  private subscribe(topic: string, subject: Subject<Notification>): void {
    this.client?.subscribe(topic, (message: any) => {
      subject.next(message);
    });
  }

  private handleError(error: any): void {
    console.error('Error with websocket', error);
  }

  disconnect(): void {
    this.client?.deactivate();
  }

  async conectWebSocket() {
    const user: User| null = this.authService.getUserFromCache();
    if (user?.id){
     this.connect(`${user.associatedClientId}`,`${user.id}`);
    }
  }
}
