import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../domains/user';
import { PasswordServiceInterface } from '../../interfaces/password-service.interface';
import { RegisterPassword } from '../../domains/register-password';
import { ToastComponent } from '../../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class PasswordServiceMock implements PasswordServiceInterface {

  urlBase: string;
  
  constructor(private http: HttpClient,
              private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.authorization;
  }

  async recoveryByEmail(email: string): Promise<boolean> {
    try {
        const userArray = await lastValueFrom(this.http.get<Array<User>>(this.urlBase + `/users?email=${email}`));
        const user = userArray[0];
      
        if (user) {
          return true
        }
      } catch (error) {
       console.error(error);
      }
      return false;
  }

  async getUser(token: string): Promise<User> {
    try {
        const userArray = await lastValueFrom(this.http.get<Array<User>>(this.urlBase + `/users?token=${token}`));
        const user = userArray[0];

        this.http.put(this.urlBase + `/users/${user.id}`, user);

        if (user) {
          return user;
        }
      } catch (error) {
       console.error(error);
      }
      return new User();
  }

  async changePassword(registerPassword: RegisterPassword): Promise<void> {
    const url = `${this.urlBase}/users/register-new-password`;
    try {
      await lastValueFrom(
        this.http.post<void>(
          url,
          registerPassword,
          { observe: 'response' },
        )
      );
    } catch (error: any) {
      if (error.status === 401) {
        this.toastComponent.showWarningCustomMessage(
          'Link de cadastro de senha expirado!',
          ''
        );
      } else if (error.error.message) {
        this.toastComponent.showWarningCustomMessage(error.error.message, '');
      }
      throw new Error();
    }
  }
  
}
