import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ConfigIntegration } from '../domains/config-integration/config-integration';
import { ClientToken } from '../domains/config-integration/client-token';
import { ToastComponent } from '../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ConfigIntegrationService {
  urlBase: string;

  constructor(private http: HttpClient, private toastComponent: ToastComponent) {
    this.urlBase = environment.bff_web;
  }
  async save(request: ConfigIntegration): Promise<boolean> {
    return lastValueFrom(this.http.post<boolean>(this.urlBase + `/config-integration`, request, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async update(request: ConfigIntegration): Promise<boolean> {
    return lastValueFrom(this.http.put<boolean>(this.urlBase + `/config-integration`, request, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async getByClient(id: number): Promise<ConfigIntegration> {
    try {
      const configuration: ConfigIntegration = await lastValueFrom(
        this.http.get<ConfigIntegration>(
          this.urlBase + `/config-integration/${id}/by-client`
        )
      );

      if (configuration) {
        return configuration;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async generateToken(id: number): Promise<ClientToken> {
    try {
      const configurationToken: ClientToken = await lastValueFrom(
        this.http.get<ClientToken>(
          this.urlBase + `/config-integration/${id}/generate-token`
        )
      );

      if (configurationToken) {
        return configurationToken;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }
}
