export const environment = {
  local: false,
  development: true,
  homologation: false,
  production: false,
  mock: false,
  tst: false,
  authorization: 'https://dev.plataforma.comunifyapp.com.br/security/api/v1/auth',
  authorizationV2: 'https://dev.plataforma.comunifyapp.com.br/security/v2',
  bff_web: 'https://dev.plataforma.comunifyapp.com.br/web',
  auto_registration_link_prefix: 'https://dev.plataforma.comunifyapp.com.br/#/autocadastro/',
  bff_webSocket: 'wss://dev.plataforma.comunifyapp.com.br/web/ws',
  bff_model_file: 'import/6e5d3c96-2cbe-4fdd-a8ad-8b25ca93c7b5-comunify-modelo-de-importacao-v2.pdf',
  md_storage: 'https://dev.plataforma.comunifyapp.com.br/storage-service',
  logo_branca: 'https://storage.googleapis.com/defaults.wedorh-dev.wedotec.com.br/images/logo_branca.png',
  icone_branco: 'https://storage.googleapis.com/defaults.wedorh-dev.wedotec.com.br/images/perfil_branco.png',
  imagem_fundo: 'https://storage.googleapis.com/defaults.wedorh-dev.wedotec.com.br/images/background_web.png'
};
