import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InternalUserService } from './internal-user.service';
import { AuthService } from './auth.service';
import { UserClientService } from './user-client.service';
import { LocalStorageKeys } from '../enums/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private urlSource = new BehaviorSubject<string>('');
  changeImageObservable = this.urlSource.asObservable();

  private nameSource = new BehaviorSubject<string>('');
  changeNameObservable = this.nameSource.asObservable();

  constructor(
    private internalUserService: InternalUserService,
    private userClientService: UserClientService,
    private authService: AuthService
  ) { }

  async changeImageInternalUser() {
    const user = this.authService.getUserFromCache();
    if (user && user.id) {
        const result = await this.internalUserService.getResumedUser(user.id);
        this.authService.saveUserWithNewUrlAndName(result.uri, result.name);
        this.urlSource.next(result.uri);
        this.nameSource.next(result.name);
    }
  }

  async changeImageClient(id: number) {
    const user = this.authService.getUserFromCache();
    if (user && user.id) {
      const result = await this.userClientService.findById(id);
      const cachedClientId = localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID);
      if(result.client.id == cachedClientId) {
        this.authService.saveUserWithNewUrlAndName(result.uri ?? '', result.name);
        this.urlSource.next(result.uri ?? '');
        this.nameSource.next(result.name);
      }
    }
  }
}
