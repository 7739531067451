export type ImportStatus = 'AWAITING_PROCESSING' | 'PROCESSING' | 'SUCCESSFULLY_PROCESSED' | 'PROCESSED_FAILED' | 'ERROR';

export const ImportStatuspTypes = {
  AWAITING_PROCESSING: { label: 'Aguardando Processamento', value: 'AWAITING_PROCESSING'},
  PROCESSING: { label: 'Em Processamento', value: 'PROCESSING'},
  SUCCESSFULLY_PROCESSED: { label: 'Processado com sucesso', value: 'SUCCESSFULLY_PROCESSED'},
  PROCESSED_FAILED: { label: 'Processado com falha', value: 'PROCESSED_FAILED'},
  ERRO: { label: 'Erro', value: 'ERROR'}

}

export const ImportStatusWithLabels = Object.values(ImportStatuspTypes);
