import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientServiceInterface } from '../../interfaces/client-service.interface';
import { Client } from '../../domains/client';
import { Observable, catchError, lastValueFrom, EMPTY as GO_TO_ON_COMPLETE, map, of } from 'rxjs';
import { ClientGeneralData } from '../../domains/client-general-data';
import { ClientDTO, InfoClientDTO } from '../../domains/clientDto';
import { ResumedClientDTO } from '../../domains/clients/resumed-client-dto';
import { ClientResumedDTO } from '../../domains/clients/client-resumed-dto';

@Injectable({
  providedIn: 'root'
})
export class ClientServiceMock implements ClientServiceInterface {

  urlBase: string;
  constructor(private http: HttpClient
    ) {
    this.urlBase = environment.bff_web;
  }

  async findInfoClientByLink(link: string): Promise<InfoClientDTO> {
    try {
      const infoClients = await lastValueFrom(this.http.get<Array<InfoClientDTO>>(this.urlBase + `/info-clients?tradingName=${link}`));
      return infoClients[0]
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getResumed(momentsIds: number[], productsIds: number[]): Promise<ResumedClientDTO[]> {
    return (await this.getAll()).map(c => ({ ...c, tradingName: c.corporateName, id: c.id ? c.id : 0 }));
  }

  async getGeneralData(): Promise<ClientGeneralData> {
    let clientGeneralData: ClientGeneralData = new ClientGeneralData();

    try {
      const companies = await lastValueFrom(this.http.get<Array<ClientDTO>>(this.urlBase + `/clients`));

      companies.forEach(c => {
        clientGeneralData.companies.push(c);
      })

      return clientGeneralData;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async delete(client: Client): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.delete(this.urlBase + `/clients/${client.id}`, { observe: 'response' }));
      if (success) {
        return true;
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }

    return false;
  }

  async getAll(filter?: Object): Promise<Array<Client>> {
    try {
      const params = new HttpParams({ fromObject: { ...filter as any } });
      const clients: Array<Client> = await lastValueFrom(this.http.get<Array<Client>>(this.urlBase + `/clients`, { params }));
      return clients;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }


  async getAllActive(): Promise<ResumedClientDTO[]> {
    try {
      const clients: Array<ResumedClientDTO> = await lastValueFrom(this.http.get<Array<ResumedClientDTO>>(`${this.urlBase}/clients`));
      if (clients) {
        return clients;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllNamesActive(): Promise<ClientResumedDTO[]> {
    try {
      const clients: Array<ClientResumedDTO> = await lastValueFrom(this.http.get<Array<ClientResumedDTO>>(`${this.urlBase}/clients`));
      if (clients) {
        return clients;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  get(id: number): Observable<ClientDTO> {
    return this.http.get<ClientDTO>(`${this.urlBase}/clients/${id}`).pipe(
      catchError(() => GO_TO_ON_COMPLETE)
    );
  }

  async save(client: FormData): Promise<string> {
    const jsonString = client.get('client');
    let clientObj;

    if (jsonString) {
      clientObj = JSON.parse(jsonString as string);
    }

    try {
      const response = await lastValueFrom(this.http.post(this.urlBase + `/client`, clientObj, { 'headers': { 'Content-Type': 'application/json' } }));

      if (response) {
        return '' + Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
      }
    } catch (error) {
      console.error(error);
    }
    return '';
  }

  update(client: FormData): Observable<boolean> {
    const jsonString = client.get('client');
    let clientObj;

    if (jsonString) {
      clientObj = JSON.parse(jsonString as string);
    }

    return this.http.put(this.urlBase + '/client', clientObj, { observe: 'response' }).pipe(
      catchError(() => of(false)),
      map(() => true)
    )
  }

  async getBeneficiaryClients(): Promise<Array<ClientDTO>> {
    try {
      const clients: Array<ClientDTO> = await lastValueFrom(this.http.get<Array<ClientDTO>>(this.urlBase + `/clients?isActive=true`));
      return clients;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getClientsCombobox(): Promise<Array<ClientDTO>> {
    return this.getBeneficiaryClients();
    try {
      const clients: Array<ClientDTO> = await lastValueFrom(this.http.get<Array<ClientDTO>>(this.urlBase + `/clients`));
      return clients;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async setActive(id: number, isActive: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('value', isActive);
    try {
      await lastValueFrom(this.http.put(this.urlBase + `/clients/active-client/${id}`, params));
      return true;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getAllWithSubsidiary(id: number, filter?: Optional): Promise<Array<Client>> {
    try {
      const params = new HttpParams({ fromObject: { ...(filter as any) } });
      const clientArray: Array<Client> = await lastValueFrom(
        this.http.get<Array<Client>>(this.urlBase + `/clients?id=${id}&includeSubsidiary=true`, { params })
      );

      if (clientArray) {
        return clientArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
