import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { InternalUserServiceInterface } from '../../interfaces/internal-user-service.interface';
import { ToastComponent } from '../../components/toaster/toast/toast.component';
import { InternalUser } from '../../domains/internal-user';
import { UserResumed } from '../../domains/userResumed';
import { InternalUserDto, InternalUserPage } from '../../domains/internal-user/internal-user-dto';

@Injectable({
  providedIn: 'root'
})
export class InternalUserServiceMock implements InternalUserServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
  ) {
    this.urlBase = environment.bff_web;
  }

  async getAllUsers(): Promise<Array<InternalUser>> {
    try {
      const userArray: Array<InternalUser> = await lastValueFrom(this.http.get<Array<InternalUser>>(this.urlBase + `/internal-users`));

      if (userArray) {
        return userArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllInternalUsersPage(page: number, size: number, search?: string): Promise<InternalUserPage> {
    try {
      const users: InternalUserPage = await lastValueFrom(this.http.get<InternalUserPage>(this.urlBase + `/internal-users`, {
          params: {
          page: page,
          size: size,
          search: search ? search : ''
          },
        })
      );

      if (users) {
        return users;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async saveUser(user: InternalUserDto): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/internal-users`, user, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async editUser(user: InternalUserDto): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.put(this.urlBase + `/internal-users`, user, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async delete(user: InternalUserDto): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/internal-users/${user.id}`, { observe: 'response' }));
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async getResumedUser(userId: number): Promise<UserResumed> {
    try {
      const user: UserResumed = await lastValueFrom(this.http.get<UserResumed>(this.urlBase + `/internal-users/user/${userId}`));

      if (user) {
        return user;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
