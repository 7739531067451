import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { UserClientSearchComponent } from 'src/app/pages/user-client/main/user-client-search.component';
import { UserClientComponent } from 'src/app/pages/user-client/user-client/user-client.component';
import { ProfilePermissions } from '../constants/profile-permissions';
import { AdminGuard } from './admin.guard';

@Injectable({
  providedIn: 'root'
})
export class ClientUsersGuard implements CanActivateChild, CanActivate {

  constructor(
    private permissionsService: NgxPermissionsService,
    private adminGuard: AdminGuard,
    private rolesService: NgxRolesService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.adminGuard.canActivate()) {
      return true;
    }

    if(childRoute.component == UserClientSearchComponent) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER);
    }

    if(childRoute.component == UserClientComponent && (state.url.includes('view-user-client'))) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER);
    }

    if(childRoute.component == UserClientComponent && (state.url.includes('edit-user-client'))) {
      return this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT_USER);
    }

    if(childRoute.component == UserClientComponent && state.url.includes('new-user-client')) {
      return this.permissionsService.hasPermission(ProfilePermissions.CREATE_CLIENT_USER);
    }

    return false;
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.adminGuard.canActivate()) {
      return true;
    }

    if (this.rolesService.getRole('CLIENT')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_CLIENT_USER);
    }

    return false;
  }
}
