import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchColumnConfiguration } from '../search/interfaces/search-table-column-configuration.interface';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-groups-modal',
  templateUrl: './groups-modal.component.html',
  styleUrls: ['./groups-modal.component.scss']
})
export class GroupsModalComponent implements OnInit, OnChanges {

  @Input()
  groups: any[] = [];

  @Input()
  showCheckbox: boolean = false;

  @Input()
  visible: boolean = false;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSelectedElements: EventEmitter<any[]> = new EventEmitter<any[]>();

  changeCheckbox: boolean = false;

  columnsConfiguration: SearchColumnConfiguration[] = [
    {
      columnName: 'Nome',
      valueName: 'name',
      columnValueCssClass: 'column-60 truncate-words',
      columnHeaderCssClass: 'column-60',
    },
    {
      columnName: 'Situação',
      valueName: 'active',
      toggleConfiguration: {
        valueName: 'active'
      },
      columnValueCssClass: 'column-10'
    },
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  private lastFilteredValue: string = '';
  private groupsFiltered: any[] = [];

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groups']) {
      this.groupsFiltered = this.groups;
      this.dataSource = new MatTableDataSource<any>(this.groupsFiltered);
    }
    if (changes['showCheckbox']) {
      this.configureCheckboxColumn();
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.visible = false;
    this.onClose.emit();
  }

  applyFilter(event: string) {
    const filteredValue = event.toLocaleLowerCase().trim();
    if (!filteredValue && this.lastFilteredValue) {
      this.dataSource.data = this.groupsFiltered;
    } else {
      this.dataSource.data = this.groupsFiltered.filter(element =>
        element.name.toLowerCase().includes(filteredValue)
      );
    }
    this.lastFilteredValue = filteredValue;
  }

  onConfirm() {
    this.onSelectedElements.emit(this.groupsFiltered.filter(e => e.isSelected));
    this.close()
  }
  private configureCheckboxColumn() {
    if (this.showCheckbox) {
      this.columnsConfiguration.unshift({
        columnName: '',
        isCheckbox: true,
        valueName: 'isSelected',
      });
    } else if (this.columnsConfiguration[0].isCheckbox) {
      this.columnsConfiguration.shift();
    }
  }

}
