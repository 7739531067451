import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { ToastComponent } from "../components/toaster/toast/toast.component";

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastComponent: ToastComponent) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if ((error.status === 500 || error.status === 0 || error.status === 503))
            {
              this.toastComponent.showErrorDefaultMessage();
              return throwError(() => error);
            }
            return throwError(() => error);
          })
        );
      }
  }
