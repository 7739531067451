import { Group } from './group';

export class User {
  id?: number;
  username: string;
  name: string;
  cpf: string;
  email: string;
  profile: string;
  token: string;
  isActive: boolean;
  password?: string | null;
  acceptTerm: boolean;
  groups: Array<Group>;
  authorities: string[];
  selected?: boolean;
  userId?: number;
  active?: boolean;
  isAdministrator?: boolean;
  clientId?: number;
  uri?: string;
  hasBeenDeleted: boolean;
  associatedClientName?: string;
  associatedClientId?: number;
  centralizerClientId?: number;
  isCentralizer?: boolean;
  constructor(
    username?: string,
    name?: string,
    cpf?: string,
    email?: string,
    profile?: string,
    token?: string,
    isActive?: boolean,
    password?: string,
    acceptTerm?: boolean,
    groups?: Array<Group>,
    authorities?: Array<string>,
    active?: boolean,
    isAdministrator?: boolean,
    clientId?: number,
    uri?: string,
    hasBeenDeleted?: boolean,
    associatedClientName?: string,
    centralizerClientId?: number,
    isCentralizer?: boolean
) {
    this.username = username || '';
    this.name = name || '';
    this.email = email || '';
    this.profile = profile || '';
    this.token = token || '';
    this.cpf = cpf || '';
    this.isActive = isActive || false;
    this.password = password || null;
    this.acceptTerm = acceptTerm || false;
    this.groups = groups || [];
    this.authorities = authorities || [];
    this.active = active;
    this.isAdministrator = isAdministrator;
    this.clientId = clientId;
    this.uri = uri;
    this.hasBeenDeleted = hasBeenDeleted || false;
    this.associatedClientName = associatedClientName || "";
    this.centralizerClientId = centralizerClientId || 0;
    this.isCentralizer = isCentralizer || false;
  }
}
