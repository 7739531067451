import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GroupByClient } from '../../domains/GroupCollaborator/group-collaborator-dto';
import { GroupCollaboratorService } from '../../services/group-collaborator.service';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss']
})
export class SelectGroupComponent implements OnChanges {

  groups: Array<GroupByClient> = [];
  allGroups: boolean = false;
  hasSelectedGroupById: boolean = false;
  onlyGroups: boolean = false

  @Input()
  clientId: number = 0;

  @Input()
  idGroupsSelected: number[] = [];

  @Input()
  disabled: boolean = false;

  @Output()
  groupSelectionChanged: EventEmitter<{ groups: number[], onlyGroups: boolean }> = new EventEmitter<{ groups: number[], onlyGroups: boolean }>();

  constructor(
    private groupCollaboratorService: GroupCollaboratorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['idGroupsSelected'] && !this.hasSelectedGroupById && this.idGroupsSelected.length > 0) {
      this.selectAwaitGroups();
    }
    if (changes['clientId'] && this.clientId > 0) {
      this.getGroups();
    }
  }

  async getGroupsById() {
    this.groups = await this.groupCollaboratorService.getGroupsByClientId(this.clientId);
  }


  private selectAwaitGroups() {
    if (this.groups.length > 0) {
      this.selectGroupsById();
    } else {
      const timeout = setTimeout(() => {
        if (this.groups.length > 0) {
          clearTimeout(timeout);
          this.selectGroupsById();
        }
      }, 1000);
    }
  }

  async getGroups() {
    await this.getGroupsById();

    if (this.idGroupsSelected.length > 0) {
      this.selectGroupsById();
    }

  }
  async ngOnInit(): Promise<void> {

  }

  onSelectGroup(group: GroupByClient) {
    if (this.disabled) return;
    group.checked = !group.checked;
    this.allGroups = this.groups.every(g => g.checked);
    this.emitEventChangeGroup();
  }

  changeAllGroups() {
    this.idGroupsSelected = [];
    if (this.allGroups) {
      this.selectAllGroups();
      this.emitEventChangeGroup();
    } else {
      this.groups.forEach(g => g.checked = false);
      this.emitEventChangeGroup();
    }
  }

  selectAllGroups() {
    this.groups.forEach(g => g.checked = true);
    this.allGroups = true
  }

  private emitEventChangeGroup() {
    this.groupSelectionChanged.emit({ groups: this.groups.filter(g => g.checked).map(g => g.id), onlyGroups: this.onlyGroups });
  }

  selectGroupsById() {
    this.unselectAll();
    this.hasSelectedGroupById = true;
    this.onlyGroups = this.idGroupsSelected.length > 0

    this.idGroupsSelected.forEach(id => {
      this.groups.forEach(g => {
        if (g.id === id) g.checked = true;
      })
    });

    this.allGroups = this.groups.length === this.idGroupsSelected.length;
    this.emitEventChangeGroup();
  }

  unselectAll() {
    this.allGroups = false
    this.groups.forEach(g => g.checked = false);
    this.groupSelectionChanged.emit({ groups: this.groups.filter(g => g.checked).map(g => g.id), onlyGroups: this.onlyGroups });
  }
}
