<mat-card class="p-0">
  <div [formGroup]="form" class="d-flex justify-content-center">
    <div class="d-flex container-fluid row p-0">
      <div class="col-12 col-sm-4 col-xl-3 p-2">
        <app-client-auto-complete
          (onSelectClient)="onSelectClientEvent($event)"
          [clearSelectedClient]="clearSelectedClient"
        ></app-client-auto-complete>
      </div>

      <div class="mat-input col-12 col-sm-4 col-xl-3 p-2">
        <mat-form-field appearance="outline" class="mat-input">
          <mat-icon matPrefix class="icon-search">search</mat-icon>
          <mat-label class="paragraph paragraph-2 mat-label"
            >Pesquisar</mat-label
          >
          <input matInput formControlName="filter" />
        </mat-form-field>
      </div>

      <mat-form-field class="col-12 col-sm-4 col-xl-3 p-2" appearance="outline">
        <mat-label class="paragraph paragraph-2 mat-label">Demitido</mat-label>
        <mat-select formControlName="isFired">
          <mat-option [value]="null">Todos</mat-option>
          <mat-option [value]="true">Sim</mat-option>
          <mat-option [value]="false">Não</mat-option>
        </mat-select>
      </mat-form-field>

      <div
        class="col-12 col-xl-3 d-sm-flex align-items-center px-2 py-3"
        style="gap: 0.5rem; padding-bottom: 40px"
      >
        <button
          mat-button
          class="btn btn-secondary subtitle subtitle-1 semibold btn-size primary-color d-flex justify-content-center"
          (click)="cleanFilter()"
        >
          <mat-icon class="icon-btn">close</mat-icon>Limpar
        </button>
        <button
          mat-button
          [disabled]="!clientSelectedFormControl.value?.id"
          class="btn btn-primary subtitle subtitle-1 semibold btn-size text-white d-flex justify-content-center"
          (click)="newBeneficiary()"
        >
          <mat-icon class="icon-btn">add</mat-icon>Colaborador
        </button>
        <app-menu-options
          [menuOptionModel]="menuOptions"
          [showOptions]="true"
        ></app-menu-options>
      </div>
    </div>
  </div>
</mat-card>

<app-confirm-modal #confirmModal *ngIf="showConfirmModal"></app-confirm-modal>
