import { Injectable } from '@angular/core';
import { ContactApp, ContactAppTypes } from '../types/contact-app.type';
import { AbstractControl } from '@angular/forms';
import { ISENTA } from './global-variables';
import { Partner } from '../domains/partner/partner';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctions {

  constructor(
    private authService: AuthService,
    private datePipe: DatePipe
  ) { }

  //#region Tamanho da tela
  public screen(): string {
    if (window.screen.width < 576) {
      return 'mobile';
    } else if (window.screen.width < 992) {
      return 'tablet';
    } else if (window.screen.width <= 1200) {
      return 'notebook';
    } else {
      return 'desktop';
    }
  }

  public isTabletOrNotebook() {
    return this.screen() === 'tablet' || this.screen() === 'notebook';
  }

  public isMobile(): boolean {
    return this.screen() === 'mobile';
  }

  public isDesktop(): boolean {
    return this.screen() === 'desktop';
  }

  public isDesktopOrNotebook(): boolean {
    return this.screen() === 'desktop' || this.screen() === 'notebook';
  }

  public isTablet(): boolean {
    return this.screen() === 'tablet';
  }
  //#endregion Tamanho da tela

  public compareString(stringA: string, stringB: string): number {
    let valueA = stringA.toLocaleUpperCase().normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');

    let valueB = stringB.toLocaleUpperCase().normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  }

  public formatStringToFilter(filter: string): string {
    return filter.trim().toLocaleLowerCase().normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  }

  public dateFormat(input: Date): string {

    const dia = String(input.getDate()).padStart(2, '0');
    const mes = String(input.getMonth() + 1).padStart(2, '0');
    const ano = String(input.getFullYear());

    return `${dia}/${mes}/${ano}`;
  }

  contactAppToString(app: ContactApp) {
    return ContactAppTypes[app].label;
  }

  isExemptRegisterState(event: KeyboardEvent, control?: AbstractControl | null): void {
    const key = event.key;
    if (['Backspace', 'Delete'].includes(key) && control?.value?.includes(ISENTA)) {
      control?.setValue("");
    }
    if (control?.value?.includes(ISENTA)) {
      event.preventDefault();
    }
  }

  isOnlyNumberRegisterState(event: KeyboardEvent, control?: AbstractControl | null): void {
    const key = event.key;
    if (key && isNaN(Number(key)) && key !== '.' && key !== '-' && key !== '/' && key !== 'P') {
      event.preventDefault();
    }
    if (key === 'P' && control?.value?.length > 0) {
      event.preventDefault();
    }
  }

  isOnlyNumber(event: KeyboardEvent): void {
    const key = event.key;

    if (key && isNaN(Number(key))) {
      event.preventDefault();
    }
  }

  generatedPartnerAffetic(): Partner {
    const aux = {} as Partner;
    aux.id = 0;
    aux.userId = 0;
    aux.fullName = 'Affetic'
    aux.corporateName = 'Affetic';
    return aux;
  }

  isElement(value: any): value is Element {
    return "elementType" in value;
  }

  removeMaskCep(cep: string): string {
    return cep.replace(/[.-]/g, '');
  }

  getPhoneMask(phone: string): string {
    return phone.length === 11 ? '(00) 00000-0000' : '(00) 0000-0000';
  }

  isUserClient(): boolean {
    const userCache = this.authService.getUserFromCache();
    return userCache?.groups.some((x) => x.name === 'CLIENT') ?? false;
  }

  convertDate(dataHora: string): string {
    const [data, tempo] = dataHora.split('T');
    const [ano, mes, dia] = data.split('-');
    let [horas, minutos, segundos] = tempo.includes('T') ? tempo.split('T')[1].split(':') : tempo.split(':');

    const novoFormato = `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;

    return novoFormato;
  }

  formatDate(date: Date | null): string {
    if (date) {
      return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss') || '';
    }
    return '';
  }

  public moveToTop() {
    window.scrollTo(0, 0);
  }

  removeInvisibleChars(value: string): string {
    const regex = /[\x00-\x1F\x7F-\x9F\uFEFF\u200B-\u200F\u2028\u2029]/g;
    return value ? value.replace(regex, '') : value;
  }
}
