import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { debounceTime, map } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MenuOptionsModel } from 'src/app/shared/components/menu-options/models/menu-options.model';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { ClientComboboxResponseDTO } from 'src/app/shared/domains/clients/client-combobox-response-dto';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

export interface FiltroBeneficiaryModel {
  isFired: boolean | null;
  filter: string;
  client: ClientComboboxResponseDTO;
}

@Component({
  selector: 'app-filtro-beneficiary',
  templateUrl: './filtro-beneficiary.component.html',
  styleUrls: ['./filtro-beneficiary.component.scss'],
})
export class FiltroBeneficiaryComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  showConfirmModal: boolean = false;

  clientSelectedFormControl = new FormControl();
  clearSelectedClient: boolean = true;
  canCreate = false;
  canDelete = true;
  canActive = false;
  canResetPass = false;

  form: FormGroup = this.formBuilder.group({
    filter: new FormControl({ value: '', disabled: true }),
    isFired: new FormControl({ value: null, disabled: true }),
  });

  searchPreviousValue = '';

  @Output()
  onFilter = new EventEmitter<FiltroBeneficiaryModel>();
  @Output()
  onClearFilter = new EventEmitter<void>();
  @Output()
  onEnableAll = new EventEmitter<void>();
  @Output()
  onDisableAll = new EventEmitter<void>();
  @Output()
  onResetPass = new EventEmitter<void>();
  @Output()
  onDeleteAll = new EventEmitter<void>();

  menuOptions: MenuOptionsModel[] = [];

  initMenuOptions() {
    this.menuOptions = [{
      menuAction: () => { this.enableAll() },
      menuIcon: 'toggle_on',
      menuTitle: 'Ativar',
      hasPermission: this.canActive
    },
    {
      menuAction: () => { this.disableAll() },
      menuIcon: 'toggle_off',
      menuTitle: 'Inativar',
      hasPermission: this.canActive
    },
    {
      menuAction: () => { this.resetPassWord() },
      menuIcon: 'key',
      menuTitle: 'Resetar senha',
      hasPermission: this.canResetPass
    },
    {
      menuAction: () => { this.deleteAll() },
      menuIcon: 'delete',
      menuTitle: 'Excluir',
      hasPermission: this.canDelete
    }]
  }

  constructor(
    private formBuilder: FormBuilder,
    public globalFunctions: GlobalFunctions,
    private permissionsService: NgxPermissionsService,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
    this.form
      .get('filter')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(
        map((value: string) => value?.trim().toLocaleLowerCase()),
        map((value: string) =>
          value?.trim().replace(/[.-]/g, '').toLocaleLowerCase()
        )
      )
      .subscribe((value) => {
        if (value || this.searchPreviousValue.trim() !== '') {
          this.searchPreviousValue = value;
          this.filter();
        }
      });

    this.form.get('isFired')?.valueChanges.subscribe((value) => {
      this.filter();
    });
  }

  async ngOnInit() {
    this.canCreate = await this.permissionsService.hasPermission(ProfilePermissions.CREATE_COLLABORATOR);
    this.canDelete = await this.permissionsService.hasPermission(ProfilePermissions.DELETE_COLLABORATOR);
    this.canActive = await this.permissionsService.hasPermission(ProfilePermissions.CHANGE_SITUATION_COLLABORATOR);
    this.canResetPass = await this.permissionsService.hasPermission(ProfilePermissions.RESET_PASSWORD);

    this.initMenuOptions();
  }

  changeModal(visible: boolean) {
    this.showConfirmModal = visible;
    this.ref.detectChanges();
  }

  onSelectClientEvent(event: ClientComboboxResponseDTO) {
    this.clearSelectedClient = false;
    this.clientSelectedFormControl.setValue(event);
    this.form.get('filter')?.enable();
    this.form.get('isFired')?.enable();
    this.filter();
  }

  filter() {
    const newFilter: FiltroBeneficiaryModel = {
      filter: this.form.get('filter')?.value,
      isFired: this.form.get('isFired')?.value,
      client: this.clientSelectedFormControl.value ?? null,
    };
    this.onFilter.emit(newFilter);
  }

  cleanFilter() {
    this.form.get('filter')?.setValue('');
    this.form.get('isFired')?.setValue(null);
    this.form.get('filter')?.disable();
    this.form.get('isFired')?.disable();
    this.clearSelectedClient = true;
    this.clientSelectedFormControl.setValue(null);
    this.onClearFilter.emit();
  }

  enableAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Ativar colaboradores', 'Deseja mesmo ativar os colaboradores selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          this.onEnableAll.emit();
        }
      });
  }

  disableAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Inativar colaboradores', 'Deseja mesmo inativar os colaboradores selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          this.onDisableAll.emit();
        }
      });
  }

  deleteAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Deseja mesmo excluir os colaboradores selecionados?', 'Caso confirme, essa ação não poderá ser desfeita.')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          this.onDeleteAll.emit();
        }
      });
  }

  resetPassWord() {
    this.changeModal(true);
    this.confirmModal?.showModal('Resetar senha', 'Deseja mesmo resetar senha dos colaboradores selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          this.onResetPass.emit();
        }
      });
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  newBeneficiary() {
    const extras: NavigationExtras = {
      queryParams: { clientId: this.clientSelectedFormControl.value.id }
    }
    this.redirectTo('/collaborators/new-collaborator', extras);
  }
}
