<mat-card *ngIf="globalFunctions.isMobile() && showResponsive" class="tooltip-responsive" [style]="styleResponsive"
    (click)="showResponsive = false;">
    <div [ngStyle]="{'white-space': 'pre-wrap'}">{{ text }}</div>
</mat-card>

<div class="ml-2">
    <mat-icon [pTooltip]="divHtmlText" [tooltipStyleClass]="tooltipStyleClass" [tooltipPosition]="tooltipPosition" [escape]="false"
    (click)="showResponsive = !showResponsive;">info_outline</mat-icon>
</div>

