export class Contact {
    id?: number;
    name: string;
    phone: string;
    contactApp?: string;
    role: string;
    email: string;
    type: string;

    constructor(name?:string, phone?:string, contactApp?:string, role?:string, email?:string, type?:string, ) {
        this.name = name || '';
        this.phone = phone || '';
        this.contactApp = contactApp || undefined;
        this.role = role || '';
        this.email = email || '';
        this.type = type || 'default';
    }

    static formatContactApp(app: string) {
      return app == 'WHATSAPP' ? 'WhatsApp' : 'Telegram';
    }
}
