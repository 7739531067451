import { Injectable } from '@angular/core';
import {
  CanActivate
} from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { ProfilePermissions } from '../constants/profile-permissions';

@Injectable({
  providedIn: 'root',
})
export class EventGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService
  ) { }

  canActivate(): boolean | Promise<boolean> {
    if (this.rolesService.getRole('CLIENT') || this.rolesService.getRole('ADMIN')) {
      return this.permissionsService.hasPermission(ProfilePermissions.READ_EVENTS);
    }

    return false;
  }
}
