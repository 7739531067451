import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { GroupUserClientCombobox, UserClientGroupResponse } from 'src/app/shared/domains/user-client-group/user-client-group';
import { UserClientGroupService } from 'src/app/shared/services/user-client-group/user-client-group.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
@Component({
  selector: 'app-user-group-modal',
  templateUrl: './user-group-modal.component.html',
  styleUrls: ['./user-group-modal.component.scss']
})
export class UserGroupModalComponent implements OnInit {

  @Input()
  visible: boolean = false;

  @Input()
  user?: { userId: number, userClientId: number };

  @Output()
  onClose = new EventEmitter<boolean>()

  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  displayedColumns: string[] = ['name', 'actions'];

  favoriteGroupControl = new FormControl(null);
  hover: number = -1;
  filteredGroupsDataSource = new MatTableDataSource<GroupUserClientCombobox>();

  get favoriteGroup(): number {
    return this.favoriteGroupControl.value;
  }

  async ngOnInit() {
    this._getGroups()
  }

  private async _getGroups() {
    const groups = await this.userClientGroupService.getAllByUserId(this.user?.userId ?? 0);
    this.filteredGroupsDataSource = new MatTableDataSource(groups);
    const favorite = groups.find(group => group.isFavorite);
    if (favorite) {
      this.favoriteGroupControl.setValue(favorite.id);
    }
  }

  constructor(public globalFunctions: GlobalFunctions, private userClientGroupService: UserClientGroupService, private toastComponent: ToastComponent) { }

  close() {
    this.onClose.emit()
  }

  onClickSetFavorite(groupId: number) {
    if (this.favoriteGroupControl.value === groupId) return;
    this.confirmModal
      ?.showModal(
        'Deseja mesmo selecionar esse grupo como favorito?'
      )
      .subscribe(async (isAccepted) => {
        if (isAccepted) {
          try {
            this.favoriteGroupControl.setValue(groupId);
            await this.userClientGroupService.setFavorite(groupId, this.user?.userClientId ?? 0);
            this.toastComponent.showSuccessCustomMessage('Sucesso!', 'Grupo selecionado como favorito com sucesso', 3000);
          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage(
                'Ops!',
                error.error.message
              );
            }
          }
        }
      })
  }
  onMouseLeave() {
    this.hover = -1;
  }

  onMouseEnter(groupId: number) {
    this.hover = groupId;
  }
}
