<div mat-dialog-title class="d-flex justify-content-between">
  <label class="titles title-2 semibold extended-secondary-darker">
    Clientes
  </label>
  <button mat-icon-button (click)="onNoClick()">
    <mat-icon class="icon-secondary-color material-icons-outlined">
      clear
    </mat-icon>
  </button>
</div>

<mat-dialog-content class="dialog-content" [ngClass]="globalFunctions.isMobile() ? 'content-height-mobile' : 'content-height'">
  <div class="row">
    <div class="col-12 col-sm-3">
      <mat-form-field appearance="outline" class="mat-input">
        <mat-icon matPrefix class="icon-search">search</mat-icon>
        <mat-label class="paragraph paragraph-2 mat-label">Pesquisar</mat-label>
        <input matInput [formControl]="searchFormControl" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="!globalFunctions.isMobile()">
        <table
          mat-table
          #dataImportTableSort="matSort"
          [dataSource]="dataSource"
          class="mat-elevation-z8 w-100"
          matSort
        >
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="column-10">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()">
              </mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let product"
              class="column-5 text-ellipsis"
            >
              <mat-checkbox
                (change)="$event ? selectItem(product) : null"
                [(ngModel)]="product.checked"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="column-30"
            >
              CNPJ
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-30">
              {{ dataImport.cnpj | mask : cnpjMask }}
            </td>
          </ng-container>

          <ng-container matColumnDef="corporateName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="column-55"
            >
              Razão Social
            </th>
            <td mat-cell *matCellDef="let dataImport" class="column-55">
              {{ dataImport.corporateName }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </div>
  </div>

  <div *ngIf="globalFunctions.isMobile()">
    <div class="checkAllContainer">
      <span> Marcar todos </span>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
      ></mat-checkbox>
    </div>
    <div class="mt-3 mb-3">
      <mat-divider></mat-divider>
    </div>

    <div>
      <div class="checkAllContainer mb-3" *ngFor="let itemS of currentListView">
        <span class="ellipsis-style nameContainer">
          {{ itemS.corporateName }}
        </span>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selectItem(itemS) : null"
          [checked]="selection.isSelected(itemS)"
          [(ngModel)]="itemS.checked"
        ></mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex" [ngClass]="!globalFunctions.isMobile() ? 'justify-content-end' : ' justify-content-center'">
  <div *ngIf="!globalFunctions.isMobile()" class="buttonContainer col-sm-3 d-flex">
    <button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"  (click)="onNoClick()">Cancelar</button>
    <button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="onSave()" [disabled]="!hasChange">Salvar</button>
  </div>
  <div *ngIf="globalFunctions.isMobile()" class="buttonContainerMobile">
    <button class="btn btn-primary subtitle subtitle-1 semibold white" (click)="onSave()" [disabled]="!hasChange">Salvar</button>
    <button class="btn btn-secondary subtitle subtitle-1 semibold primary-color" (click)="onNoClick()">Cancelar</button>
  </div>
</mat-dialog-actions>
