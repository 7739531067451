import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../domains/user';
import { lastValueFrom } from 'rxjs';
import { ToastComponent } from '../components/toaster/toast/toast.component';
import { InternalUserServiceInterface } from '../interfaces/internal-user-service.interface';
import { UserResumed } from '../domains/userResumed';
import { InternalUserDto, InternalUserPage } from '../domains/internal-user/internal-user-dto';

@Injectable({
  providedIn: 'root'
})
export class InternalUserService implements InternalUserServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
  ) {
    this.urlBase = environment.bff_web;
  }

  async getAllUsers(): Promise<Array<User>> {
    try {
      const userArray: Array<User> = await lastValueFrom(this.http.get<Array<User>>(this.urlBase + `/internal-users`));

      if (userArray) {
        return userArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllInternalUsersPage(page: number, size: number, search?: string): Promise<InternalUserPage> {
    try {
      const users: InternalUserPage = await lastValueFrom(this.http.get<InternalUserPage>(this.urlBase + `/internal-users/find-all-pageable`, {
        params: {
          page: page,
          size: size,
          search: search ? search : ''
        },
      })
      );

      if (users) {
        return users;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async saveUser(user: InternalUserDto): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/internal-users`, user, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async editUser(user: InternalUserDto, image?: File): Promise<boolean> {
    const formData = new FormData();
    formData.append(
      'internalUser',
      new Blob([JSON.stringify(user)], { type: 'application/json' })
    );

    if (image) {
      formData.append('image', image, image.name);
    }

    try {
      const success = await lastValueFrom(this.http.put(this.urlBase + `/internal-users`, formData, { observe: 'response' }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async delete(user: InternalUserDto): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/internal-users/${user.id}`, { observe: 'response' }));
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async getResumedUser(userId: number): Promise<UserResumed> {
    try {
      const user: UserResumed = await lastValueFrom(this.http.get<UserResumed>(this.urlBase + `/internal-users/user/${userId}`));

      if (user) {
        return user;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async changeSituationByGroupIds(ids: number[], situation: boolean): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('situation', situation);

    try {
      await lastValueFrom(this.http.put(this.urlBase + `/internal-users/change-situation?ids=${ids.join(',')}`, params));
      return true;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async deleteInternalUserByGroupId(ids: number[]): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/internal-users?ids=${ids.join(',')}`, {
          observe: 'response',
        })
      );

      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }
  async resetPasswords(ids: number[]): Promise<boolean> {
    try {
      await lastValueFrom(this.http.post(this.urlBase + `/internal-users/reset-password?userIds=${ids.join(',')}`, null));
      return true;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }
}
