import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ToastComponent } from '../components/toaster/toast/toast.component';
import { NetworkDTO, NetworkPage } from '../domains/network/network';
import { NetworkDetailsDTO } from '../domains/network/network-details';
import { NetworkInsert } from '../domains/network/network-insert';
import { NetworkServiceInterface } from '../interfaces/network-service.interface';


@Injectable({
  providedIn: 'root'
})
export class NetworkService implements NetworkServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) { this.urlBase = environment.bff_web + '/networks' }


  async delete(id: number): Promise<boolean> {
    return await lastValueFrom(this.http.delete<boolean>(this.urlBase + '/' + id).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async getComments(id: number): Promise<NetworkDetailsDTO> {
    try {
      const params = new HttpParams()
      .set('networkId', `${id}`)

      const comment = await lastValueFrom(
        this.http.get<NetworkDetailsDTO>(this.urlBase + `/comments`, {params})
      );
      return comment;
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
  }

  async getById(id: number): Promise<NetworkInsert> {
    try {
      const network = await lastValueFrom(
        this.http.get<NetworkInsert>(this.urlBase + `/${id}`)
      );
      return network;
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }
  }

  async save(network: FormData, attachments?: File[]): Promise<boolean> {

    if (attachments && attachments.length > 0) {
      attachments.forEach(file => {
        network.append('attachments', file, file.name);
      });
    }

    return await lastValueFrom(this.http.post<boolean>(this.urlBase, network, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async saveComment(comment: FormData, attachments?: File[]): Promise<boolean> {
    if (attachments && attachments.length > 0) {
      attachments.forEach(file => {
        comment.append('attachments', file, file.name);
      });
    }
    return await lastValueFrom(this.http.post<boolean>(this.urlBase + "/comment", comment, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));

  }

  async update(network: FormData, attachments?: File[]): Promise<boolean> {

    if (attachments && attachments.length > 0) {
      attachments.forEach(file => {
        network.append('attachments', file, file.name);
      });
    }
    return await lastValueFrom(this.http.put<boolean>(this.urlBase, network, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }


  async send(networkId: number): Promise<boolean> {

    return await lastValueFrom(this.http.put<boolean>(this.urlBase+ `/send/${networkId}`, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }


  async saveAndSend(network: FormData, attachments?: File[]): Promise<boolean> {

    if (attachments && attachments.length > 0) {
      attachments.forEach(file => {
        network.append('attachments', file, file.name);
      });
    }

    return await lastValueFrom(this.http.post<boolean>(this.urlBase + "/save-and-send", network, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async updateAndSend(network: FormData, attachments?: File[]): Promise<boolean> {

    if (attachments && attachments.length > 0) {
      attachments.forEach(file => {
        network.append('attachments', file, file.name);
      });
    }

    return await lastValueFrom(this.http.put<boolean>(this.urlBase + "/update-and-send", network, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async getFilteredNetworks({
    filter,
    pageSize,
    page,
    table
  }: any): Promise<NetworkPage> {
    try {
      const noticeArray: any = await lastValueFrom(
        this.http.get<Array<NetworkDTO>>(this.urlBase + `/list`, {
          params: {
            filter: filter,
            pageSize: pageSize,
            page: page,
            table: table
          },
        })
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
    }
    throw new Error();
  }
}
