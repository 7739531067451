import { mergeMap } from 'rxjs';
import { ToastComponent } from './../../components/toaster/toast/toast.component';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, lastValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../../domains/notification/notification';
import { NotificationsServiceInterface } from '../../interfaces/notifications-service.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceMock implements NotificationsServiceInterface {

    urlBase: string;

    constructor(private http: HttpClient, private toastComponent: ToastComponent) {
        this.urlBase = environment.bff_web;
    }

    deleteByIds(ids: string[]): Observable<boolean> {
      throw new Error('Method not implemented.');
    }

    getAll(): Observable<Notification[]> {
      return this.http.get<Array<Notification>>(this.urlBase + `/notifications`, { headers: { ignoreLoading: 'true'}}).pipe(
        catchError(() => of([]))
      );
    }

    async readNotification(notif: Notification): Promise<any> {
        const notification: Notification = await lastValueFrom(this.http.get<Notification>(this.urlBase + `/notifications/${notif.id}`));
        notification.isRead = true;
        this.http.put(this.urlBase + `/notfications/${notif.id}`, notification, { 'headers': { 'Content-Type': 'application/json', ignoreLoading: 'true'}});
    }

}
