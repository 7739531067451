import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { SearchTableDeleteDialogConfiguration, SituationDialogConfiguration } from 'src/app/shared/components/search/interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from 'src/app/shared/components/search/interfaces/search-table-select-configuration.interface';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { GroupResponseDTO } from 'src/app/shared/domains/GroupCollaborator/group-collaborator-dto';
import { BeneficiaryPage, BeneficiarySearchResponseDTO } from 'src/app/shared/domains/beneficiary/beneficiary-search-response';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { BeneficiaryService } from 'src/app/shared/services/beneficiary.service';
import { GroupCollaboratorService } from 'src/app/shared/services/group-collaborator.service';
import { ResponsabilityTermTypeWithLabels } from 'src/app/shared/types/responsability-term.type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { masks } from 'src/app/shared/utils/global-variables';
import { FiltroBeneficiaryModel } from '../filtro-beneficiary/filtro-beneficiary.component';

@Component({
  selector: 'app-beneficiary-search',
  templateUrl: './beneficiary-search.component.html',
  styleUrls: ['./beneficiary-search.component.scss']
})
export class BeneficiarySearchComponent implements OnInit {

  allGroups: GroupResponseDTO[] = [];
  showModalAssociateGroups: boolean = false;

  dataSource = new MatTableDataSource<any>();
  dataTableSort: MatSort | undefined;
  clientId: number | null = null;
  selectedClient = {} as ClientDTO;
  clientWithIntegration: boolean = false;
  allBeneficiaries: BeneficiarySearchResponseDTO[] = [];
  isSortingApplied: boolean = false;

  paginator: MatPaginator | undefined;
  totalElements!: number;
  pageLength!: number;
  pageIndex!: number;

  paginationCard: number = 10;

  canCreate = false;
  canDelete = true;
  canEdit = false;
  canActive = false;
  canResetPass = false;

  columns: SearchColumnConfiguration[] = [];

  filter: FiltroBeneficiaryModel = {isFired: null} as FiltroBeneficiaryModel

  selectClient: SearchTableSelectConfiguration = {
    labelValueName: "tradingName",
    data: [],
    placeholder: 'Cliente',
    disableAddButtonWhileNotSelect: true,
  }

  changeSituationDialogInfo: SituationDialogConfiguration = {
    description: '',
    onConfirmFn: this.changeSituation.bind(this),
    title: 'Deseja mesmo ',
    title2: ' este colaborador?',
    situationFieldName: 'situation'
  }

  dialogInfo: SearchTableDeleteDialogConfiguration = {
    onConfirmFn: this.confirmDeleteModal.bind(this),
    title: 'Deseja mesmo excluir este Colaborador?',
    description: 'Caso confirme, essa ação não poderá ser desfeita.'
  };

  constructor(
    private toastComponent: ToastComponent,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    public globalFunctions: GlobalFunctions,
    private beneficiariesService: BeneficiaryService,
    private groupService: GroupCollaboratorService,
    private ref: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  initColumns() {
    this.columns = [
      {
        columnName: '',
        valueName: 'checkbox',
        columnValueCssClass: 'column-10',
        isCheckbox: true
      },
      {
        columnName: 'Matrícula',
        valueName: 'employeeEnrollment',
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        columnName: 'CPF',
        valueName: 'cpf',
        mask: masks.cpfMask,
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },

      {
        columnName: 'Nome completo',
        valueName: 'fullName',
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        columnName: 'E-mail',
        valueName: 'preferredEmail',
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        columnName: 'Cargo',
        valueName: 'role',
        columnValueCssClass: 'column-5 column-category-20 truncate-words',
      },
      {
        columnName: 'Política de Privacidade',
        valueName: 'statusTypeLabel',
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        columnName: 'Demitido',
        valueName: 'isFiredLabel',
        columnValueCssClass: 'column-5 column-category-20 truncate-words',
      },
      {
        columnName: 'Data de desligamento',
        valueName: 'firedAt',
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        columnName: 'Situação',
        valueName: 'situation',
        toggleConfiguration: {
          valueName: 'situation'
        },
        columnValueCssClass: 'column-10 column-category-20 truncate-words',
      },
      {
        actionsConfiguration: {
          useEditButton: this.canEdit,
          useViewButton: !this.canEdit,
          replaceEditWithView: 'isFired',
          useDeleteFeature: true,
          extraActions: [
            {
              iconName: 'group',
              tooltip: 'Visualizar grupos',
              onClick: this.openGroupModal.bind(this),
              countAtIconSide: 'countComments',
              css: 'margin-right: 8px;'
            }
          ]
        },
        columnName: '',
        valueName: 'actions',
        columnValueCssClass: 'p-0 d-flex justify-content-end',
      },
    ];
  }

  async ngOnInit() {
    this.canCreate = await this.permissionsService.hasPermission(ProfilePermissions.CREATE_COLLABORATOR);
    this.canDelete = await this.permissionsService.hasPermission(ProfilePermissions.DELETE_COLLABORATOR);
    this.canEdit = await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_COLLABORATOR);
    this.canActive = await this.permissionsService.hasPermission(ProfilePermissions.CHANGE_SITUATION_COLLABORATOR);
    this.canResetPass = await this.permissionsService.hasPermission(ProfilePermissions.RESET_PASSWORD);

    this.initColumns();
  }

  onFilter(filter: FiltroBeneficiaryModel) {
    this.filter = filter
    this.getBeneficiaries();
  }

  clearFilter(){
    this.filter = {} as FiltroBeneficiaryModel
    this.getBeneficiaries()
  }

  private configTable() {
    if (this.dataTableSort) {
      this.dataSource.sort = this.dataTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {

        if (property == 'situation') {
          return item['situation'] ? 'ativo' : 'inativo';
        }
        return item[property]?.toLowerCase();
      };
    }
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToNewBeneficiary() {
    const extras: NavigationExtras = {
      queryParams: { clientId: this.selectedClient.id }
    }
    this.redirectTo('/collaborators/new-collaborator', extras);
  }

  redirectToEditBeneficiary(beneficiary: BeneficiarySearchResponseDTO) {
    const extras: NavigationExtras = {
      queryParams: { id: beneficiary.id, clientId: this.selectedClient.id }
    }
    this.redirectTo('/collaborators/edit-collaborator', extras);
  }

  redirectToViewBeneficiary(beneficiary: BeneficiarySearchResponseDTO) {
    const extras: NavigationExtras = {
      queryParams: { id: beneficiary.id, clientId: this.selectedClient.id }
    }
    this.redirectTo('/collaborators/view-collaborator', extras);
  }

  async confirmDeleteModal(beneficiary: BeneficiarySearchResponseDTO) {
    try {
      const success = await this.beneficiariesService.delete(beneficiary.id);
      if (success) {
        this.toastComponent.showSuccessCustomMessage(`Colaborador excluído com sucesso`, '', 3000);
        this.getBeneficiaries();
      }
    } catch (error: any) {
      if (error.status == 422) {
        this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
      }
    }
  }

  async changeSituation(beneficiary: BeneficiarySearchResponseDTO) {
    let success = false;
    
    if(beneficiary.id) {
      try {
        success = await this.beneficiariesService.changeSituation(beneficiary.id, beneficiary.situation);
      } catch(error) {
        success = false;
      }
    }

    if (success) {
      this.toastComponent.showSuccessCustomMessage(`Colaborador ${beneficiary.situation ? 'ativado' : 'desativado'} com sucesso`, '', 3000);
    } else {
      beneficiary.situation = !beneficiary.situation;
    }
  }

  getResponsabilityTerm(status: string): string | undefined {
    return ResponsabilityTermTypeWithLabels.find((p) => p.value.toLowerCase() === status.toLowerCase())?.label;
  }

  async enableAll() {
    try {
      const idsList = this.dataSource.data
        .filter(x => x.checkbox === true)
        .map(x => x.id);
      
      if(idsList.length === 0) {
        this.toastComponent.showWarningCustomMessage('Selecione pelo menos um colaborador');
        return;
      }

      const success = await this.beneficiariesService.changeSituationByGroupIds(idsList, true);
      if (success) {
        this.toastComponent.showSuccessCustomMessage(`Situação alterada com sucesso`, '', 3000);
        this.getBeneficiaries();
      }
    } catch (error: any) {
      if (error.status == 422) {
        this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
      }
    }
  }

  async disableAll() {
    try {
      const idsList = this.dataSource.data
        .filter(x => x.checkbox === true)
        .map(x => x.id);
      
      if(idsList.length === 0) {
        this.toastComponent.showWarningCustomMessage('Selecione pelo menos um colaborador');
        return;
      }

      const success = await this.beneficiariesService.changeSituationByGroupIds(idsList, false);
      if (success) {
        this.toastComponent.showSuccessCustomMessage(`Situação alterada com sucesso`, '', 3000);
        this.getBeneficiaries();
      }
    } catch (error: any) {
      if (error.status == 422) {
        this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
      }
    }
  }

  async deleteAll() {
    try {
      const idsList = this.dataSource.data
        .filter(x => x.checkbox === true)
        .map(x => x.id);

      if(idsList.length === 0) {
        this.toastComponent.showWarningCustomMessage('Selecione pelo menos um colaborador');
        return;
      }
      const success = await this.beneficiariesService.deleteBeneficiariesByGroupId(idsList);
      if (success) {
        this.toastComponent.showSuccessCustomMessage(`Colaboradores excluídos com sucesso`, '', 3000);
        this.getBeneficiaries();
      }

    } catch (error: any) {
      if (error.status == 422) {
        this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
      }
    }
  }

  async resetPassWord() {
    try {
      const ids = this.dataSource.data.filter(x => x.checkbox == true).map(x => {
        return x.idUser;
      });

      if(ids.length === 0) {
        this.toastComponent.showWarningCustomMessage('Selecione pelo menos um colaborador');
        return;
      }

      const success = await this.beneficiariesService.resetPasswords(ids);

      if (success) {
        this.toastComponent.showSuccessCustomMessage(`Senha resetada com sucesso`, '', 3000);
        this.getBeneficiaries();
      }

    } catch (error: any) {
      if (error.status == 422) {
        this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
      }
    }
  }

  openGroupModal(beneficiaryId: BeneficiarySearchResponseDTO) {
    this.groupService.findByBeneficiaryId(beneficiaryId.id).then((data) => {
      this.allGroups = data;
      this.showModalAssociateGroups = true;
    })
  }

  closeModalAssociateGroups() {
    this.allGroups = [];
    this.showModalAssociateGroups = false;
  }

  async onPageChange(event: PageEvent) {
    const page = event.pageIndex + 0;
    const pageSize = event.pageSize;
    this.pageLength = event.pageSize;

    if (page == 0) this.pageIndex = 0;

    this.getFilteredBeneficiary(
      pageSize,
      page
    );
  }

  sortingTable = (data: Sort) => {
    if (data.direction !== '') {
      this.sortingDataAccessorMethod();
    } else {
      this.dataSource = new MatTableDataSource(this.mapperBeneficiary(this.allBeneficiaries));
    }
  };

  sortingDataAccessorMethod = () => {
    this.isSortingApplied = true;
    this.dataSource = new MatTableDataSource(this.mapperBeneficiary(this.allBeneficiaries));
    this.dataSource.sortingDataAccessor = (item, property) => {
      const value = item[property];

      if (!isNaN(value) && typeof value !== 'boolean') {
        return value;
      }

      if (property == 'statusTypeLabel') {
        return item['statusTypeLabel'];
      }

      return String(value).toLowerCase();
    };
  };

  async getFilteredBeneficiary(pageSize: number, page: number) {
    try {
      let params = {
        clientId: [this.selectedClient.id],
        filter: [this.filter.filter],
        isFired: this.filter.isFired,
        pageSize,
        page
      };

      const data = await this.beneficiariesService.getFilteredBeneficiaryByClient(params);
      this.afterLoadConfig(data);
    } catch (err) {
      console.error(err);
    }
  }

  async getBeneficiaries() {
    this.clientWithIntegration = this.filter.client.allowDataReception
    this.selectedClient.id = this.filter.client.id;
    this.selectedClient.tradingName = this.filter.client.name;

    this.getFilteredBeneficiary(10, 0);
  }

  afterLoadConfig(data: BeneficiaryPage) {
    this.allBeneficiaries = data.content;
    this.dataSource = new MatTableDataSource(this.mapperBeneficiary(data.content));
    this.dataSource.paginator?.firstPage();
    this.pageLength = data.size;
    this.totalElements = data.totalElements;
    this.pageIndex = data.number;
    this.configTable();
  }

  private mapperBeneficiary(data: BeneficiarySearchResponseDTO[]): BeneficiarySearchResponseDTO[] {
    return data.map(
      (b) =>
      ({
        ...b,
        statusTypeLabel: this.getResponsabilityTerm(b.reponsabilityTermStatus),
        isFiredLabel: b.isFired ? 'Sim' : 'Não',
        firedAt: this.formatTimestamp(b.firedAt)
      } as BeneficiarySearchResponseDTO)
    );
  }

  formatTimestamp(timestamp: string | null): string {
    if (timestamp) {
      return this.datePipe.transform(timestamp, 'dd/MM/yyyy') || '';
    } else {
      return '';
    }
  }


  getBeneficiaryMobileVision(): void {
    this.paginationCard += 10;
    this.getFilteredBeneficiary(this.paginationCard, 0);
  }
}
