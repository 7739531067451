<p-dialog [(visible)]="visible" [style]="{width: '800px', height: '350px'}" class="medium-l" [modal]="true"
    [draggable]="false" [resizable]="false" [ngClass]="{'medium-mobile': globalFunctions.isMobile()}"
    (onHide)="close()">
    <ng-template pTemplate="header" class="mt-3 py-0">
        <span class="pl-2 subtitle subtitle-2 mt-2">
            <b>
                Grupos associados
            </b>
        </span>
    </ng-template>
    <div class="row">
        <div class="col-12">
            <table mat-table [dataSource]="filteredGroupsDataSource" class="mat-elevation-z8"
                style="border-bottom: none !important" w-100 matSort>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef
                        class="column-90 semibold extended-secondary-darker header-content">
                        Nome
                    </th>
                    <td mat-cell *matCellDef="let group" class="column-90">
                        <div class="d-flex justify-content-between pr-5 column-category truncate-words">
                            <label for="">
                                {{ group.name }}
                            </label>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="column-10 header-content">
                        Favorito
                    </th>
                    <td mat-cell *matCellDef="let group" class="column-10">
                        <div style="cursor: pointer;">
                            <mat-icon (mouseleave)="onMouseLeave()" (click)="onClickSetFavorite(group.id)"
                                *ngIf="favoriteGroup === group.id || hover == group.id; else emptyStar"
                                class="icon-secondary-color">star</mat-icon>
                            <ng-template #emptyStar>
                                <mat-icon (mouseenter)="onMouseEnter(group.id)" class="icon-secondary-color"
                                    (click)="onClickSetFavorite(group.id)">star_border</mat-icon>
                            </ng-template>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</p-dialog>

<app-confirm-modal #confirmModal></app-confirm-modal>