import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMaskDate]'
})
export class MaskDateDirective {
  constructor(private el: ElementRef<HTMLInputElement>) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'Delete'];
    if (
      allowedKeys.includes(event.key) ||
      (event.key === '/' && this.el.nativeElement.value.indexOf('/') === -1) ||
      (event.ctrlKey || event.metaKey)
    ) {
      return;
    }

    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const value = this.el.nativeElement.value;
    const numberRegEx = /^[0-9/]+$/;

    if (!numberRegEx.test(value) || value.length > 10) {
      this.el.nativeElement.value = '';
      event.preventDefault();
    } else if ([2, 5].includes(value.length) && event.key !== 'Backspace') {
      this.el.nativeElement.value = `${value}/`;
    }
  }
}
