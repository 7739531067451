import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ToastComponent } from '../../components/toaster/toast/toast.component';
import { Beneficiary } from '../../domains/beneficiary/beneficiary';
import { DataImportServiceInterface } from '../../interfaces/data-import-service.interface';
import { DataImport } from '../../domains/data-import/data-import';

@Injectable({
  providedIn: 'root',
})
export class DataImportServiceMock implements DataImportServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent      
  ) {
    this.urlBase = environment.bff_web;
  }
  async importFile(file: Blob): Promise<DataImport | null> {
    try {
      const formData: FormData = new FormData();
      formData.append('file', file);

      const result = await lastValueFrom(
        this.http.post<DataImport>(this.urlBase + `/client/import-file`, formData)
      );
      if (result) {
        return result;
      }
    } catch (error) {
      console.error(error);
      return null;
    }

    throw new Error();
  }
  async getBlob(url: string): Promise<Blob> {
    try {
      const result = await lastValueFrom(
        this.http.get(
          url,
          {
            responseType: 'blob',
          }
        )
      );

      if (result) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAll(): Promise<Array<Beneficiary>> {
    try {
      const beneficiaries: Array<Beneficiary> = await lastValueFrom(
        this.http.get<Array<Beneficiary>>(this.urlBase + `/beneficiaries`)
      );
      return beneficiaries;
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getByClients(): Promise<Array<Beneficiary>> {
    try {
      const beneficiaries: Array<Beneficiary> = await lastValueFrom(
        this.http.get<Array<Beneficiary>>(this.urlBase + `/beneficiaries`)
      );

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
