import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ToastComponent } from '../components/toaster/toast/toast.component';
import { DataImport } from '../domains/data-import/data-import';

@Injectable({
  providedIn: 'root',
})
export class DataImportService {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }
  async importFile(clienteId: number, userName: string, file: Blob): Promise<DataImport | null> {
    try {

      const formData: FormData = new FormData();
      formData.append('username', userName);
      formData.append('document', file);

      const result = await lastValueFrom(
        this.http.post<DataImport>(this.urlBase + `/import/${clienteId}/save`, formData)
      );
      if (result) {
        return result;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      return null;
    }

    throw new Error();
  }
  async getBlob(url: string): Promise<Blob> {
    try {
      const result = await lastValueFrom(
        this.http.get(this.urlBase + `/files/download?path=${url}`, {
          responseType: 'blob',
        })
      );

      if (result) {
        return result;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getImage(url: string): Promise<Blob> {
    try {
      const result = await lastValueFrom(
        this.http.get(this.urlBase + `/files/download-image?path=${url}`, {
          responseType: 'blob',
        })
      );

      if (result) {
        return result;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }

  async getByClients(filter?: Optional): Promise<Array<DataImport>> {
    try {
      const beneficiaries: Array<DataImport> = await lastValueFrom(
        this.http.get<Array<DataImport>>(this.urlBase + `/import/${filter}/all`)
      );

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
    }
    throw new Error();
  }
}
