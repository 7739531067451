import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, from, lastValueFrom, map, mergeMap, Observable, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastComponent } from "../../components/toaster/toast/toast.component";
import { Beneficiary } from "../../domains/beneficiary/beneficiary";
import { BeneficiaryServiceInterface } from "../../interfaces/beneficiary-service.interface";
import { ResumedBeneficiaryDTO } from "../../domains/beneficiary/resumed-beneficiary.interface";
import { BeneficiaryResumedDTO } from "../../domains/beneficiary/beneficiary-resumed";

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryServiceMock implements BeneficiaryServiceInterface {

  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,)
   {
    this.urlBase = environment.bff_web;
  }

  async getAll(): Promise<Array<Beneficiary>> {
    try {
      const beneficiaries: Array<Beneficiary> = await lastValueFrom(this.http.get<Array<Beneficiary>>(this.urlBase+`/beneficiaries`));
      return beneficiaries;

    } catch(error) {
      console.error(error);
    }
    throw new Error();
  }

  async getByClients(): Promise<Array<Beneficiary>> {
    try {
      const beneficiaries: Array<Beneficiary> = await lastValueFrom(this.http.get<Array<Beneficiary>>(this.urlBase + `/beneficiaries`));

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getNamesByClient(id?: number): Promise<Array<BeneficiaryResumedDTO>> {
    try {
      const beneficiaries: Array<BeneficiaryResumedDTO> = await lastValueFrom(
        this.http.get<Array<BeneficiaryResumedDTO>>(
          this.urlBase + `/beneficiaries `
        )
      );

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }


  async save(beneficiary: Beneficiary, imgs?: File): Promise<boolean> {
    try {
      const response = await lastValueFrom(this.http.post(this.urlBase + `/beneficiaries`, beneficiary, { 'headers': { 'Content-Type': 'application/json' }}));

      if (response) {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  update(beneficiary: Beneficiary, imgs?: File): Observable<boolean> {
    return this.http.put(this.urlBase + `/beneficiaries/` + beneficiary.id, beneficiary, { observe: 'response' }).pipe(
      catchError(() => of(false)),
      map(() => true)
    )
  }

  async delete(id: number): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.http.delete(this.urlBase + `/beneficiaries/${id}`));

      if(result){
        return true;
      }

    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async findById(id: number): Promise<Beneficiary> {
    try {
      const beneficiary = await lastValueFrom(
        this.http.get<Beneficiary>(this.urlBase + `/beneficiaries/${id}`));
      return beneficiary;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

 
}
