import { User } from "../user";
import { Role } from "./role";

export class ProfileGeneralData {
  users: User[];
  roles: Role[];

  constructor() {
    this.users = [];
    this.roles = [];
  }
}