import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ToasterModule } from 'src/app/shared/components/toaster/toast/toast.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoticeViewModalModule } from './shared/components/notice-view-modal/notifications.module';
import { NotificationsModule } from './shared/components/notifications/notifications.module';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { ErrorInterceptor } from './shared/interceptors/error-interceptor';
import { LoadingInterceptor } from './shared/interceptors/loading-interceptor';
import { RefreshTokenInterceptor } from './shared/interceptors/refresh-token-interceptor';
import { AuthServiceMock } from './shared/mock/services/auth.service';
import { BeneficiaryServiceMock } from './shared/mock/services/beneficiary.service';
import { BenefitsServiceMock } from './shared/mock/services/benefits.service';
import { ClientServiceMock } from './shared/mock/services/client.service';
import { ConfigIntegrationServiceMock } from './shared/mock/services/config-integration.service';
import { DataBridgeServiceMock } from './shared/mock/services/data-bridge.service';
import { DataImportServiceMock } from './shared/mock/services/data-import.service';
import { InternalUserServiceMock } from './shared/mock/services/internal-user.service';
import { NetworkServiceMock } from './shared/mock/services/network.service';
import { NotificationServiceMock } from './shared/mock/services/notification.service';
import { PartnerServiceMock } from './shared/mock/services/partner.service';
import { PasswordServiceMock } from './shared/mock/services/password.service';
import { ProfileServiceMock } from './shared/mock/services/profile.service';
import { UserClientServiceMock } from './shared/mock/services/user-client.service';
import { UserServiceMock } from './shared/mock/services/user.service';
import { AuthService } from './shared/services/auth.service';
import { BeneficiaryService } from './shared/services/beneficiary.service';
import { BenefitsService } from './shared/services/benefits.service';
import { ClientService } from './shared/services/client.service';
import { ConfigIntegrationService } from './shared/services/config-integration.service';
import { DataBridgeService } from './shared/services/data-bridge.service';
import { DataImportService } from './shared/services/data-import.service';
import { InternalUserService } from './shared/services/internal-user.service';
import { LoadingService } from './shared/services/loading.service';
import { NetworkService } from './shared/services/network.service';
import { NotificationService } from './shared/services/notification.service';
import { PartnerService } from './shared/services/partner.service';
import { PasswordService } from './shared/services/password.service';
import { ProfileService } from './shared/services/profile.service';
import { UserClientService } from './shared/services/user-client.service';
import { UserService } from './shared/services/user.service';
import { CustomPaginatorIntl } from './shared/utils/custom-language-paginator';
import { CustomDateAdapter } from './shared/utils/datepicker-adapter';
import { MY_DATE_FORMATS } from './shared/utils/global-variables';
import { PersonaSelectModule } from './shared/components/persona-select/persona-select.module';
import { DashBoardService } from './shared/services/dashboard.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatCardModule,
    ToasterModule,
    DialogModule,
    NotificationsModule,
    NoticeViewModalModule,
    PersonaSelectModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    LoadingService,
    ConfirmationService,
    DatePipe,
    {
      provide: AuthService,
      useClass: environment.mock ? AuthServiceMock : AuthService,
    },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    {
      provide: UserService,
      useClass: environment.mock ? UserServiceMock : UserService,
    },
    {
      provide: InternalUserService,
      useClass: environment.mock
        ? InternalUserServiceMock
        : InternalUserService,
    },
    {
      provide: PasswordService,
      useClass: environment.mock ? PasswordServiceMock : PasswordService,
    },
    {
      provide: ClientService,
      useClass: environment.mock ? ClientServiceMock : ClientService,
    },
    {
      provide: DataBridgeService,
      useClass: environment.mock ? DataBridgeServiceMock : DataBridgeService,
    },
    {
      provide: BeneficiaryService,
      useClass: environment.mock ? BeneficiaryServiceMock : BeneficiaryService,
    },
    {
      provide: BenefitsService,
      useClass: environment.mock ? BenefitsServiceMock : BenefitsService,
    },
    {
      provide: PartnerService,
      useClass: environment.mock ? PartnerServiceMock : PartnerService,
    },
    {
      provide: UserClientService,
      useClass: environment.mock ? UserClientServiceMock : UserClientService,
    },
    {
      provide: NotificationService,
      useClass: environment.mock
        ? NotificationServiceMock
        : NotificationService,
    },
    {
      provide: ProfileService,
      useClass: environment.mock ? ProfileServiceMock : ProfileService,
    },
    {
      provide: DataImportService,
      useClass: environment.mock ? DataImportServiceMock : DataImportService,
    },
    {
      provide: ConfigIntegrationService,
      useClass: environment.mock
        ? ConfigIntegrationServiceMock
        : ConfigIntegrationService,
    },
    {
      provide: NetworkService,
      useClass: environment.mock ? NetworkServiceMock : NetworkService,
    },
    {
      provide: DashBoardService,
      useClass: DashBoardService
    },
    //HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {
        suffix: '',
        align: 'left',
        prefix: '',
        decimal: ',',
        thousands: '.',
        precision: 3,
        allowNegative: false,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
