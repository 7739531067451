import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ToastComponent } from '../components/toaster/toast/toast.component';
import { Notice, NoticePage, NoticesCentralUsersPaginator, ViewedNotices } from '../domains/notice';
import { NoticeServiceInterface, paginatedProps } from '../interfaces/notice-service.interface';

@Injectable({
  providedIn: 'root',
})
export class NoticeService implements NoticeServiceInterface {
  urlBase: string;

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }

  async getNotice(id: number): Promise<Notice> {
    try {
      const product: Notice = await lastValueFrom(
        this.http.get<Notice>(this.urlBase + `/notices/${id}`)
      );

      if (product) {
        return product;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getClientNotice(id: number): Promise<Notice> {
    try {
      const product: Notice = await lastValueFrom(
        this.http.get<Notice>(this.urlBase + `/notices-client/${id}`)
      );

      if (product) {
        return product;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllNotices({
    page,
    size,
    sort,
    name,
  }: paginatedProps): Promise<NoticePage> {
    try {
      const noticeArray: NoticePage = await lastValueFrom(
        this.http.get<NoticePage>(this.urlBase + `/notices`, {
          params: {
            page: page,
            size: size,
            sort: sort,
            name: name,
          },
        })
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllClientNotices({
    page,
    size,
    sort,
    name,
    clientId,
  }: paginatedProps): Promise<NoticePage> {
    try {
      const noticeArray: any = await lastValueFrom(
        this.http.get<NoticePage>(this.urlBase + `/notices-client`, {
          params: {
            page: page,
            size: size,
            sort: sort,
            name: name,
            clientId: clientId ?? '',
          },
        })
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getAllAvaibleNotices(): Promise<Array<Notice>> {
    try {
      const noticeArray: Array<Notice> = await lastValueFrom(
        this.http.get<Array<Notice>>(
          this.urlBase +
            `/notices/available`
        )
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/notices/${id}`, {
          observe: 'response',
        })
      );
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async deleteClientNotice(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(
        this.http.delete(this.urlBase + `/notices-client/${id}`, {
          observe: 'response',
        })
      );
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async registerNotice(notice: Notice, image?: File): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'notice',
        new Blob([JSON.stringify(notice)], { type: 'application/json' })
      );

      if (image) {
        // const imageBlob = await this.fileToBlob(image);
        formData.append('image', image);
      }
      const success = await lastValueFrom(
        this.http.post(this.urlBase + '/notices', formData)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }

    return false;
  }

  async registerClientNotice(notice: Notice, image?: File): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'notice',
        new Blob([JSON.stringify(notice)], { type: 'application/json' })
      );

      if (image) {
        // const imageBlob = await this.fileToBlob(image);
        formData.append('image', image);
      }

      const success = await lastValueFrom(
        this.http.post(this.urlBase + '/notices-client', formData)
      );

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }

    return false;
  }

  async edit(notice: Notice, image?: File): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'notice',
        new Blob([JSON.stringify(notice)], { type: 'application/json' })
      );

      if (image) {
        // const imageBlob = await this.fileToBlob(image);
        formData.append('image', image);
      }

      const result = await lastValueFrom(
        this.http.put(this.urlBase + `/notices/${notice.id}`, formData)
      );

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  async editClientNotice(notice: Notice, image?: File): Promise<boolean> {
    try {
      const formData = new FormData();

      formData.append(
        'notice',
        new Blob([JSON.stringify(notice)], { type: 'application/json' })
      );

      if (image) {
        // const imageBlob = await this.fileToBlob(image);
        formData.append('image', image);
      }

      const result = await lastValueFrom(
        this.http.put(this.urlBase + `/notices-client/${notice.id}`, formData)
      );

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      throw new Error();
    }

    return false;
  }

  // Função para converter um objeto File em um Blob
  private fileToBlob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const blob = new Blob([reader.result as ArrayBuffer], {
          type: file.type,
        });
        resolve(blob);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  async sendViewedNotices(notice: ViewedNotices): Promise<boolean> {
    try {
      const success = await lastValueFrom(
        this.http.post(this.urlBase + '/notices-central', notice)
      );

      if (success) return true;

    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }

    return false;
  }

  async findUsersByNoticeId(noticeId: number, {filter, page, pageSize}: any): Promise<NoticesCentralUsersPaginator> {
    try {
      const noticeArray: any = await lastValueFrom(
        this.http.get<NoticePage>(this.urlBase + `/notices-central/notice/${noticeId}/users`, {
          params: {
            filter: filter,
            page: page,
            pageSize: pageSize
          },
        })
      );

      if (noticeArray) {
        return noticeArray;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async getNumberOfUsersViewedNotice(noticeId: number): Promise<number> {
    try {
      const numberOfUsers: number = await lastValueFrom(
        this.http.get<number>(this.urlBase + `/notices-central/notice/${noticeId}/viewers/count`)
      );

      if (numberOfUsers || numberOfUsers == 0) {
        return numberOfUsers;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
