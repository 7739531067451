import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ConfigIntegration } from '../../domains/config-integration/config-integration';
import { ToastComponent } from '../../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ConfigIntegrationServiceMock {
  urlBase: string;

  constructor(
    private http: HttpClient, private toastComponent: ToastComponent
  ) {
    this.urlBase = environment.bff_web;
  }
  async save(request: ConfigIntegration): Promise<boolean> {
    return await lastValueFrom(this.http.post<boolean>(this.urlBase + `/config-integration`, request, { observe: 'response' }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false);
      })
    ));
  }

  async getByClient(id: number): Promise<ConfigIntegration> {
    try {
      const configuration: ConfigIntegration = await lastValueFrom(
        this.http.get<ConfigIntegration>(
          this.urlBase + `/config-integration/${id}`
        )
      );

      if (configuration) {
        return configuration;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }
}
