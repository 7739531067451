export type Ethnicity = 'UNINFORMED' | 'WHITE' |'BLACK' | 'BROWN' | 'YELLOW' | 'INDIGENOUS' | 'OTHERS';

const Types = {
  UNINFORMED: { label: 'Não informado', value: 'UNINFORMED' },
  WHITE: { label: 'Branca', value: 'WHITE' },
  BLACK: {  label: 'Preta/Negra', value: 'BLACK' },
  BROWN: { label: 'Parda', value: 'BROWN' },
  YELLOW: { label: 'Amarela', value: 'YELLOW' },
  INDIGENOUS: { label: 'Indígena', value: 'INDIGENOUS' },
  OTHERS: { label: 'Outros', value: 'OTHERS' }
}

export const EthnicityWithLabels = Object.values(Types);
