export type MaritalStatus = 'UNMARRIED' | 'MARRIED' |'WIDOWER' | 'SEPARATED' | 'DIVORCED' | 'OTHERS';

const Types = {
  UNMARRIED: { label: 'Solteiro (a)', value: 'UNMARRIED' },
  MARRIED: { label: 'Casado (a)', value: 'MARRIED' },
  WIDOWER: {  label: 'Viúvo (a)', value: 'WIDOWER' },
  SEPARATED: { label: 'Separado (a)', value: 'SEPARATED' },
  DIVORCED: { label: 'Divorciado (a)', value: 'DIVORCED' },
  OTHERS: { label: 'Outros', value: 'OTHERS' }
}

export const MaritalStatusesWithLabels = Object.values(Types);
