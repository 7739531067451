import { ToastComponent } from './../components/toaster/toast/toast.component';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationsServiceInterface } from '../interfaces/notifications-service.interface';
import { Observable, catchError, lastValueFrom, map, of } from 'rxjs';
import { Notification } from '../domains/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements NotificationsServiceInterface {

  urlBase: string;

  constructor(private http: HttpClient, private toastComponent: ToastComponent) {
      this.urlBase = environment.bff_web + '/notifications';
  }

  deleteByIds(ids: string[]): Observable<boolean> {
    return this.http.delete(this.urlBase, { params: { ids: ids.join(',') } }).pipe(
      map(() => true),
      catchError(e => {
        this.toastComponent.showApiError(e);
        return of(false)
      }),
    );
  }

  getAll(): Observable<Notification[]> {
    return this.http.get<Array<Notification>>(this.urlBase, { headers: { ignoreLoading: 'true'}}).pipe(
      catchError(() => of([]))
    );
  }

  readNotification(notification: Notification): Promise<any> {
    return lastValueFrom(this.http.put(`${this.urlBase}/${notification.id}`, notification , { headers: { ignoreLoading: 'true' }}));
  }
}
