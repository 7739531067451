import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CacheService } from '../services/cache.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cacheService: CacheService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    if (this.authService.getToken()) {
      authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${this.authService.getToken()}` },
      });
    }

    return next.handle(authReq).pipe(
      catchError((err: HttpErrorResponse): Observable<HttpEvent<any>> => {
        if (err.status === 401 && this.authService.getToken()) {
          if (this.authService.updatingToken) {
            this.cacheService.refreshShouldPerformAction();
            // se já estiver atualizando o token, aguarda a atualização terminar antes de tentar novamente
            return this.authService.tokenUpdated.pipe(
              switchMap(() => {
                // reenvia a solicitação original com o novo token
                const authReq = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${this.authService.getToken()}`,
                  },
                });
                return next.handle(authReq);
              })
            );
          } else {
            // se não estiver atualizando o token, tenta atualizar o token
            this.authService.updatingToken = true;

            // limite o número de tentativas de atualização do token
            const retryCount = 3;
            const token = this.authService.getToken();

            if (token)
              return this.authService.refreshToken().pipe(
                switchMap(() => {
                  this.authService.updatingToken = false;
                  // reenvia a solicitação original com o novo token
                  const authReq = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${this.authService.getToken()}`,
                    },
                  });
                  return next.handle(authReq);
                }),
                catchError(() => {
                  this.authService.updatingToken = false;
                  // logout ou redireciona para a página de login
                  this.authService.logout();
                  this.router.navigate(['/login']);
                  return throwError(() => new Error());
                }),
                retry(retryCount)
              );
          }
        }
        return throwError(() => err);
      })
    );
  }
}
