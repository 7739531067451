import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../domains/user';
import { UserServiceInterface } from '../interfaces/user-service.interface';
import { lastValueFrom } from 'rxjs';
import { ToastComponent } from '../components/toaster/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class UserService implements UserServiceInterface {

  urlBase: string;
  user: User = new User();

  constructor(
    private http: HttpClient,
    private toastComponent: ToastComponent,
    ) {
    this.urlBase = environment.bff_web;
  }

  async saveUser(user: User): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.post(this.urlBase + `/users`, user, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async editUser(user: User): Promise<boolean> {
    try {
      const success = await lastValueFrom(this.http.put(this.urlBase + `/users`, user, { 'headers': { 'Content-Type': 'application/json' } }));

      if (success) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async delete(user: User): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/users/${user.id}`, { observe: 'response' }));
      if (success.status == 204) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  getUser(): User {
    return this.user;
  }

  setUser(user: User): void {
    this.user = user;
  }
}
