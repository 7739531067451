import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToastComponent } from "../../components/toaster/toast/toast.component";
import { Benefit } from "../../domains/benefits/benefit";
import { BenefitsGeneralData } from "../../domains/benefits/benefits-general-data";
import { BenefitServiceInterface } from "../../interfaces/benefits-service.interface";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { PartnerService } from "../../services/partner.service";


@Injectable({
    providedIn: 'root'
})
export class BenefitsServiceMock implements BenefitServiceInterface {

    urlBase: string;

    constructor(
      private http: HttpClient,
      private toastComponent: ToastComponent,            
      private partnerService: PartnerService
    ) {
      this.urlBase = environment.bff_web;
    }

    async getAll(): Promise<Benefit[]> {
        try {
            const benefits: Array<Benefit> = await lastValueFrom(this.http.get<Array<Benefit>>(this.urlBase + `/benefits`));
            if (benefits) {
                return benefits;
            }
        } catch (error) {
            console.error(error);
        }
        throw new Error();
    }

    async delete(id: number): Promise<boolean> {
        try {
          const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/benefits/${id}`, { observe: 'response' }));
          return true;
        } catch (error) {
          this.toastComponent.showApiError(error);
          console.log(error);
          throw error;
        }
    }

    async update(benefit: Benefit): Promise<boolean> {
        try {
          const benefits = await lastValueFrom(this.http.put(this.urlBase + `/benefits/${benefit.id}`, benefit, { 'headers': { 'Content-Type': 'application/json' } }));
          if (benefits) {
            return true;
          }
        } catch (error) {
          this.toastComponent.showApiError(error);
          console.error(error);
          throw new Error();
        }
        return false;
    }

    async save(benefit: Benefit): Promise<boolean> {
      try {
        const benefits = await lastValueFrom(this.http.post(this.urlBase + `/benefits`, benefit, { 'headers': { 'Content-Type': 'application/json' } }));
        if (benefits) {
          return true;
        }
      } catch (error) {
        this.toastComponent.showApiError(error);
        console.error(error);
        throw new Error();
      }
      return false;
    }

    async getGeneralData(): Promise<BenefitsGeneralData> {
        const partners = await this.partnerService.getAll();

        try {
            const benefitsGeneralData: BenefitsGeneralData = {
                partners: partners
            };

            return benefitsGeneralData;
        } catch (error) {
            console.error(error);
        }
        throw new Error();
    }

    async get(id: number): Promise<Benefit> {
        try {
          const benefit: Benefit = await lastValueFrom(this.http.get<Benefit>(this.urlBase + `/benefits/${id}`));
          if (benefit) {
              return benefit;
          }
        } catch (error) {
        console.error(error);
        }
        throw new Error();
    }

}
