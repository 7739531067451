<div class="container" (click)="$event.stopPropagation()">
  <div class="notifications">
    <div class="row">
      <div class="col-12">
        <label class="paragraph paragraph-2 extended-secondary-darker semibold"
          style="margin-top: 13px;">Notificações</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="notf-flex" *ngIf="allNotifications.length == 0">
          <img src="../assets/img/notifications.png">
          <label class="paragraph paragraph-2 semibold">Você não tem notificações</label>
          <label class="paragraph paragraph-1 text-center">
            Quando receber alguma notificação nova, ela será mostrada aqui.
          </label>
        </div>

        <div *ngIf="allNotifications.length > 0">
          <div class="row m-0 d-flex flex-row-reverse">
            <div>
              <button class="btn btn-tertiary subtitle subtitle-1 semibold primary-color"
                (click)="deleteAllNotifications()">Excluir tudo</button>
            </div>
          </div>

          <div class="container-notification w-100">
            <mat-card *ngFor="let notification of allNotifications; let i = index" class="click card-notification"
              [ngClass]="{'notification-to-read': !notification.isRead}">
              <div class="row m-0 flex-nowrap">
                <div class="col-1 trash-area" (click)="deleteNotificationById(notification, i)">
                  <mat-icon class="trash-icon material-icons-outlined">delete</mat-icon>
                </div>
                <div class="col-11 p-3 click" (click)="readNotification(notification)">
                  <div class="d-flex justify-content-between">
                      <div>
                        <label class="paragraph paragraph-1 semibold notification-title"
                          style="text-align: justify;">
                          {{ notification.subject }}
                        </label>
                      </div>
                      <div>
                        <label class="paragraph paragraph-1">{{ notification.timeNotification }}</label>
                      </div>
                  </div>
                  <div class="mt-1">
                    <label class="paragraph paragraph-1 click" style="text-align: justify;">
                      {{ notification.content }}
                    </label>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
