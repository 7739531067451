<p-confirmDialog #cd class="medium">
  <ng-template pTemplate="header">
    <div class="container"
      style="width: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
      <img src="./../../../../assets/img/alert.png">

      <div class="row">
        <div class="col-sm-12 text-center">
          <label class="subtitle subtitle-3 semibold">{{ title }}</label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center">
          <label class="subtitle subtitle-1">{{ text }}</label>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="row" *ngIf="globalFunctions.isMobile()">
      <div class="col-12 mb-3">
        <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white w-100"
          (click)="cd.accept()">Confirmar</button>
      </div>
      <div class="col-12">
        <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color w-100"
          (click)="cd.reject()">Cancelar</button>
      </div>
    </div>
    <button mat-button class="btn btn-secondary subtitle subtitle-1 semibold primary-color"
      *ngIf="!globalFunctions.isMobile()" (click)="cd.reject()">{{ isEdit && isEdit == true ? 'Não' : 'Cancelar' }}</button>&nbsp;
    <button mat-button class="btn btn-primary subtitle subtitle-1 semibold white" *ngIf="!globalFunctions.isMobile()"
      (click)="cd.accept()">{{ isEdit && isEdit == true ? 'Sim' : 'Confirmar' }}</button>&nbsp;
  </ng-template>
</p-confirmDialog>
