import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuOptionsModel } from './models/menu-options.model';



@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss']
})
export class MenuOptionsComponent implements OnInit {

  @Input() menuOptionModel: MenuOptionsModel[] = [];
  @Input() showOptions: boolean = true;

  constructor() { }

  ngOnInit(): void {

  }
}
