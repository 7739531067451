import { AddressType } from '../enums/address-type';
import { AttachmentDTO } from './attachment';
import { Contact } from './contact';


export interface AddressDTO {
  id?: number;
  uf: string;
  city: string;
  streetAddress: string;
  district: string;
  complement: string;
  number: string;
  cep: string;
  typeAddress: AddressType;
}

export interface CategoryDTO {
  categoryId: number;
  id?: number;
}

export interface DomainDTO {
  domain: string;
  id?: number;
}

export interface InfoClientDTO {
  tradingName: string;
  id: number;
  image: string;
}

export class ClientDTO {
  checked?: boolean;
  id: number | null;
  code?: string;
  cnpj?: string;
  abbCnpj: string | null;

  registerState?: string;
  abbRegisterState: string | null;
  corporateName: string;
  type: string;
  cnae: string;
  tradingName?: string;

  phone?: string;
  email?: string;
  emailAbb: string | null;

  site?: string;
  logoImgUri: string | null;
  hasBeenImageDeleted?: boolean;

  beneficialRegisterLink: string | null;
  clientLinkId?: number;
  clientTypeLink?: string;

  contacts: Contact[];
  attachments: AttachmentDTO[];
  addresses: AddressDTO[];
  categories: CategoryDTO[];
  domains: DomainDTO[] = [];

  isActive: boolean = false;
  isAdministrator?: boolean;
  participationRequiresApproval: boolean;
  isSameCnpjAddress?: boolean;
  isPcvBusiness?: boolean | false;
  isPcvProduct?: boolean | false;
  origin?: string;
  isTradingNameEdited: boolean = false;

  constructor() {
    this.id = null;
    this.abbCnpj = null;
    this.abbRegisterState = null;
    this.corporateName = '';
    this.type = '';
    this.cnae = '';
    this.emailAbb = null;
    this.logoImgUri = null;
    this.beneficialRegisterLink = null;
    this.contacts = [];
    this.attachments = [];
    this.addresses = [];
    this.categories = [];
    this.isPcvBusiness = false;
    this.isPcvProduct = false;    
    this.checked = false;
    this.participationRequiresApproval = false;
  }
}
