import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  merge,
  repeatWhen,
  scan,
  takeUntil,
  takeWhile,
} from 'rxjs';
import {
  ClientComboboxResponseDTO,
  ClientComboboxResponsePage,
} from '../../domains/clients/client-combobox-response-dto';
import { ClientAutoCompleteType } from '../../enums/client-auto-complete.enum';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-client-auto-complete',
  templateUrl: './client-auto-complete.component.html',
})
export class ClientAutoCompleteComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input()
  clientAutoCompleteType: ClientAutoCompleteType =
    ClientAutoCompleteType.DEFAULT;

  @Input()
  disabled = false;

  @Input()
  label: string = 'Cliente';

  @Input()
  onlyActive: boolean = false;

  @Input() set clientRegistered(
    clientRegistered: ClientComboboxResponseDTO | null
  ) {
    if (clientRegistered) {
      this.searchClient.setValue(clientRegistered);
      this.currentPage = 1;
      this.openFirstAutoComplete = true;
    }
  }

  @Input() set clearSelectedClient(clearSelectedClient: boolean) {
    if (clearSelectedClient) {
      this.searchClient.setValue(null);
    }
  }

  @Output()
  onSelectClient = new EventEmitter<ClientComboboxResponseDTO>();

  filterChanged$ = new Subject<string>();
  clients: ClientComboboxResponseDTO[] = [];
  private nextPage$ = new Subject<string>();
  searchClient = new FormControl();
  readonly pageSize: number = 10;
  currentPage: number = 0;
  filter: string = '';
  private repeatNextPageExecutor$ = new Subject<void>();
  destroyNextPageExecutor$ = new Subject<void>();
  openFirstAutoComplete = true;

  constructor(public clientService: ClientService) {}

  ngOnInit(): void {
    this.verifyIfOnlyOneClientAndIsNotAdministration();
    this.searchClientValueChanges();
    this.nextPageExecutor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.searchClient.disable();
      } else {
        this.searchClient.enable();
      }
    }
  }

  verifyIfOnlyOneClientAndIsNotAdministration(): void {
    this.getClients(this.filter, this.currentPage).subscribe((clients) => {
      if (clients && clients.totalElements === 1) {
        const client = clients.content[0];
        this.searchClient.setValue(client);
        this.searchClient.disable();
        this.clientSelected(client);
      } else {
        this.clients = clients.content;
        this.currentPage = 1;
        this.openFirstAutoComplete = true;
      }

      if (this.disabled) this.searchClient.disable();
    });
  }

  searchClientValueChanges(): void {
    this.searchClient.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        filter((filter) => typeof filter === 'string')
      )
      .subscribe((filter) => {
        this.filter = !!filter ? filter : '';
        this.currentPage = 0;
        this.clients = [];
        this.destroyAndRepeatNextPageExecutor();
        this.filterChanged$.next(this.filter);
      });
  }

  nextPageExecutor(): void {
    merge(this.nextPage$, this.filterChanged$)
      .pipe(
        exhaustMap((filter) => this.getNextPageClients(filter)),
        takeWhile((clients) => clients.last === false, true),
        map((clients) => clients.content),
        scan(
          (
            allClients: ClientComboboxResponseDTO[],
            newClients: ClientComboboxResponseDTO[]
          ) => allClients.concat(newClients),
          []
        ),
        takeUntil(this.destroyNextPageExecutor$),
        repeatWhen(() => this.repeatNextPageExecutor$)
      )
      .subscribe((newClients: ClientComboboxResponseDTO[]) => {
        this.addNewClients(newClients);
        this.openFirstAutoComplete = false;
      });
  }

  getNextPageClients(filter: string): Observable<ClientComboboxResponsePage> {
    const page = this.currentPage;
    const clients = this.getClients(filter, page);
    this.currentPage++;
    return clients;
  }

  addNewClients(newClients: ClientComboboxResponseDTO[]) {
    const existingClientIds = new Set(this.clients.map((client) => client.id));

    newClients.forEach((newClient) => {
      if (!existingClientIds.has(newClient.id)) {
        this.clients.push(newClient);
      }
    });
  }

  destroyAndRepeatNextPageExecutor(): void {
    this.destroyNextPageExecutor$.next();
    this.repeatNextPageExecutor$.next();
  }

  openAutoComplete(): void {
    if (!this.openFirstAutoComplete) {
      this.getClients('', 0).subscribe((clients) => {
        if (clients?.content?.length > 0) {
          this.clients = clients.content;
          this.currentPage = 1;
        }
      });
    }
  }

  displayWith(client: ClientComboboxResponseDTO): string {
    return client?.name;
  }

  onScroll() {
    this.nextPage$.next(this.filter);
  }

  getClients(
    filter: string,
    page: number
  ): Observable<ClientComboboxResponsePage> {
    const clients = this.clientService.getClientsInformationsPagination(
      filter,
      page,
      this.pageSize,
      this.clientAutoCompleteType
    );
    if (this.onlyActive) {
      return clients.pipe(
        map((client) => {
          client.content = client.content.filter((c) => c.isActive);
          return client;
        })
      );
    }
    return clients;
  }

  clientSelected(client: ClientComboboxResponseDTO): void {
    this.onSelectClient.emit(client);
  }

  resetProperties(): void {
    this.currentPage = !!this.openFirstAutoComplete ? 1 : 0;
    this.filter = '';
    this.destroyAndRepeatNextPageExecutor();
  }

  ngOnDestroy(): void {
    this.destroyNextPageExecutor$.next();
    this.destroyNextPageExecutor$.complete();

    this.repeatNextPageExecutor$.complete();
  }
}
