import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private rolesService: NgxRolesService) {}

  canActivate(): boolean {
    const role = this.rolesService.getRole('ADMIN');

    if (role) {
      return true;
    }

    return false;
  }
}
