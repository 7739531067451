import { NgxPermissionsService } from 'ngx-permissions';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { SearchTableDeleteDialogConfiguration, SituationDialogConfiguration } from 'src/app/shared/components/search/interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from 'src/app/shared/components/search/interfaces/search-table-select-configuration.interface';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { Group } from 'src/app/shared/domains/group';
import { ClientService } from 'src/app/shared/services/client.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { SearchTableComponent } from 'src/app/shared/components/search/search-table.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { GroupDTO, GroupPage } from 'src/app/shared/domains/user-client/user-client';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ClientComboboxResponseDTO } from 'src/app/shared/domains/clients/client-combobox-response-dto';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profiles-search.component.html',
  styleUrls: ['./user-profiles-search.component.scss']
})
export class UserProfilesSearchComponent implements OnInit {
  @ViewChild('searchTable') searchTable!: SearchTableComponent;

  dataSource = new MatTableDataSource<GroupDTO>();
  lastFilterValue = '';
  displayedColumns: string[] = ['name', 'isActive', 'actions'];
  paginationCard: number = 10;
  profilesFilteredByClient: Group[] = [];
  clientId!: number;
  columns: SearchColumnConfiguration[] = [];
  clientSelected!: ClientComboboxResponseDTO;
  canDelete: boolean = true;
  canCreate: boolean = true;
  canUpdate: boolean = true;
  canChangeSituation: boolean = true;
  pageLength!: number;
  pageIndex!: number;
  pageSize: number = 10;
  searchFormControl = new FormControl();
  clearSearch: boolean = false;
  currentList: GroupDTO[] | undefined = [];
  currentListView: GroupDTO[] | undefined = [];
  dataImportTableSort: MatSort | undefined;
  isUserClient: boolean = false;

  dialogInfo: SearchTableDeleteDialogConfiguration = {
    description: 'Caso confirme, essa ação não poderá ser desfeita.',
    onConfirmFn: this.deleteProfile.bind(this),
    title: 'Deseja mesmo excluir este perfil?',
  }

  changeSituationDialogInfo: SituationDialogConfiguration = {
    description: '',
    onConfirmFn: this.changeActiveProfile.bind(this),
    title: 'Deseja mesmo ',
    title2: ' este perfil de usuário?',
    situationFieldName: 'isActive'
  }

  selectClient: SearchTableSelectConfiguration = {
    labelValueName: "tradingName",
    data: [],
    placeholder: 'Cliente',
    disableAddButtonWhileNotSelect: true,
  }

  constructor(
    private router: Router,
    private clientService: ClientService,
    private profileService: ProfileService,
    public globalFunctions: GlobalFunctions,
    private permissionsService: NgxPermissionsService,
    private toastComponent: ToastComponent,
    private authService: AuthService,
  ) {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((searchText: string) => {
        if (searchText.trim() !== '' || !this.clearSearch) {
          this.pageIndex = 0;
          this.searchAdminView(searchText);
          this.clearSearch = false;
        }
      })
    ;
  }

  initColumns() {
    this.columns = [
      {
        columnName: 'Nome',
        valueName: 'name',
        columnValueCssClass: 'column-85'
      },
      {
        columnName: 'Situação',
        valueName: 'isActive',
        toggleConfiguration: {
          valueName: 'isActive'
        },
        columnValueCssClass: 'column-10'
      },
      {
        actionsConfiguration: {
          useEditButton: this.canUpdate,
          useViewButton: !this.canUpdate, 
          useDeleteFeature: true
        },
        columnName: '',
        valueName: 'actions',
        columnValueCssClass: 'p-0 d-flex justify-content-end',
      }
    ];
  }

  async ngOnInit() {
    this.initProfile();
    this.canCreate = await this.permissionsService.hasPermission(ProfilePermissions.CREATE_CLIENT_USER_PROFILE);
    this.canDelete = await this.permissionsService.hasPermission(ProfilePermissions.DELETE_CLIENT_USER_PROFILE);
    this.canChangeSituation = await this.permissionsService.hasPermission(ProfilePermissions.CHANGE_SITUATION_CLIENT_USER_PROFILE);
    this.canUpdate = await this.permissionsService.hasPermission(ProfilePermissions.UPDATE_CLIENT_USER_PROFILE);
    this.initColumns();
  }

  initProfile() {
    const userCache = this.authService.getUserFromCache();
    if (userCache?.groups.some((x) => x.name === 'CLIENT')) {
      this.isUserClient = true;
    }
  }

  searchAdminView(searchText: string) {
    if (!searchText) {
      this.getList();
      this.getFilteredGroupsByClient(this.clientId, this.searchFormControl.value ?? '', this.pageSize, 0);
    } else {
      this.getFilteredGroupsByClient(this.clientId, [searchText], this.pageSize, 0);
      console.log(this.clientId)
      console.log(searchText)
    }
  }

  getList() {
    this.currentList = this.currentListView?.slice();
    this.dataSource = new MatTableDataSource(this.currentList);
    this.dataSource.paginator?.firstPage();
  }

  getProfilesByClient(client: ClientComboboxResponseDTO) {
    this.clientSelected = client;
    this.clientId = client.id;
    this.restorePagination();
    this.clearSearchInput();
    this.getFilteredGroupsByClient(client.id, this.searchFormControl.value ?? '', this.pageSize, 0);
  }

  restorePagination() {
    this.pageIndex = 0;
    this.pageSize = 10;
  }

  clearSearchInput() {
    this.clearSearch = true;
    this.searchFormControl.setValue('');
  }

  async getFilteredGroupsByClient(clientId: number, filter: string[], pageSize: number, page: number) {
    try {
      let params = {
        clientId: clientId,
        filter: filter,
        pageSize: pageSize,
        page: page,
      };
      let groupList: GroupPage =
        await this.profileService.getFilteredGroupsByClient(params);
      this.currentListView = groupList.content;
      this.dataSource = new MatTableDataSource(this.groupMapper());
      this.dataSource.paginator?.firstPage();

      this.pageLength = groupList.totalElements;
      this.pageIndex = groupList.number;
      this.configTable();
    } catch (err) {
      console.error(err);
    }
  }

  private groupMapper(): GroupDTO[] {
    return this.currentListView!.map(
      (f) =>
      ({
        ...f,
        name: f.name,
        situation: f.situation,
      } as GroupDTO)
    );
  }

  private configTable() {
    if (this.dataImportTableSort) {
      this.dataSource.sort = this.dataImportTableSort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        return item[property]?.toLowerCase();
      };
    }
  }

  async onPageChange(event: PageEvent) {
    const page = event.pageIndex + 0;
    const pageSize = event.pageSize;
    this.pageSize = event.pageSize;
    try {
      if (page == 0) {
        this.pageIndex = 0;
        this.getFilteredGroupsByClient(
          this.clientId,
          [this.searchFormControl.value ? this.searchFormControl.value : ''],
          pageSize,
          0
        );
      } else {
        this.getFilteredGroupsByClient(
          this.clientId,
          [this.searchFormControl.value ? this.searchFormControl.value : ''],
          pageSize,
          page
        );
      }
    } finally {
      this.dataSource = new MatTableDataSource(this.currentListView);
    }
  }

  async changeActiveProfile(group: Group) {
    const success = await this.profileService.changeGroupSituation(group);

    if (success) {
      this.toastComponent.showSuccessCustomMessage(`Perfil de Usuário ${group.isActive ? 'ativado' : 'inativado'} com sucesso`, '', 3000);
    }
    else {
      group.isActive = !group.isActive;
    }
  }

  applyFilter(event: string) {
    this.searchFormControl.setValue(event);
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToNewUserProfile() {
    const extras: NavigationExtras = {
      queryParams: { clientId: this.clientId, clientName: this.clientSelected.name }
    }
    this.redirectTo('/user-profiles/new-profile', extras);
  }

  editUserProfile(group: GroupDTO) {
    const extras: NavigationExtras = {
      queryParams: { groupId: group.id, clientId: this.clientId, clientName:  this.clientSelected.name }
    }
    this.redirectTo('/user-profiles/edit-profile', extras);
  }

  redirectToViewUserProfile(group: GroupDTO) {
    const extras: NavigationExtras = {
      queryParams: { groupId: group.id, clientId: this.clientId, clientName:  this.clientSelected.name }
    }
    this.redirectTo('/user-profiles/view-profile', extras);
  }

  async deleteProfile(group: Group) {
    await this.profileService.deleteByGroup(group.id);
    this.toastComponent.showSuccessCustomMessage('Perfil deletado com sucesso!', '', 3000);
    this.getProfilesByClient(this.clientSelected);
  }
}
